import { ImgBox, RelativeBox, SectionContainer } from "../../../../styles/components/Layout";
import { AdvantagesBackground, AdvantagesList, AdvantagesListItem } from "./Advantages.styles";
import { advantagesList } from "./Advantages.data";
import { Stack, Typography } from "@mui/material";
import AdvantagesGrid from "../../../templates/advantages/AdvantagesGrid";

const Advantages = () => {
  return (
    <RelativeBox>
      <AdvantagesBackground />
      <SectionContainer p={"19rem 0"}>
        <AdvantagesGrid data={advantagesList} />
      </SectionContainer>
    </RelativeBox>
  );
};

export default Advantages;
