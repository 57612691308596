import { AvatarIcon, GlobeIcon, StarIcon } from "./img/Numbers.icon";

export const numbersList = [
  {
    icon: StarIcon(),
    title: '+50k Web3 brands',
    desc: 'Face lack of scalability in advertising'
  },  {
    icon: AvatarIcon(),
    title: '90% of founders',
    desc: 'Say Web3m saves them time and money'
  },  {
    icon: GlobeIcon(),
    title: 'More than 100K',
    desc: 'Available Web3 sites and ad placements for advertising'
  },
]