import React from "react"

function AddIcon() {
    return (
        <svg
            width="34px"
            height="34px"
            viewBox="0 0 34 34"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M58.5 39.435c-8.32 0-15.065 6.745-15.065 15.065S50.18 69.565 58.5 69.565 73.565 62.82 73.565 54.5 66.82 39.435 58.5 39.435M42 54.5C42 45.386 49.386 38 58.5 38S75 45.386 75 54.5 67.614 71 58.5 71 42 63.614 42 54.5m10.253-6.247a.72.72 0 011.016 0l5.231 5.233 5.231-5.233a.72.72 0 011.016 0 .72.72 0 010 1.016L59.514 54.5l5.233 5.231a.72.72 0 010 1.016.72.72 0 01-1.016 0L58.5 55.514l-5.231 5.233a.72.72 0 01-1.016 0 .72.72 0 010-1.016l5.233-5.231-5.233-5.231a.72.72 0 010-1.016"
                transform="translate(-631 -1197) translate(589.423 1159.65) rotate(45 58.5 54.5)"
                // fill="#9FB0C6"
                fill={'rgba(130,131,136,0.45)'}
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </svg>
    )
}

export default AddIcon