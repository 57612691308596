//Core
import { useContext} from "react";
//Styles
import {
  ArrowExpanded,
  SectionBlock,
  SectionBlockTitle,
  SectionDescription, SectionTitleBackground
} from "../../shared/styles/layout";
import SectionBlockWithLoader from "../../shared/components/loaders/SectionBlockWithLoader";
import { Box } from "@mui/material";
//Components
import ExistingFields from "./existingFields/ExistingFields";
//Hooks
import { useGeneralInfo } from "./useGeneralInfo";
//Context
import { BrandInfoContext } from "../../../../../context/BrandInfoContext";
//Images
import RemoveIcon from "@mui/icons-material/RemoveOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";
//Utils
import { sectionOpenItems } from "../../../../shared/brandAnalysis/help/sectionLabel";
import { fetchItems } from "../../../../../utils/fetchItems";

const GeneralInfo = () => {
  const { fetchList, sectionOpen, handleSectionOpen, brandSectionsList } = useContext(BrandInfoContext);
  const isLoading = fetchList.includes(fetchItems.general_info) || fetchList.includes(fetchItems.brand_profile);

  const {existingFields } = useGeneralInfo()

  if((!existingFields.length && !isLoading) || !brandSectionsList.includes(sectionOpenItems.general_info)) return null


  return (
    <SectionBlock className={isLoading ? "loading" : ""}>
      <SectionTitleBackground/>
      <SectionBlockTitle onClick={() => !isLoading && handleSectionOpen(sectionOpenItems.general_info)}>
        {'Your Business Info'}
      </SectionBlockTitle>
      <SectionDescription mb="6rem" sx={{maxWidth: '80rem'}}>
        {"Uncover valuable information about your brand. Get a comprehensive overview of your brand's online presence and reputation, allowing you to understand its growth trajectory and potential opportunities"}
      </SectionDescription>
      <SectionBlockWithLoader loading={isLoading}>
        {(sectionOpen === sectionOpenItems.general_info) &&
          <Box sx={{ m: { xs: "-1rem -3rem 2rem", br480: "1rem 0 7rem" } }}>
            {!!existingFields.length && <ExistingFields existingFields={existingFields} />}
          </Box>
        }
          <ArrowExpanded onClick={() => !isLoading && handleSectionOpen(sectionOpenItems.general_info)}>
            {(sectionOpen === sectionOpenItems.general_info)
              ? <RemoveIcon />
              : <ExpandMoreIcon />
            }
          </ArrowExpanded>
      </SectionBlockWithLoader>
    </SectionBlock>
  );
};

export default GeneralInfo;
