import { Decentraland, PartnerBox1, PartnerBox2, PartnersBox, TrustedButton, TrustedContainer } from "./TrustedBlock.styles";
import Sandbox from "./img/Sandbox.webp";
// import AxieInfinity from '../../assets/img/partners/logos/Axie Infinity (AXS).webp'
// import Vee from '../../assets/img/partners/logos/vee.webp'
// import Bitdao from '../../assets/img/partners/logos/bitdao.webp'
// import Nansen from '../../assets/img/partners/logos/nansen.webp'
// import Ripple from '../../assets/img/partners/logos/ripple.webp'
import Mises from "./img/Mises.webp";
import Gala from "./img/Gala.webp";
import Exodia from "./img/Exodia.webp";
import Appics from "./img/Appics.webp";
import { FlexBox } from "../../../styles/components/Layout";
import { useLocation } from "react-router-dom";

export const TrustedBlock = () => {
  const location = useLocation();
  const faqPage = location.pathname === "/faq";

  return (
    <TrustedContainer>
      <PartnersBox>
        <TrustedButton disabled className={faqPage ? "light" : ""}>
          {"Trusted by"}
        </TrustedButton>
        <FlexBox>
          <PartnerBox1>
            <img src={Mises} alt={"mises"} />
          </PartnerBox1>
          <PartnerBox1>
            <img src={Gala} alt={"gala"} />
          </PartnerBox1>
          <PartnerBox1>
            <Decentraland />
          </PartnerBox1>
        </FlexBox>
        <FlexBox>
          <PartnerBox2>
            <img src={Sandbox} alt={"sandbox"} />
          </PartnerBox2>
          <PartnerBox2>
            <img src={Exodia} alt={"exodia"} />
          </PartnerBox2>
          <PartnerBox2>
            <img src={Appics} alt={"appics"} />
          </PartnerBox2>
        </FlexBox>
      </PartnersBox>
    </TrustedContainer>
  );
};
