import { SectionContainer } from "../../../../styles/components/Layout";
import { Typography } from "@mui/material";
import { featuresList } from "./Features.data";
import { textAlignResponsive } from "../../../../utils/cssHelpers";
import CardsGrid from "../../../templates/cardsGrid/CardsGrid";

const Features = () => {
  return (
    <SectionContainer p={"14rem 0"}>
      <Typography variant={"h1"} color={"#0f3987"} mb={"7rem"} textAlign={textAlignResponsive}>
        Become a marketing maverick in <b style={{ color: "#53baff" }}> web3</b>
      </Typography>
      <Typography variant={"h2"} mb={"7rem"} textAlign={textAlignResponsive}>
        Here are some of the top features <br /> you enjoy in the platform
      </Typography>
      <CardsGrid data={featuresList} titleWidth={'80%'}/>
    </SectionContainer>
  );
};

export default Features;
