import {List, styled} from "@mui/material";
import theme from "../../../../../../styles/theme";

export const RequestSentList = styled(List)`
  & .MuiListItem-root {
    padding-left: 0;

    ${theme.breakpoints.down('br768')} {
      width: fit-content;
      margin: 0 auto;
    }
  }

  & .MuiListItemIcon-root {
    min-width: 1.25rem;

    & svg {
      color: #f22fdc;
      width: 6.5px;
      height: 6.5px;
    }
  }
`