//Core
import { Link } from "react-router-dom";
//Styles
import { HeroBackgoundExtension, HeroBackground, HeroContent, HeroImageContainer } from "./Hero.styles";
import { FlexBox, SectionContainer } from "../../../../styles/components/Layout";
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { MainTitle, SectionParagraph } from "../../../../styles/components/typography";
//Components
import { MainButton } from "../../../../styles/components/buttons";
//Hooks
import { useLoginWithRedirect } from "../../../../utils/hooks/useLoginWithRedirect";
//Images
import heroImage from "./img/Hero.image.webp";
import { HeroBg } from "./img/Hero.icons";
//Utils
import { heroListData } from "./Hero.data";

const Hero = () => {
  const { onLogin } = useLoginWithRedirect();

  return (
    <Box sx={{ position: "relative" }}>
      <HeroBackground>
        <HeroBackgoundExtension />
        <HeroBg />
      </HeroBackground>
      <SectionContainer>
        <FlexBox flexDirection={{ xs: "column", md: "row" }} paddingTop={"10rem"}>
          <HeroContent>
            <MainTitle sx={{ textShadow: "0 9px 9px rgba(15, 23, 68, 0.17)" }}>
              The only platform you need <b> to growth across web3 </b>
            </MainTitle>
            <SectionParagraph>
              Launch targeted ads. Measure conversions. Take data-driven decisions. Web3m is the premier dashboard for getting your message
              in front of the right audience.
            </SectionParagraph>
            <List>
              {heroListData?.map((el) => (
                <ListItem key={el.text}>
                  <ListItemIcon>{el.icon}</ListItemIcon>
                  <ListItemText>{el.text}</ListItemText>
                </ListItem>
              ))}
            </List>
            {/*<Link to={pathWithUtm("/signup")} className={""}>*/}
            <MainButton sx={{ transform: "translateY(-100%)", width: { xs: "100%", md: "fit-content" } }} onClick={onLogin}>
              {"SIGN UP"}
            </MainButton>
            {/*</Link>*/}
          </HeroContent>
          <HeroImageContainer>
            <img src={heroImage} alt={"hero"} />
          </HeroImageContainer>
        </FlexBox>
      </SectionContainer>
    </Box>
  );
};

export default Hero;
