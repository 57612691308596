//Styles
import {
  BgPeopleDark,
  GridContainer,
  MobileGridContainer,
  MobileGridLeft,
  MobileGridRight,
  PeopleBg,
  PeopleBox,
  PeopleImgBox,
  PeopleTextContainer,
  StepGridDesc,
  StepGridImage,
  StepGridTitle,
  StepsBigText,
  StepsNftContainer,
  StepsNftContent,
} from "./StepsNft.styles";
import {
  BackgroundRound,
  BenefitsContainer,
  BenefitsContent,
  GridDesc,
  GridItem,
  GridTitle,
  ImageBox,
} from "./benefits/BenefitsNft.styles";
import { Grid, Stack, Typography } from "@mui/material";
//Data
import { stepsData } from "./StepsData";
import { benefitsData } from "./benefits/BenefitsData";
//Images
import PeopleImg from "./img/People.webp";

const StepNft = ({ element, i }) => {
  return (
    <Stack>
      <StepGridImage>
        <img src={element.image} alt={"Step"} />
      </StepGridImage>
      <StepGridTitle>{element.title1}</StepGridTitle>
      <StepGridTitle>{element.title2}</StepGridTitle>
      <StepGridDesc i={i}>{element.desc}</StepGridDesc>
    </Stack>
  );
};

const BenefitsItem = ({ element, i }) => {
  return (
    <GridItem item xs={6} br768={4}>
      <ImageBox i={i}>{element.image}</ImageBox>
      <GridTitle>{element.title}</GridTitle>
      <GridDesc>{element.desc}</GridDesc>
    </GridItem>
  );
};
export const StepsNft = () => {
  return (
    <StepsNftContainer>
      <StepsNftContent>
        <Typography variant={"h2"}>
          {`Apply to purchase the NFT.`}
          <br />
          {`Here’s how`}
        </Typography>
        <GridContainer container columnSpacing={{ xs: 5, md: 5, br1024: 10, lg: 15 }}>
          {stepsData.map((el, i) => (
            <Grid key={el.title1} item xs={4}>
              <StepNft element={el} i={i} />
            </Grid>
          ))}
        </GridContainer>
        <MobileGridContainer container columnSpacing={{ xs: 2, sm: 5 }} alignItems="center">
          <MobileGridLeft item xs={6}>
            <StepNft element={stepsData[0]} i={0} />
            <StepNft element={stepsData[2]} i={2} />
          </MobileGridLeft>
          <MobileGridRight item xs={6}>
            <StepNft element={stepsData[1]} i={1} />
          </MobileGridRight>
        </MobileGridContainer>
      </StepsNftContent>
      <PeopleBox>
        <PeopleBg />
        <PeopleTextContainer>
          <StepsBigText>
            <span>{"More than "}</span>
            {"just a tool suite."}
            <br />
            {"Join the "}
            <span>{"marketing "}</span>
            {"movement."}
          </StepsBigText>
        </PeopleTextContainer>
        <PeopleImgBox>
          <img src={PeopleImg} alt={"People"} />
          <BgPeopleDark />
        </PeopleImgBox>
      </PeopleBox>
      <BackgroundRound />
      <BenefitsContainer>
        <BenefitsContent>
          <Grid container columnSpacing={{ xs: 5, sm: 7, br1024: 3, lg: 8, xl: 10 }} rowSpacing={7}>
            {benefitsData.map((el, i) => (
              <BenefitsItem key={el.title} element={el} i={i} />
            ))}
          </Grid>
        </BenefitsContent>
      </BenefitsContainer>
    </StepsNftContainer>
  );
};
