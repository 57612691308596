export const FaqNftData = [
  {
    question: `Can I use Web3m without purchasing an NFT?`,
    answer: `No, Web3m is only accessible through the NFT. The NFT serves as your access key to the ad platform as well as membership into the Web3m marketing community.`
  },
  {
    question: `Can I sell my NFT?`,
    answer: `Yes, you are welcome to sell your Web3m NFT at any time. You will no longer have access to the NFT benefits—which includes losing access to the Web3m ad platform. `
  },
  {
    question: `When will the Web3m tokens be released?`,
    answer: `Expect the token to launch in Q3 2023.`
  },
  {
    question: `Why do I have to apply to purchase the Web3m NFT?`,
    answer: `Web3m is more than a mere ad platform. It’s a community of Web3 marketers. We have limited the number of available NFTs (and by extension, memberships) to 2,000. The application process helps us ensure a strong and collaborative community.`
  },
  {
    question: `How many people can purchase the NFT?`,
    answer: `Web3m release 2,000 total NFTs. With limited available membership, we want to ensure the right businesses have access to the platform and community.`
  },
]