import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginUpdateForm } from "../../services/web3Service";
import { coPilotLink, dashboardLink } from "../helpers";
import { pathWithUtm } from "../pathWithUtm";
import { getUserCampaignsExist } from "../../services/statisticManagerService";

export const useLoginWithRedirect = () => {
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const { isAuthenticated, loginWithPopup, user } = useAuth0();

  const onLogin = async () => {
    !isAuthenticated && await loginWithPopup();
    setRedirectToDashboard(true);
  };

  const getUserCampaignsData = async (user_id) => {
    try {
      const data = await getUserCampaignsExist(user_id);
      if (data && !data.has_campaigns && !data.has_draft && !data.has_pr) {
        window.open(pathWithUtm(coPilotLink), '_self')
      } else return data
    } catch(error) {
      console.log(error);
      return true
    }
  }

  useEffect(() => {
    if (isAuthenticated && redirectToDashboard) {
      if (user) {
        const sendUserForm = async () => {
          const payload = {
            user_id: user.sub,
            user_name: user.name ?? user.nickname ?? '',
            user_email: user.email ?? '',
          }
          await LoginUpdateForm(payload);
          const data = await getUserCampaignsData(user.sub)
          if (data) window.location.replace(pathWithUtm(dashboardLink));
        }
        void sendUserForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, redirectToDashboard]);

  return { onLogin };
}