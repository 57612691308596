import { useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useBreakpoints } from "../../../../utils/hooks/useBreakpoints";
import { useAuth0 } from "@auth0/auth0-react";
import { coPilotLink, DEV_ONLY } from "../../../../utils/helpers";
import axios from "axios";
import { sendFeedback } from "../../../../services/gatewayServiceStats";
import { BrandInfoContext } from "../../../../context/BrandInfoContext";
import { pathWithUtm } from "../../../../utils/pathWithUtm";
import { useDispatch } from "react-redux";
import { setActivePopup, setPopupData } from "../../../../redux/app/appSlice";
// import { getUserCampaignsExist } from "../../../../services/statisticManagerService";

export const useHeader = () => {
  const {url, sendTimeLapFeedback} = useContext(BrandInfoContext)
  const [menuOpen, setMenuOpen] = useState(false);
  const [hasNFT, setHasNFT] = useState(true);
  const [float, setFloat] = useState(false);
  const { tabletScreen } = useBreakpoints();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isAuthenticated, user, logout } = useAuth0();

  const getUserData = async (user_id) => {
    try {
      const data = await axios.get(`https://mvp-campaigns-${DEV_ONLY ? "develop-" : ""}dot-winky-apis.ew.r.appspot.com/user/${user_id}`);
      // const userPaymentInfo = data?.data?.payment_info;
      //TODO: remove this when we have the NFTs
      setHasNFT(true);
      //TODO: uncomment this when we have the NFTs
      // setHasNFT(userPaymentInfo?.has_nft ?? false);
    } catch {
      //TODO: remove this when we have the NFTs
      setHasNFT(true);
      //TODO: uncomment this when we have the NFTs
      // setHasNFT(false);
    }
  };

  // const getUserCampaignsData = async (user_id) => {
  //   try {
  //     await getUserCampaignsExist(user_id);
  //   } catch(error) {
  //     console.log(error);
  //   }
  // }

  useEffect(() => {
    if (isAuthenticated) {
      void getUserData(user.sub);
      // void getUserCampaignsData(user.sub);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!tabletScreen) setMenuOpen(false);
  }, [tabletScreen]);

  useEffect(() => {
    const onScroll = () => {
      setFloat(!!window.scrollY);
    };

    if (location.pathname === "/") {
      window.addEventListener("scroll", onScroll);
    }

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [location.pathname]);

  const toggleMobileMenu = () => {
    dispatch(setActivePopup(""));
    dispatch(setPopupData({}));
    setMenuOpen(!menuOpen);
  };

  const headerBackground = {
    "/": { backgroundColor: float ? "#fff" : "transparent" },
    "/ecosystem": { backgroundColor: "#fff" },
    "/brand_info": { backgroundColor: "#fff" },
    "/faq": { backgroundColor: "#fff" },
    "/pixel": { backgroundColor: "#fff" },
    "/data_network": { backgroundColor: "#fff" },
    "/ad_network": { backgroundColor: "#fff" },
    "/roadmap": { backgroundColor: "#fff" },
    "/membership": { backgroundColor: "#00213A" },
    "/signup": { backgroundColor: "#002541" },
    "/signup/done": { backgroundColor: "#002541" },
  };

  const headerNavBoxClassName = {
    "/": float ? "white-blue" : "",
    "/faq": "white-blue",
    "/brand_info": "brand-info",
    "/pixel": "white-blue",
    "/ecosystem": "white-blue",
    "/data_network": "white-blue",
    "/ad_network": "white-blue",
    "/roadmap": "white-blue",
    "/signup": "signupPage",
  };

  const faqPage = location.pathname === "/faq";
  // const nftPage = location.pathname === "/membership";
  // const homePage = location.pathname === "/";

  const createCampaignButton = async () => {
    void sendTimeLapFeedback();
    void sendFeedback({
      brand_url: url ?? "",
      create_campaign_after_BI: true
    });
    window.open(pathWithUtm(`${coPilotLink}?utm_campaign=organic&utm_medium=brandinfo`), "app");
  }

  const openLoginPopup = async (type) => {
    await dispatch(setPopupData({ type }));
    void dispatch(setActivePopup("login"));
  }

  return {
    location,
    faqPage,
    menuOpen,
    toggleMobileMenu,
    headerBackground: headerBackground[location.pathname],
    headerNavBoxClassName: headerNavBoxClassName[location.pathname],
    hasNFT,
    logout: () => logout({ returnTo: window.location.origin }),
    createCampaignButton,
    openLoginPopup
  };
};
