export function ClickIcon(props) {
  return (
    <svg width="10.2rem" height="9.8rem" viewBox="0 0 102 98" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#000" strokeWidth={5.76} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path
          d="M29.416 27.69h0c2.931-1.812 6.813-.9 8.625 2.031l8.796 14.214a6.24 6.24 0 012.82-4.014h.003c2.67-1.653 6.126-1.04 8.091 1.3a6.273 6.273 0 012.95-4.89c2.67-1.65 6.128-1.042 8.093 1.298a6.277 6.277 0 012.946-4.89c2.931-1.812 6.972-.99 8.625 2.031l12.177 22.236c9.9 18.078-9.213 41.316-28.428 32.256l-33.32-15.714c-3.118-1.47-4.465-5.223-2.998-8.34 2.91-1.797 9.165-2.607 12.303-1.128l6.312 2.98-19.026-30.745c-1.812-2.93-.903-6.81 2.031-8.625z"
          transform="translate(-545 -2853) translate(459.73 2755.438) translate(88.988 97.808) translate(0 2.647)"
        />
        <path
          d="M25.807 49.083c-7.065-2.496-12.13-9.234-12.13-17.154 0-10.044 8.146-18.189 18.19-18.189 9.102 0 16.64 6.687 17.979 15.417"
          transform="translate(-545 -2853) translate(459.73 2755.438) translate(88.988 97.808) translate(0 2.647)"
        />
        <path
          d="M18.474 60.711C7.566 55.665 0 44.625 0 31.818 0 14.244 14.244 0 31.818 0c14.763 0 27.18 10.056 30.771 23.691"
          transform="translate(-545 -2853) translate(459.73 2755.438) translate(88.988 97.808) translate(0 2.647)"
        />
      </g>
    </svg>
  );
}

export function GlobeIcon(props) {
  return (
    <svg width="9.2rem" height="9.2rem" viewBox="0 0 92 92" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(-1337 -2854) translate(1233.209 2755.438) translate(107.648 97.808) translate(0 4.447)"
        stroke="#000"
        strokeWidth={5.76}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M42.519 0c23.484 0 42.519 19.038 42.519 42.519 0 23.484-19.035 42.522-42.519 42.522C19.035 85.041 0 66.003 0 42.519 0 19.038 19.035 0 42.519 0z" />
        <path d="M42.519 0L42.519 85.002" />
        <path d="M0.0165 42.5202L85.0185 42.5202" />
        <path d="M72.569 12.914c-6.492 5.862-17.538 9.72-30.072 9.72-12.507 0-23.532-3.843-30.027-9.68M72.569 72.126c-6.492-5.862-17.538-9.72-30.072-9.72-12.507 0-23.532 3.843-30.027 9.681" />
        <path d="M42.132 84.558c-12.828-9.057-21.27-24.468-21.27-41.955 0-17.45 8.406-32.835 21.183-41.898M43.533 84.558c12.828-9.057 21.27-24.468 21.27-41.955 0-17.45-8.406-32.835-21.183-41.898" />
      </g>
    </svg>
  );
}

export function TargetIcon(props) {
  return (
    <svg width="10.3rem" height="10.2rem" viewBox="0 0 103 102" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#000" strokeWidth={5.76} fill="none" fillRule="evenodd">
        <path
          d="M47.824 0c26.412 0 47.823 21.414 47.823 47.823 0 26.412-21.411 47.823-47.823 47.823S0 74.235 0 47.823C0 21.414 21.41 0 47.824 0z"
          transform="translate(-554 -3695) translate(459.73 3599.376) translate(97.902 97.808) translate(0 .935)"
        />
        <path
          d="M79.914 70.831c-.726-4.146-1.935-9.069-5.061-11.913-2.874-2.604-5.292-1.929-7.818-4.188-2.568-2.295-2.988-4.266-.975-7.485 1.605-2.565 2.85-6.282 2.85-9.462 0-5.328-3.672-9.648-8.565-9.648a7.931 7.931 0 00-3.519.81M15.735 70.831c.726-4.146 1.932-9.069 5.061-11.913 2.871-2.604 5.292-1.929 7.818-4.188 2.571-2.295 2.988-4.266.975-7.485-1.605-2.565-2.85-6.282-2.85-9.462 0-5.328 3.672-9.648 8.565-9.648 1.272 0 2.457.288 3.519.81m33.27 50.43c.309-2.79.639-9.837-3.447-13.494-3.747-3.357-8.79-3.237-12.09-6.147-3.351-2.958-3.897-6.59-1.269-10.734 2.094-3.306 3.717-8.094 3.717-12.183 0-6.864-4.788-12.429-11.181-12.429-6.39 0-11.178 5.565-11.178 12.43 0 4.088 1.623 8.876 3.717 12.182 2.628 4.143 2.082 7.776-1.269 10.734-3.303 2.91-8.343 2.79-12.093 6.147-4.086 3.657-3.753 10.704-3.444 13.494m72.09-31.55H79.458m-31.635 47.82V79.455M0 47.824h16.191M47.823.001V16.19"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(-554 -3695) translate(459.73 3599.376) translate(97.902 97.808) translate(0 .935)"
        />
      </g>
    </svg>
  );
}

export function CameraIcon(props) {
  return (
    <svg width="10.2rem" height="8.8rem" viewBox="0 0 102 88" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(-1342 -3696) translate(1233.209 3599.376) translate(111.902 97.808) translate(0 2)"
        stroke="#000"
        strokeWidth={5.76}
        fill="none"
        fillRule="evenodd"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.66533454e-15 18.243L19.047 18.243 26.553 0 68.607 0 76.116 18.243 95.16 18.243 95.16 81.42 1.66533454e-15 81.42z"
        />
        <path d="M47.581 28.743c8.268 0 14.97 6.702 14.97 14.97S55.85 58.68 47.581 58.68s-14.97-6.699-14.97-14.967 6.702-14.97 14.97-14.97zm0-10.5c14.064 0 25.467 11.403 25.467 25.467 0 14.07-11.403 25.47-25.467 25.47-14.067 0-25.47-11.4-25.47-25.47 0-14.064 11.403-25.467 25.47-25.467z" />
      </g>
    </svg>
  );
}
