export const getCompetitorPercent = (val, competitors) => {
  const sum = competitors.reduce((acc, el) => acc + (el.monthly_traffic || el.monthly_visits || 0), 0);

  if (sum === 0) return "Low";

  const res = Math.round(((val || 0) / sum) * 10000) / 100;

  if (res > 66) {
    return "High";
  } else if (res > 33) {
    return "Mid";
  } else {
    return "Low";
  }

  // return Math.round(((val || 0) / sum) * 10000) / 100;
};
