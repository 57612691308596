import img1 from "./img/advantages1.webp";
import img1mob from "./img/advantages1mob.webp";
import img2 from "./img/advantages2.webp";
import img2mob from "./img/advantages2mob.webp";
import img3 from "./img/advantages3.webp";
import img3mob from "./img/advantages3mob.webp";

export const advantagesList = [
  {
    title: "Built on-chain for better security (and lower costs)",
    desc: "Crypto companies shouldn’t be penalized for merely advertising their projects. Web3m is built on-chain, making data more trustworthy and more affordable to access. Big Tech no longer owns advertising. It’s owned by the full web3 community.",
    img: img1,
    img_mob: img1mob
  },
  {
    title: "All-in-one web3 analytics",
    desc: "Great data calls for powerful analytics. Web3m’s analytics platform makes it easy to learn more about your site visitors and customers. The analytics combines the data you know (like demographics and geographics) with data only available in web3, like wallet behavior.",
    img: img2,
    img_mob: img2mob
  },
  {
    title: "Rest easy with full GDPR compliance",
    desc: "No conversation about data is complete without talking about compliance. With Web3m, you can advertise with powerful data, within the boundaries set in place with GDPR. That means better privacy, security—and conversions.",
    img: img3,
    img_mob: img3mob
  },
];
