import { Tab } from "@mui/material";
import { Stack, styled } from "@mui/system";
import theme from "../../../../../styles/theme";

export const SentimentCustomTab = (props) => {
  const StyledTab = styled(Tab)`
    /* flex: 1; */
    min-height: auto;
    padding: 1.4rem 0;

    font-size: 2.2rem;
    text-transform: none;
    font-weight: bold;

    letter-spacing: 0.24px;

    &.Mui-selected {
      color: #132338;
    }

    &[aria-selected="false"] {
      color: #1a418e;

      opacity: 0.44;
    }

    &:not(:last-of-type) {
      margin-right: 4rem;
    }

    svg {
      width: 2.2rem;
      height: 2.2rem;
    }

    ${theme.breakpoints.down("br480")} {
      font-size: 1.6rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.06;
      letter-spacing: 0.18px;
      color: #fff;
      border-radius: 3.2rem;
      background-color: #677194;
      width: calc(50% - 1rem);
      padding: 0.5rem 1rem;
      text-align: left;

      &:not(:last-of-type) {
        margin-right: 2rem;
      }

      &[aria-selected="false"] {
        border: solid 1px #9bacce;
        background-color: #fff;
      }

      &[aria-selected="true"] {
        color: #fff;
        svg{
          path, circle{
            stroke: #fff;

          }
        }
      }

      svg {
        width: 4.4rem;
        height: 4.4rem;
        path,
        circle {
          fill: none;
        }
      }
    }
  `;

  return <StyledTab iconPosition="start" {...props} />;
};

export const SentimentComments = styled(Stack)`
  margin-top: 6rem;

  ${theme.breakpoints.down("br1024")} {
    margin-top: 3rem;
  }
  h4 {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.41;
    letter-spacing: 0.2px;
    color: #4a5767;
  }

  p {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
  }
`;
