import Hero from "./hero/Hero";
import Advantages from "./advantages/Advantages";
import Network from "./network/Network";
import Steps from "./steps/Steps";
import { PageTitle } from "../../../styles/components/Layout";

const Ecosystem = () => {
  return (
    <div>
      <PageTitle>Ecosystem</PageTitle>
      <Hero />
      <Advantages />
      <Network />
      {/* <Steps /> */}
      {/*<Media />*/}
      {/*<Partners />*/}
    </div>
  );
};

export default Ecosystem;
