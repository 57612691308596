import { CameraIcon, ClickIcon, GlobeIcon, TargetIcon } from "./img/Targeting.icons";

export const targetingList = [
  {
    title: "Wallet-based targeting",
    icon: ClickIcon(),
    features: ["Token interactions", "Current Token & NFT Holdings", "Transactions details (number, value, frequency, etc.)"],
    extraFeatures: true,
  },
  {
    title: "Geographic parameters",
    icon: GlobeIcon(),
    features: ["Country", "Region", "City", "ZIP code"],
    extraFeatures: true,
  },
  {
    title: "Demographic targeting",
    icon: TargetIcon(),
    features: ["Gender", "Age"],
    extraFeatures: false,
  },
  {
    title: "Interests & behavior",
    icon: CameraIcon(),
    features: ["Page topics", "Articles read", "Websites visited", "Favorite content types"],
    extraFeatures: true,
  },
];
