import React from "react";
import { Typography } from "@mui/material";
import { SectionContainer } from "../../../../styles/components/Layout";
import HeadImg from "./img/ctaWoman.webp";
import { SignUpTitleContainer } from "./SignUpTitle.styles";

const SignUpTitle = () => {
  return (
    <>
      <SignUpTitleContainer>
        <SectionContainer>
          <Typography variant="h1">
            Fill out this form to get the Early Access for <b>Web3m platform</b>
          </Typography>
          <Typography variant="body2">
            All the necessary tools to grow. Analyze your brand, generate automated creative and reachout 1B+ active users on multiple
            channels. all in one place.
          </Typography>
          <img src={HeadImg} alt="" />
        </SectionContainer>
      </SignUpTitleContainer>
    </>
  );
};

export default SignUpTitle;
