export const getPotentialUsers = (socialScrapper) => {
  const sum =
    socialScrapper &&
    Object.values(socialScrapper).reduce((acc, next) => {
      if (next?.avg_engagements) {
        return acc + next.avg_engagements;
      } else if (next?.total_online_members) {
        return acc + next.total_online_members;
      }
      return acc;
    }, 0);
  return Math.round(sum);
};

export const getFollowers = (socialMedia) =>
  socialMedia
    ? Object.entries(socialMedia).reduce(
        (acc, next) => {
          if (next[1]?.followers) {
            return {
              num: acc.num + next[1].followers,
              mediaList: [...acc.mediaList, next[0]],
            };
          } else if (next[1]?.total_members) {
            return {
              num: acc.num + next[1].total_members,
              mediaList: [...acc.mediaList, next[0]],
            };
          } else return acc;
        },
        { num: 0, mediaList: [] }
      )
    : null;
