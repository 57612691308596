import {useSnackbar} from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {useEffect} from "react";
import { removeSnack } from "../../redux/snack/snackSlice";
import { notificationsSelector } from "../../redux/snack/selector";

export const useSnackBar = () => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const notifications = useSelector(notificationsSelector);
    useEffect(() => {
        for (const { key, message, variant } of notifications) {
            enqueueSnackbar(message, {
                key,
                variant,
                onExited: (event, sKey) => {
                    dispatch(removeSnack(sKey));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications, dispatch]);
}
