import networkImg from "./img/network.webp";
import improveImg from "./img/improve.webp";
export const aboutList = [
  {
    icon: networkImg,
    title: "A better ad network for everyone",
    desc: "At Web3m, we reimagined ad networks from the ground up. The result is an ecosystem that gives site owners, site visitors, and advertisers a better online experience.",
  },  {
    icon: improveImg,
    title: "Improve your ad conversions with better data",
    desc: "Send targeted ads based on lookalike audiences. Launch data-driven campaigns across the top websites in crypto.",
  },
];
