import Grid2 from "@mui/material/Unstable_Grid2";
import { Stack, Typography } from "@mui/material";
import { textAlignResponsive } from "../../../utils/cssHelpers";
import { ImgBox } from "../../../styles/components/Layout";
import { SectionParagraph } from "../../../styles/components/typography";

const CardsGrid = ({ data, spacing, titleWidth }) => {
  return (
    <Grid2 container spacing={{ xs: spacing ?? "10rem", br768: "0", md: spacing ?? "10rem" }} mb={'5rem'}>
      {data.map((el, i) => (
        <Grid2 xs={12} md={4} key={el.title + i}>
          <Stack width={{ xs: "70%", md: "100%" }} height={"100%"} minWidth={"30rem"} m={{ xs: "0 auto", md: "0" }}>
            <Typography
              variant={"subtitle1"}
              color={"#5680ad"}
              width={titleWidth ?? "90%"}
              m={{ xs: "0 auto 3rem", md: "0 0 3rem" }}
              sx={{whiteSpace: 'pre-line'}}
              textAlign={textAlignResponsive}
            >
              {el.title}
            </Typography>
            <SectionParagraph mb={"5rem"}>
              {el.desc}
            </SectionParagraph>
            <ImgBox m={{ xs: 'auto', md:"auto 0" }} mb={{xs: '5rem', md: '0'}} sx={{maxWidth: '27rem'}}>
              <img src={el.img} alt={el.title} style={{ marginTop: "auto" }} />
            </ImgBox>
          </Stack>
        </Grid2>
      ))}
    </Grid2>
  );
};

export default CardsGrid;
