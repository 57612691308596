import React from "react";
import { styled, Tooltip, tooltipClasses, Typography } from "@mui/material";
import theme from "../../../../../styles/theme";

export const BrandAnalysisSectionTooltip = styled(({ className, children, ...props }) =>
  <Tooltip {...props}
           classes={{ popper: className }}
           children={children}
           // disableFocusListener
           // disableHoverListener
           // disableTouchListener
           leaveTouchDelay={5000}
           arrow
           placement={"top-end"}
  />)(() => ({
  // paddingTop: "1.7rem !important",
  [`&:has([data-popper-placement^='top-end'])`]: {
    [`& .${tooltipClasses.arrow}`]: {
      color: 'unset !important',
      right: '0 !important',
      left: 'auto !important',
      transform: 'translateX(0) !important',
      width: '1.5rem',
      height: '1.5rem',
      overflow: 'hidden',
      '&:before': {
        transform: 'unset !important',
        content: '""',
        display: 'block',
        width: '200%',
        height: '200%',
        position: 'absolute',
        borderRadius: '50%',
        bottom: '-1.7rem',
        right: 0,
        boxShadow: '1rem -1rem 0 0 #E8E8FF',
        background: 'unset !important',
      }
    },
  },
  [`data-popper-placement^='bottom-end'`]: {
    [`& .${tooltipClasses.tooltip}`]: {
      borderRadius: "1rem 1rem 1rem 1rem",
    },
    [`& .${tooltipClasses.arrow}`]: {
      display: 'none',
      color: 'unset !important',
      right: '0 !important',
      left: 'auto !important',
      transform: 'translateX(0) !important',
      width: '1.5rem',
      height: '1.5rem',
      overflow: 'hidden',
      '&:before': {
        transform: 'unset !important',
        content: '""',
        display: 'block',
        width: '200%',
        height: '200%',
        position: 'absolute',
        borderRadius: '50%',
        bottom: '-1.7rem',
        right: 0,
        boxShadow: '1rem 1rem 0 0 #E8E8FF',
        background: 'unset !important',
      }
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#E8E8FF",
    maxWidth: 300,
    minWidth: 140,
    width: "fit-content",
    fontSize: "1.82rem",
    borderRadius: "1rem",
    padding: "3rem",
    boxShadow: '0 12px 12px 0 rgb(5 27 61 / 50%)',
    marginBottom: "2rem !important",
    [`${theme.breakpoints.down("lg")}`]: {
      maxWidth: 220,
    },
    [`${theme.breakpoints.down("md")}`]: {
      maxWidth: 250,
      margin: "0 auto",
    },
    [`${theme.breakpoints.down("sm")}`]: {
      maxWidth: 200,
    },
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#E8E8FF",
    transform: 'translate3d(178.5px, 0px, 0px) !important',
  }
  //   color: 'unset !important',
  //   right: '0 !important',
  //   left: 'auto !important',
  //   transform: 'translateX(0) !important',
  //   width: '1.5rem',
  //   height: '1.5rem',
  //   overflow: 'hidden',
  //   '&:before': {
  //     transform: 'unset !important',
  //     content: '""',
  //     display: 'block',
  //     width: '200%',
  //     height: '200%',
  //     position: 'absolute',
  //     borderRadius: '50%',
  //     bottom: '-1.7rem',
  //     right: 0,
  //     boxShadow: '1rem -1rem 0 0 #E8E8FF',
  //     background: 'unset !important',
  //   }
  // },
}));

export const BrandAnalysisSectionTooltipContent = styled(Typography)`
  font-size: 1.4rem;
  color: #2A0B0B;
  letter-spacing: 0.7px;
`


