import { Box, styled } from "@mui/material";
import { FlexBox, ImgBox } from "../../../../../styles/components/Layout";
import { mobileScreen, smallMobileScreen } from "../../../../../utils/themeBreakpoints";

export const StepsImageBox = styled(ImgBox)`
  transform-origin: bottom left;
  transform: translate(-4rem, -10rem) scale(1.6);
  margin-bottom: -5rem;
`;

export const PixelBanner = styled(FlexBox)`
  position: relative;
  background-color: #0e67e0;
  border-radius: 1.7rem;
  padding: 5rem;
  justify-content: space-between;
  margin-bottom: 14rem;

  ${mobileScreen} {
    margin-top: 5rem;
    padding-top: 10rem;
    margin-bottom: 4rem;

    > div:first-of-type {
      width: 100%;
    }
  }

  svg {
    height: 5rem;
    margin-right: 2.7rem;

    ${mobileScreen} {
      position: absolute;
      background-color: #0e67e0;
      border-radius: 50%;
      top: 0;
      left: 50%;
      aspect-ratio: 1 /1;
      padding: 2rem;
      height: 12rem;
      border: 3px solid #fff;
      transform: translate(-50%, -50%);
    }
    
    ${smallMobileScreen}{
      height: 10rem;
    }
  }

  h2 {
    font-family: "F37 Judge", sans-serif;
    font-size: 4.6rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: 1.61px;
    color: #fff;

    ${mobileScreen} {
      text-align: center;
      width: 100%;
    }
  }

  a {
    font-size: 2.9rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-decoration: underline;
    line-height: 1.07;
    letter-spacing: 1.01px;
    color: #fff;
    margin-left: 2rem;
  }
`;

export const StepsContainer = styled(Box)`
  width: 82%;
  margin: 0 auto;
  transform: translateX(-7%);
  > div:first-of-type {
    width: 90%;
    margin: 0 auto 3rem;

    ${mobileScreen} {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  ${mobileScreen} {
    width: 95%;
    transform: none;
    margin-top: 7rem;
  }
  
  ${smallMobileScreen}{
    width: 100%;
  }
`;

export const StepsDescription = styled(FlexBox)`
  justify-content: space-between;
  column-gap: 15rem;
  
  ${mobileScreen}{
    column-gap: 3vw;
    margin-left: -5%;
    width: 110%;
  }

  // ${smallMobileScreen} {
  //   margin-left: -5%;
  //   width: 110%;
  // }

  p {
    font-size: 2.4rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.84px;
    text-align: center;
    color: #000;

    ${mobileScreen} {
      word-break: break-word;
      font-size: 3vw;
      flex: 1 1 33%;
      //max-width: 25%;
    }
    
    ${smallMobileScreen}{
      max-width: 25%;
    }
  }
`;
