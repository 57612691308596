//Core
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect, useState, Suspense } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress } from "@mui/material";

//Components
import FullPageLoader from "./loader/FullPageLoader";
import FAQ from "../mainContent/faq/FAQ";
import SignUp from "../mainContent/signup/SignUp";
import ThankYou from "../mainContent/signup/thankYou/ThankYou";
import ScrollToTop from "./utils/ScrollToTop";
import Page404 from "../mainContent/404/Page404";
import Footer from "./footer/Footer";
import Nft from "../mainContent/nft/NFT";
import Homepage from "../mainContent/homepage/Homepage";
import Header from "./header/Header";
import Ecosystem from "../mainContent/ecosystem/Ecosystem";
import Pixel from "../mainContent/solutions/pixel/Pixel";
// import Roadmap from "../mainContent/roadmap/Roadmap";
import DataNetwork from "../mainContent/solutions/dataNetwork/DataNetwork";
import AdNetwork from "../mainContent/solutions/adNetwork/AdNetwork";
import ConnectionPage from "../mainContent/connection/ConnectionPage";
//Styles
import { LoaderContainer } from "./Main.styles";
import BrandInfo from "../mainContent/brandInfo/BrandInfo";
import { pathWithUtm } from "../../utils/pathWithUtm";

// const FAQ = lazy(() => import("../../mainContent/faq/FAQ"));
// const SignUp = lazy(() => import("../../mainContent/signup/SignUp"));
// const ThankYou = lazy(() => import("../../mainContent/signup/components/thankYou/ThankYou"));
// const Page404 = lazy(() => import("../../mainContent/404/Page404"));
// const Footer = lazy(() => import("./footer/Footer"));
// const Nft = lazy(() => import("../../mainContent/nft/NFT"));
// const Homepage = lazy(() => import("../../mainContent/homepage/Homepage"));
// const Header = lazy(() => import("./header/Header"));
// const Ecosystem = lazy(() => import("../../mainContent/ecosystem/Ecosystem"));
// const Pixel = lazy(() => import("../../mainContent/solutions/pixel/Pixel"));
// const Roadmap = lazy(() => import("../../mainContent/roadmap/Roadmap"));
// const DataNetwork = lazy(() => import("../../mainContent/solutions/dataNetwork/DataNetwork"));
// const AdNetwork = lazy(() => import("../../mainContent/solutions/adNetwork/AdNetwork"));

export const Main = () => {
  const { isLoading } = useAuth0();
  const [is404, setIs404] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/404") setIs404(true);
    else setIs404(false);
  }, [location.pathname]);

  const renderLoader = () => (
    <LoaderContainer sx={{ display: isLoading ? "flex" : "none" }}>
      <CircularProgress />
    </LoaderContainer>
  );

  return (
    <>
      {renderLoader()}
      <div
        style={{
          height: isLoading ? "100vh" : "fit-content",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        {!is404 && location.pathname !== '/login' && <Header />}
        <ScrollToTop>
          <Suspense fallback={<FullPageLoader />}>
            <Routes>
              <Route path={"/"}  element={<Homepage />} />
              <Route path={"/login"} element={<ConnectionPage />} />
              <Route path={"/faq"} element={<FAQ />} />
              <Route path={"/signup"}>
                <Route index element={<SignUp />} />
                <Route path={"done"} element={<ThankYou />} />
              </Route>
              <Route path={"/membership"} element={<Nft />} />
              <Route path={"/ecosystem"} element={<Ecosystem />} />
              <Route path={"/pixel"} element={<Pixel />} />
              {/*<Route path={"/roadmap"} element={<Roadmap />} />*/}
              <Route path={"/data_network"} element={<DataNetwork />} />
              <Route path={"/ad_network"} element={<AdNetwork />} />
              <Route path={"/brand_info"} element={<BrandInfo />} />
              <Route path={"/404"} element={<Page404 setIs404={setIs404} />} />
              <Route path={"*"} element={<Navigate to={pathWithUtm("/404")} replace />} />
            </Routes>
          </Suspense>
        </ScrollToTop>
        {!is404 && location.pathname !== '/login' && <Footer />}
      </div>
    </>
  );
};
