import { Instagram, Reddit, Twitter } from "@mui/icons-material";

export const socialMediaIcons = {
  facebook: <Facebook />,
  twitter: <Twitter />,
  linkedin: <LinkedIn />,
  instagram: <Instagram />,
  youtube: <Youtube />,
  whitepaper: <WhitePaper />,
  discord: <Discord />,
  decentraland: <Decentraland />,
  sandbox: <Sandbox />,
  tiktok: <TikTok />,
  reddit: <Reddit />,
};

function LinkedIn(props) {
  return (
    <svg viewBox="0 0 20.0 20.0" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <clipPath id="a">
          <path d="M1894 0v1173H0V0h1894z" />
        </clipPath>
        <clipPath id="b">
          <path d="M4.239 6.276V18.9H.311V6.276H4.24zM2.276 0a2.276 2.276 0 11-.003 4.551A2.276 2.276 0 012.276 0z" />
        </clipPath>
        <clipPath id="c">
          <path d="M7.527 0c3.973 0 4.707 2.614 4.707 6.014v6.924H8.311v-6.14c0-1.464-.025-3.347-2.039-3.347-2.042 0-2.353 1.596-2.353 3.243v6.244H0V.313h3.761V2.04h.054C4.338 1.047 5.618 0 7.527 0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="translate(-435 -495)">
        <g clipPath="url(#b)" transform="translate(435.372 495.93)">
          <path
            fill="#C7CBD0"
            d="M0 0L4.55041406 0 4.55041406 18.9 0 18.9 0 0z"
          />
        </g>
        <g
          clipPath="url(#c)"
          transform="translate(435.372 495.93) translate(6.7 5.962)"
        >
          <path
            fill="#C7CBD0"
            d="M0 0L12.2340152 0 12.2340152 12.9376238 0 12.9376238 0 0z"
          />
        </g>
      </g>
    </svg>
  );
}

function WhitePaper(props) {
  return (
    <svg viewBox="0 0 24 29" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1091.546 1034.082a3.171 3.171 0 013.172-3.172h5.765c.23 0 .45.091.612.254l8.072 8.072a.861.861 0 01.254.612v10.378a3.171 3.171 0 01-3.172 3.172h-1.441v1.441a3.171 3.171 0 01-3.171 3.171h-11.532a3.171 3.171 0 01-3.171-3.171v-16.145a3.171 3.171 0 013.17-3.17h1.442v-1.442zm0 3.17h-1.441c-.796 0-1.442.647-1.442 1.442v16.145c0 .796.646 1.442 1.442 1.442h11.532c.795 0 1.441-.646 1.441-1.442v-1.441h-8.36a3.171 3.171 0 01-3.172-3.172v-12.973zm3.172-4.612c-.796 0-1.442.646-1.442 1.442v16.144c0 .796.646 1.442 1.442 1.442h11.531c.796 0 1.442-.646 1.442-1.442v-9.514h-4.901a3.171 3.171 0 01-3.171-3.17v-4.902h-4.901zm6.63 1.224l5.12 5.119h-3.678a1.442 1.442 0 01-1.442-1.442v-3.677z"
        transform="translate(-1086 -1030)"
        fill="#1A418E"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  );
}

function Discord(props) {
  return (
    <svg viewBox="0 0 38 27" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M205.466 11.768c-1.799 0-3.218 1.577-3.218 3.501 0 1.925 1.451 3.502 3.218 3.502 1.798 0 3.217-1.577 3.217-3.502 0-1.924-1.42-3.501-3.217-3.501m-11.515 0c-1.798 0-3.218 1.577-3.218 3.501 0 1.925 1.451 3.502 3.218 3.502 1.798 0 3.218-1.577 3.218-3.502.031-1.924-1.42-3.501-3.218-3.501m14.67 14.732s-1.136-1.356-2.083-2.555c4.133-1.167 5.71-3.754 5.71-3.754a18.044 18.044 0 01-3.628 1.861c-1.577.663-3.091 1.104-4.574 1.356-3.028.568-5.805.41-8.17-.031a26.46 26.46 0 01-4.638-1.357 18.472 18.472 0 01-2.303-1.072c-.095-.063-.19-.095-.284-.158-.063-.032-.094-.063-.126-.095a11.023 11.023 0 01-.883-.536s1.514 2.524 5.52 3.723c-.946 1.198-2.113 2.618-2.113 2.618-6.972-.22-9.622-4.795-9.622-4.795 0-10.158 4.543-18.392 4.543-18.392C190.512-.094 194.834 0 194.834 0l.316.38c-5.679 1.64-8.297 4.132-8.297 4.132s.694-.379 1.861-.915c3.376-1.483 6.057-1.893 7.161-1.987.19-.032.347-.064.537-.064a26.693 26.693 0 016.372-.063 25.718 25.718 0 019.496 3.029s-2.492-2.366-7.855-4.007l.441-.505s4.322-.094 8.865 3.313c0 0 4.543 8.234 4.543 18.392 0 0-2.682 4.574-9.654 4.795"
        transform="translate(-437 -1080) translate(256 1080)"
        fill="#1A418E"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  );
}

function Decentraland(props) {
  return (
    <svg viewBox="0 0 19 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(-351 -2118) translate(315.263 2115.798) translate(36.093 3.371)"
        stroke="#132338"
        strokeWidth={0.5}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <path d="M8.81 17.625a8.81 8.81 0 008.81-8.81A8.81 8.81 0 100 8.81c0 4.863 3.943 8.814 8.81 8.814z" />
        <path d="M6.24244554 5.73049895L6.24244554 12.34039 11.75 12.34039z" />
        <path d="M0.734891075 12.34039L6.24244554 12.34039 6.24244554 5.73049895z" />
        <path d="M1.763 14.103a9.142 9.142 0 001.763 1.763h10.573a9.142 9.142 0 001.763-1.763h-14.1z" />
        <path d="M3.526 15.862a8.766 8.766 0 005.289 1.763 8.784 8.784 0 005.288-1.763H3.526z" />
        <path d="M11.8201862 9.25632467L11.8201862 14.1033029 15.8620871 14.1033029z" />
        <path d="M11.82 12.34H.735c.272.632.62 1.222 1.028 1.763h10.065V12.34h-.008z" />
        <path d="M7.78654252 14.1033029L11.8243148 14.1033029 11.8243148 9.25632467z" />
        <path d="M11.82 7.931a2.204 2.204 0 10.001-4.408 2.204 2.204 0 000 4.408zM6.242 4.41a1.102 1.102 0 100-2.205 1.102 1.102 0 000 2.204z" />
      </g>
    </svg>
  );
}

function Sandbox(props) {
  return (
    <svg viewBox="0 0 19 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(-350 -2173) translate(315.263 2115.798) translate(34.944 57.789)"
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
        fill="none"
      >
        <circle fill="#132338" cx={9.38487413} cy={9.38487413} r={9.38487413} />
        <path
          fill="#FFF"
          d="M6.23155642 4.42966059L5.21799002 5.443227 5.21799002 9.45995313 6.23155642 10.4735195 10.2482826 10.4735195 10.2482826 12.5006523 8.25868924 12.5006523 8.25868924 11.4870859 5.21799002 11.4870859 5.21799002 13.5142188 6.23155642 14.5277852 12.2754154 14.5277852 13.2889818 13.5142188 13.2889818 9.45995313 12.2754154 8.44638672 8.25868924 8.44638672 8.25868924 6.41925391 10.285822 6.41925391 10.285822 7.43282031 13.3265213 7.43282031 13.3265213 5.4056875 12.3129549 4.39212109 6.23155642 4.39212109z"
        />
      </g>
    </svg>
  );
}

function Facebook(props) {
  return (
    <svg viewBox="0 0 10 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M542.447 59.183c-1.48 1.072-1.154 4.159-1.154 5.253h-1.826l-.046 2.767 1.825.048v8.91h3.65v-8.91l2.715-.048.28-2.72h-2.995v-2.204c0-.845.858-.985 1.217-.985.239 0 1.685.233 1.685.233l.467-2.813s-1.254-.353-2.716-.353c-1.058 0-2.224.185-3.102.822"
        transform="translate(-854 -2321) translate(315.263 2262.798)"
        fill="#132338"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
        opacity={0.334404355}
      />
    </svg>
  );
}

function Youtube(props) {
  return (
    <svg
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          id="a"
          d="M3.11924813 0L2.28300188 3.69999 1.32548062 0 0 0 1.58236313 5.22529875 1.58236313 8.729595 2.93898938 8.729595 2.93898938 5.17596187 4.49075813 0z"
        />
        <path
          d="M4.49 3.534V7.4c0 .937.844 1.556 1.703 1.556.86 0 1.718-.498 1.718-1.54v-3.67c0-.5-.361-1.586-1.718-1.586-.678 0-1.702.423-1.702 1.374m1.19 3.851V3.64c0-.181.256-.362.519-.362.445 0 .52.332.52.422v3.61c0 .18-.166.514-.52.514s-.52-.287-.52-.439"
          id="c"
        />
        <path
          d="M11.165 2.326s.015 4.637 0 4.923c-.165.212-.572.453-.723.453-.15 0-.302-.06-.302-.317V2.326H8.965v5.498c0 .075-.06 1.041.769 1.041s1.43-.785 1.43-.785v.65h1.207V2.326h-1.206z"
          id="e"
        />
        <path
          d="M7.4 13.543v3.027s.099.184.362.212c.263.028.391-.128.391-.354v-2.73c0-.284-.228-.383-.39-.383-.165 0-.364.228-.364.228"
          id="g"
        />
        <path
          d="M11.115 13.315s-.391.086-.391.355v.726h.782v-.726c0-.328-.265-.356-.359-.356l-.032.001"
          id="i"
        />
        <path
          d="M.917 9.408c-1.34.098-1.954 1.175-2.038 1.777-.084.6-.112 4.727 0 5.987.111 1.258.99 1.902 1.73 1.986.74.084 4.201.14 5.555.14s4.383-.07 5.22-.112c.837-.042 1.884-.476 2.05-1.734.169-1.26.182-5.387 0-6.38-.18-.993-1.17-1.594-1.924-1.664-.754-.07-3.7-.097-5.346-.097 0 0-3.908 0-5.247.097zM6.462 10.8h.937v2.16s.242-.427.825-.427c.582 0 .838.499.838.896v3.282c0 .469-.34.882-.767.882s-.853-.341-.896-.455v.383h-.937v-6.72zm-6.65 0h3.154v1.01H1.914v5.711H.878v-5.726H-.188V10.8zm9.974 5.5v-2.615c0-.611.569-1.237 1.407-1.237.81 0 1.25.569 1.25 1.123v1.591H10.71V16.3c0 .312.212.486.411.486.2 0 .398-.188.398-.458v-.61h.923v.71c0 .326-.213 1.221-1.307 1.221-1.094 0-1.35-.909-1.35-1.349zm-5.697.383c.07 0 .398-.185.554-.37-.015-.269 0-3.78 0-3.78h.923v4.988h-.91l-.013-.554s-.47.626-1.023.626c0 0-.654-.043-.654-.64v-4.42h.91v3.85c0 .268.073.306.142.306.026 0 .05-.006.07-.006z"
          id="k"
        />
      </defs>
      <g
        transform="translate(-850 -2264) translate(315.263 2262.798) translate(536.696 1.58)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#132338"
          mask="url(#b)"
          d="M3.11924813 0L2.28300188 3.69999 1.32548062 0 0 0 1.58236313 5.22529875 1.58236313 8.729595 2.93898938 8.729595 2.93898938 5.17596187 4.49075813 0z"
        />
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          d="M4.49 3.534V7.4c0 .937.844 1.556 1.703 1.556.86 0 1.718-.498 1.718-1.54v-3.67c0-.5-.361-1.586-1.718-1.586-.678 0-1.702.423-1.702 1.374m1.19 3.851V3.64c0-.181.256-.362.519-.362.445 0 .52.332.52.422v3.61c0 .18-.166.514-.52.514s-.52-.287-.52-.439"
          fill="#132338"
          mask="url(#d)"
        />
        <mask id="f" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <path
          d="M11.165 2.326s.015 4.637 0 4.923c-.165.212-.572.453-.723.453-.15 0-.302-.06-.302-.317V2.326H8.965v5.498c0 .075-.06 1.041.769 1.041s1.43-.785 1.43-.785v.65h1.207V2.326h-1.206z"
          fill="#132338"
          mask="url(#f)"
        />
        <mask id="h" fill="#fff">
          <use xlinkHref="#g" />
        </mask>
        <path
          d="M7.4 13.543v3.027s.099.184.362.212c.263.028.391-.128.391-.354v-2.73c0-.284-.228-.383-.39-.383-.165 0-.364.228-.364.228"
          fill="#132338"
          mask="url(#h)"
        />
        <mask id="j" fill="#fff">
          <use xlinkHref="#i" />
        </mask>
        <path
          d="M11.115 13.315s-.391.086-.391.355v.726h.782v-.726c0-.328-.265-.356-.359-.356l-.032.001"
          fill="#132338"
          mask="url(#j)"
        />
        <mask id="l" fill="#fff">
          <use xlinkHref="#k" />
        </mask>
        <path
          d="M.917 9.408c-1.34.098-1.954 1.175-2.038 1.777-.084.6-.112 4.727 0 5.987.111 1.258.99 1.902 1.73 1.986.74.084 4.201.14 5.555.14s4.383-.07 5.22-.112c.837-.042 1.884-.476 2.05-1.734.169-1.26.182-5.387 0-6.38-.18-.993-1.17-1.594-1.924-1.664-.754-.07-3.7-.097-5.346-.097 0 0-3.908 0-5.247.097zM6.462 10.8h.937v2.16s.242-.427.825-.427c.582 0 .838.499.838.896v3.282c0 .469-.34.882-.767.882s-.853-.341-.896-.455v.383h-.937v-6.72zm-6.65 0h3.154v1.01H1.914v5.711H.878v-5.726H-.188V10.8zm9.974 5.5v-2.615c0-.611.569-1.237 1.407-1.237.81 0 1.25.569 1.25 1.123v1.591H10.71V16.3c0 .312.212.486.411.486.2 0 .398-.188.398-.458v-.61h.923v.71c0 .326-.213 1.221-1.307 1.221-1.094 0-1.35-.909-1.35-1.349zm-5.697.383c.07 0 .398-.185.554-.37-.015-.269 0-3.78 0-3.78h.923v4.988h-.91l-.013-.554s-.47.626-1.023.626c0 0-.654-.043-.654-.64v-4.42h.91v3.85c0 .268.073.306.142.306.026 0 .05-.006.07-.006z"
          fill="#132338"
          mask="url(#l)"
        />
      </g>
    </svg>
  );
}

function TikTok(props) {
  return (
    <svg viewBox="0 0 17 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M495.373 527.514c-.008-.135-.004-.542-.004-.542h-3.191v12.952a2.806 2.806 0 11-2.042-2.7v-3.206a6 6 0 105.237 5.953v-6.924a5.39 5.39 0 004.388 1.548v-2.999c-2.278-.101-4.257-1.838-4.388-4.082"
        transform="translate(-483 -526)"
        fill="#C7CBD0"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  );
}
