import { Masonry } from "@mui/lab";
import { GeneralInfoListItem, LinkTooltip } from "./ExistingFields.styles";
import { Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import { socialMediaIcons } from "../../../shared/icons/socialMedia";
import { VerticalDivider } from "../../../shared/styles/layout";

const ExistingFields = ({ existingFields }) => {
  const renderSocialMedia = (el) => (
    <Typography>
      {el.data.map((el, i, arr) => (
        <Fragment key={el.platform_name + i}>
          <LinkTooltip text={el.link} onClick={() => window.open(el.link, el.title)}>
            <span className="link">
              {socialMediaIcons[el.platform_name]} {el.platform_name}
            </span>
          </LinkTooltip>
          {i !== arr.length - 1 && <VerticalDivider />}
        </Fragment>
      ))}
    </Typography>
  );

  const renderLinks = (el) => (
    <Typography display="flex" flexWrap="wrap" gap=".5rem 1rem" alignSelf="flex-start">
      {el.data.map((el, i) => (
        <Fragment key={el.desc + i}>
          <span>
            <LinkTooltip text={el.link} onClick={() => window.open(el.link, el.desc)}>
              <span className="link list-item">{el.desc.split(" ").slice(0, 4).join(" ")}</span>
            </LinkTooltip>
          </span>
        </Fragment>
      ))}
    </Typography>
  );

  const renderText = (el) => (
    <Typography className={el.link ? "link" : ""}>
      <LinkTooltip text={el.link} onClick={() => el.link && window.open(el.link, el.title)}>
        <span>
          {el.icon} {el.data}
        </span>
      </LinkTooltip>
    </Typography>
  );

  const renderList = (el) => (
    <Typography>
      {el.data.map((el, i, arr) => (
        <Fragment key={el + i}>
          <span>{el}</span>
          {i !== arr.length - 1 && <VerticalDivider />}
        </Fragment>
      ))}
    </Typography>
  );

  const renderData = (el) =>
    el.title === "Social Media"
      ? renderSocialMedia(el)
      : ["Publications", "Web Mentions"].includes(el.title)
      ? renderLinks(el)
      : el.title === "Founders"
      ? renderList(el)
      : renderText(el);

  return (
    <Grid container spacing={0} >
      {existingFields.map((el) => (
        <GeneralInfoListItem key={el.title}>
          <Typography className={["Publications", "Web Mentions"].includes(el.title) ? "multi-list" : ""}>{el.title}:</Typography>
          {renderData(el)}
        </GeneralInfoListItem>
      ))}
    </Grid>
  );
};

export default ExistingFields;
