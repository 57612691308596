import SectionBlockLoader from "./SectionBlockLoader";


const SectionBlockWithLoader = ({ children, loading }) => {
  if (loading) return <SectionBlockLoader />;

  return <> {children} </>;
};

export default SectionBlockWithLoader;
