import { styled } from "@mui/material";
import { FlexBox } from "../../styles/components/Layout";

export const LoaderContainer = styled(FlexBox)`
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #fff;
  z-index: 10000;
`;