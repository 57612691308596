import { Box, Dialog, DialogContent, IconButton, styled } from "@mui/material";
import theme from "../../../styles/theme";

export const StyledDialog = styled(Dialog)`
  max-width: 85rem;
  margin: 0 auto;
  .MuiPaper-root {
    width: 90%;
    max-width: 85rem;
    height: 77rem;
    max-height: 95%;
    border-radius: 1.2rem;
    box-shadow: 0 19px 19px 0 rgba(0, 0, 0, 0.5);
    display: block;
    margin: 0 auto;

    ${theme.breakpoints.down("lg")} {
      width: 100%;
      margin: 2rem;
    }
    
    ${theme.breakpoints.down("md")} {
      height: 70rem;
    }

    ${theme.breakpoints.down("br768")} {
      width: 100%;
      height: 100%;
      max-height: 100%;
      margin: 0;
      border-radius: 0;
      //padding-top: 10rem;
    }
  }
  
  &.disabledPaddingTop{
    .MuiPaper-root{
      ${theme.breakpoints.down("br768")} {
        padding-top: 0;
      }
    }
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  flex: 0;
  width: 100%;
  max-width: 85rem;
  height: 77rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  margin: 0 auto;

  
  ${theme.breakpoints.down("md")} {
    height: 70rem;
  }

  ${theme.breakpoints.down("br768")} {
    //padding: 5rem 4rem;
    height: 100%;
    align-items: flex-start;
  }
`;

export const CloseDialog = styled(IconButton)`
  position: absolute;
  background: #FFFFFF;
  padding: 0;
  top: 2rem;
  right: 2rem;
  z-index: 1;

  &:hover {
    background: #FFFFFF;
  }
  
  svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  ${theme.breakpoints.down("br768")} {
    &.white {
      svg {
        path {
          fill: #fff;
          stroke: #000;
          stroke-width: 1px;
        }
      }
    }

    &.outlined {
      svg {
        width: 4.2rem;
        height: 4.2rem;
      }
    }
  }
`;

export const LoaderContainer = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
`;
