import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

const SectionBlockLoader = ({ loaderColor }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "30rem",
      }}
    >
      <CircularProgress sx={{ color: loaderColor }} />
    </Box>
  );
};

export default SectionBlockLoader;
