import { SectionContainer } from "../../../../../styles/components/Layout";
import CardsGrid from "../../../../templates/cardsGrid/CardsGrid";
import { useCasesList } from "./UseCases.data";
import { SecondaryTitle } from "../../../../../styles/components/typography";

const UseCases = () => {
  return (
    <SectionContainer pt={{ md: "16rem" }}>
      <SecondaryTitle mb={"8.8rem"}>Use cases</SecondaryTitle>
      <CardsGrid data={useCasesList} spacing={"14rem"} titleWidth={"80%"} />
    </SectionContainer>
  );
};

export default UseCases;
