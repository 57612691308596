import { Box, styled } from "@mui/system";
import theme from "../../../../../styles/theme";

export const GoogleSearchesDateRange = styled(Box)`
  margin-top: 1rem;
  background-color: #f4f5f7;
  border-radius: 1.1rem;
  padding: 1.8rem 2rem;

  p {
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: 0.24px;
    color: #8294b0;
    line-height: normal;
  }

  ${theme.breakpoints.down("br480")} {
    p {
      text-align: center;
    }
  }
`;