import { getCompetitorPercent } from "./Competitors.data";
import { useContext } from "react";
import { BrandInfoContext } from "../../../../../context/BrandInfoContext";


export const useCompetitors = () => {
  const {  competitors } = useContext(BrandInfoContext);

  const competitorsData = competitors
    ? [...Object.entries(competitors)]
      ?.sort(
        (a, b) => Number(b[1].monthly_traffic || b[1].monthly_visits || 0) - Number(a[1].monthly_traffic || a[1].monthly_visits || 0)
      )
      .slice(0, 10)
      .map(([key, el]) => [
        el.company_name || key,
        `${getCompetitorPercent(Number(el.monthly_traffic || el.monthly_visits), Object.values(competitors))}`,
      ])
    : [];

  return {competitorsData}

}