export function Checkbox(props) {
    return (
        <svg viewBox="0 0 36 37" xmlns="http://www.w3.org/2000/svg" {...props}>
          <path
            d="M0 8.286A4.285 4.285 0 014.286 4h27.428A4.285 4.285 0 0136 8.286v27.428A4.285 4.285 0 0131.714 40H4.286A4.285 4.285 0 010 35.714V8.286zm4.286-2.572a2.572 2.572 0 00-2.572 2.572v27.428a2.572 2.572 0 002.572 2.572h27.428a2.572 2.572 0 002.572-2.572V8.286a2.572 2.572 0 00-2.572-2.572H4.286zM27.178 16.25a.86.86 0 010 1.214L16.893 27.75a.86.86 0 01-1.214 0l-5.143-5.143a.86.86 0 010-1.214.86.86 0 011.214 0l4.536 4.538 9.679-9.68a.86.86 0 011.213 0z"
            transform="translate(-1388 -1189) translate(1388 1185.7)"
            fill="#F2A626"
            stroke="none"
            strokeWidth={1}
            fillRule="evenodd"
          />
        </svg>
      )
  }

  export function CloseSvg(props) {
    return (
      <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M1726.7 455.048c-7.816 0-14.152 6.336-14.152 14.152 0 7.816 6.336 14.152 14.152 14.152 7.816 0 14.152-6.336 14.152-14.152 0-7.816-6.336-14.152-14.152-14.152m-15.5 14.152c0-8.561 6.939-15.5 15.5-15.5s15.5 6.939 15.5 15.5-6.939 15.5-15.5 15.5-15.5-6.939-15.5-15.5m9.632-5.868a.676.676 0 01.954 0l4.914 4.915 4.914-4.915a.676.676 0 01.954.954l-4.915 4.914 4.915 4.914a.676.676 0 01-.954.954l-4.914-4.915-4.914 4.915a.676.676 0 01-.954-.954l4.915-4.914-4.915-4.914a.676.676 0 010-.954"
          transform="translate(-1711 -453)"
          fill="#293644"
          stroke="none"
          strokeWidth={1}
          fillRule="evenodd"
          opacity={0.36563837}
        />
      </svg>
    )
  }


export function AttentionUserSvg(props) {
    return (
        <svg viewBox="0 0 15 13" xmlns="http://www.w3.org/2000/svg"{...props}>
            <path
                d="M1915.91 33.534a.784.784 0 00-1.345 0l-5.31 8.85a.784.784 0 00.672 1.188h10.621a.784.784 0 00.673-1.188l-5.311-8.85zm-2.151-.484a1.724 1.724 0 012.957 0l5.311 8.85c.69 1.15-.139 2.613-1.479 2.613h-10.621c-1.34 0-2.169-1.463-1.478-2.612l5.31-8.85zm1.479 2.996c.26 0 .47.211.47.47v2.51a.47.47 0 01-.94 0v-2.51c0-.259.21-.47.47-.47zm0 4.39c.26 0 .47.211.47.47v.628a.47.47 0 01-.94 0v-.627c0-.26.21-.47.47-.47z"
                transform="translate(-1908 -32)"
                fill={props.fill}
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </svg>
    )
}

export function EnergySvg(props) {
  return (
    <svg
      viewBox="0 0 39 39"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        transform="translate(-210 -411) translate(210.679 411.62)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <circle
          stroke="#FFF"
          strokeWidth={3}
          cx={19.1145961}
          cy={19.1145961}
          r={17.6145961}
        />
        <path
          d="M20.294 8.062c1.396-1.571 3.96-.2 3.43 1.833l-1.726 6.619 2.858 1.144a2 2 0 01.753 3.185l-8.289 9.324c-1.396 1.572-3.96.2-3.429-1.833l1.726-6.618-2.859-1.144a2 2 0 01-.753-3.186l8.29-9.324z"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export function QuestionSvg(props) {
  return (
    <svg viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg"{...props}>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M26.005 52.01a25.902 25.902 0 0016.828-6.177c.941-.8 4.785.747 6.645.475 1.86-.272-2.8-4.495-2.259-5.258a25.887 25.887 0 004.791-15.045C52.01 11.643 40.368 0 26.005 0 11.643 0 0 11.643 0 26.005 0 40.368 11.643 52.01 26.005 52.01z"
          fill="#369AFE"
          transform="translate(-210 -7249) translate(210 7249.01)"
        />
        <text
          fontFamily="Hellix-Regular, Hellix"
          fontSize={38.5}
          fontWeight="normal"
          letterSpacing={1.3444438}
          fill="#FFF"
          transform="translate(-210 -7249) translate(210 7249.01)"
        >
          <tspan x={17.2552466} y={39.5760932}>
            {"?"}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export function AnswerSvg(props) {
  return (
    <svg viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg"{...props}>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M26.005 52.01a25.902 25.902 0 0016.828-6.177c.941-.8 4.785.747 6.645.475 1.86-.272-2.8-4.495-2.259-5.258a25.887 25.887 0 004.791-15.045C52.01 11.643 40.368 0 26.005 0 11.643 0 0 11.643 0 26.005 0 40.368 11.643 52.01 26.005 52.01z"
          fill="#E69D22"
          transform="translate(-211 -7327) translate(210 7249.01) translate(1.105 78)"
        />
        <text
          fontFamily="Hellix-Regular, Hellix"
          fontSize={34.5}
          fontWeight="normal"
          letterSpacing={1.20476133}
          fill="#FFF"
          transform="translate(-211 -7327) translate(210 7249.01) translate(1.105 78)"
        >
          <tspan x={14.5052466} y={36.0052466}>
            {"A"}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export function PlusSvg(props) {
  return (
    <svg
      viewBox="0 0 147 148"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M794.805 2171.781l.98 26.874a4 4 0 003.56 3.83l26.077 2.878a6 6 0 015.32 6.487l-1.858 21.23a6 6 0 01-6.5 5.455l-20.71-1.812a4 4 0 00-4.346 4.13l.838 22.928a6 6 0 01-5.996 6.219h-24.754a6 6 0 01-6-6v-27.134a4 4 0 00-3.652-3.985l-27.117-2.372a6 6 0 01-5.461-6.412l1.698-23.347a6 6 0 016.642-5.528l23.45 2.588a4 4 0 004.44-3.976V2172a6 6 0 016-6h21.393a6 6 0 015.996 5.781z"
          id="b"
        />
        <filter
          x="-30.6%"
          y="-25.0%"
          width="161.1%"
          height="163.5%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feMorphology
            radius={7}
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy={7}
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation={7.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.183740576 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        transform="translate(-705 -2151) rotate(-11 778.005 2218)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <path
          stroke="#FFF"
          strokeWidth={7}
          d="M788.809 2162.5a9.473 9.473 0 016.594 2.661 9.473 9.473 0 012.9 6.492l.98 26.875 26.523 3.356a9.472 9.472 0 016.304 3.419 9.472 9.472 0 012.118 6.852l-1.857 21.23a9.469 9.469 0 01-3.222 6.334 9.474 9.474 0 01-6.788 2.323l-20.992-1.832.294 23.443a9.47 9.47 0 01-2.535 6.815 9.47 9.47 0 01-6.682 3.028l-25.03.004a9.47 9.47 0 01-6.718-2.783 9.47 9.47 0 01-2.782-6.717v-27.134l-27.574-2.87a9.471 9.471 0 01-6.405-3.304 9.471 9.471 0 01-2.242-6.85l1.698-23.346a9.47 9.47 0 013.263-6.497 9.47 9.47 0 016.973-2.283l23.731 2.615.556-22.331a9.47 9.47 0 012.782-6.717 9.47 9.47 0 016.718-2.783z"
          fill="#E69D22"
        />
      </g>
    </svg>
  )
}

export function BulbIcon(props) {
  return (
    <svg
      width="2.2rem"
      height="2.2rem"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M485.54 2631.633a.75.75 0 011.06 0l1 1a.75.75 0 11-1.06 1.061l-1-1a.75.75 0 010-1.06m10.53 1.28a6.251 6.251 0 00-4.752 10.31c.775.906 1.615 1.973 2.096 3.19h5.31c.482-1.217 1.321-2.284 2.097-3.19a6.25 6.25 0 00-4.752-10.311m2.278 15h-4.555c.018.172.027.345.027.522v.728a2.25 2.25 0 004.5 0v-.728c0-.177.01-.35.028-.522m-10.028-8.75a7.75 7.75 0 1113.641 5.036c-.88 1.031-1.658 2.068-1.983 3.175a3.766 3.766 0 00-.158 1.061v.728a3.75 3.75 0 11-7.5 0v-.728a3.73 3.73 0 00-.158-1.06c-.324-1.108-1.1-2.145-1.983-3.176a7.72 7.72 0 01-1.859-5.036m18.281-7.53a.75.75 0 010 1.061l-1 1a.75.75 0 11-1.06-1.06l1-1a.75.75 0 011.06 0m-19 13a.75.75 0 010 1.06l-1 1a.75.75 0 11-1.06-1.06l1-1a.75.75 0 011.06 0m16.94 0a.75.75 0 011.06 0l1 1a.75.75 0 11-1.06 1.06l-1-1a.75.75 0 010-1.06"
        transform="translate(-485 -2631)"
        fill="#5680AD"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}

export function PixelIcon(props) {
  return (
    <svg
      width="2.2rem"
      height="2.2rem"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.205 18.25a2.75 2.75 0 012.75-2.75h12a2.75 2.75 0 012.75 2.75v1.25h1.25a2.75 2.75 0 012.75 2.75v1a.75.75 0 01-1.5 0v-1c0-.69-.56-1.25-1.25-1.25h-1.25v9.25a2.75 2.75 0 01-2.75 2.75h-9.25v1.25c0 .69.56 1.25 1.25 1.25h1a.75.75 0 010 1.5h-1a2.75 2.75 0 01-2.75-2.75V33h-1.25a2.75 2.75 0 01-2.75-2.75v-12zm2.75-1.25c-.69 0-1.25.56-1.25 1.25v12c0 .69.56 1.25 1.25 1.25h12c.69 0 1.25-.56 1.25-1.25v-12c0-.69-.56-1.25-1.25-1.25h-12zm1.25 6.25a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75zm0 4a.75.75 0 01.75-.75h8a.75.75 0 010 1.5h-8a.75.75 0 01-.75-.75zm16.75-.75a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 01.75-.75zm0 6a.75.75 0 01.75.75v1a2.75 2.75 0 01-2.75 2.75h-1a.75.75 0 010-1.5h1c.69 0 1.25-.56 1.25-1.25v-1a.75.75 0 01.75-.75zm-9.75 3.75a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-2a.75.75 0 01-.75-.75z"
        transform="translate(-678 -3814) translate(465.795 3799) translate(190)"
        fill="#FFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}

export function ForwardIcon (props) {
  return (
    <svg
      width="3.8rem"
      height="1.6rem"
      viewBox="0 0 38 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        transform="translate(-617 -6370) translate(463 6356) translate(154.256 14.059)"
        fill="#FFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
        opacity={0.677589053}
      >
        <path
          transform="rotate(90 31.25 7.72)"
          d="M31.25 1.47058824L38.9705882 13.9705882 23.5294118 13.9705882z"
        />
        <path
          transform="rotate(90 18.75 7.72)"
          d="M18.75 1.47058824L26.4705882 13.9705882 11.0294118 13.9705882z"
        />
        <path
          transform="rotate(90 6.25 7.72)"
          d="M6.25 1.47058824L13.9705882 13.9705882 -1.47058824 13.9705882z"
        />
      </g>
    </svg>
  )
}

export function TriangleIcon(props) {
  return (
    <svg viewBox="0 0 13 16"  width={'1.3rem'} height={'1.6rem'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        transform="translate(-460 -10065) translate(160.744 9382.74) translate(117.756 668.7) translate(0 14.059) rotate(90 187.75 7.72)"
        fill="#FFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
        opacity={0.677589053}
        d="M187.75 1.47058824L195.470588 13.9705882 180.029412 13.9705882z"
      />
    </svg>
  )
}

export function CodeIcon(props) {
  return (
    <svg
      viewBox="0 0 66 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M550.224 848.041c.757.19 1.217.958 1.028 1.711l-11.294 45.176a1.414 1.414 0 01-1.711 1.03 1.413 1.413 0 01-1.028-1.713l11.294-45.176a1.415 1.415 0 011.711-1.028m-21.933 8.843c.554.551.554 1.446 0 1.997l-13.117 13.118 13.117 13.12c.554.55.554 1.446 0 1.996-.55.55-1.442.55-1.996 0l-14.117-14.117a1.413 1.413 0 010-1.996l14.117-14.118a1.413 1.413 0 011.996 0m31.886 0a1.413 1.413 0 011.996 0l14.117 14.118c.554.55.554 1.445 0 1.996l-14.117 14.117a1.413 1.413 0 01-1.996-1.996l13.12-13.12-13.12-13.118a1.413 1.413 0 010-1.997"
        transform="translate(-511 -848)"
        fill="#FFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}

export function StoreIcon(props) {
  return (
    <svg
      viewBox="0 0 54 53"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.357 12.704A3.787 3.787 0 009.33 14.22l-5.048 6.73a3.775 3.775 0 00-.757 2.272v7.15A5.052 5.052 0 008.57 35.42a5.05 5.05 0 005.048-5.048V29.11a1.262 1.262 0 012.524 0v1.262a5.05 5.05 0 005.047 5.048 5.05 5.05 0 005.048-5.048V29.11a1.262 1.262 0 012.524 0v1.262a5.05 5.05 0 005.048 5.048 5.05 5.05 0 005.047-5.048V29.11a1.262 1.262 0 012.524 0v1.262a5.05 5.05 0 006.057 4.947 5.052 5.052 0 004.038-4.947v-7.15c0-.82-.265-1.618-.757-2.271l-5.048-6.731a3.794 3.794 0 00-3.028-1.515H12.357zM40.12 34.558a7.566 7.566 0 01-6.31 3.385 7.563 7.563 0 01-6.309-3.385 7.566 7.566 0 01-6.31 3.385 7.563 7.563 0 01-6.309-3.385 7.566 7.566 0 01-6.31 3.385V56.87a3.787 3.787 0 003.786 3.786h6.31v-8.833a6.309 6.309 0 016.31-6.31h5.047a6.309 6.309 0 016.31 6.31v8.833h6.309a3.787 3.787 0 003.786-3.786V37.943a7.563 7.563 0 01-6.31-3.385zm8.833 2.953A7.573 7.573 0 0054 30.371v-7.15a6.32 6.32 0 00-1.262-3.786l-5.048-6.73a6.309 6.309 0 00-5.047-2.524H12.357a6.305 6.305 0 00-5.047 2.523l-5.048 6.731A6.32 6.32 0 001 23.221v7.15c0 3.299 2.107 6.1 5.048 7.14v19.36a6.309 6.309 0 006.31 6.31h30.285a6.309 6.309 0 006.31-6.31v-19.36zM33.81 60.657v-8.833a3.787 3.787 0 00-3.786-3.786h-5.048a3.787 3.787 0 00-3.786 3.786v8.833h12.62z"
        transform="translate(-974 -3454) translate(973.795 3443.82)"
        fill="#FFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}

export function LoudspeakerIcon(props) {
  return (
    <svg
      viewBox="0 0 54 54"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M45.577 13.978c0-1.888-2.283-2.836-3.619-1.498-6.175 6.176-12.13 9.791-21.11 9.791H8.835a3.802 3.802 0 00-3.801 3.801v10.136a3.8 3.8 0 003.8 3.8h12.014c.23 0 .456.003.682.009 8.61.18 14.415 3.775 20.428 9.788 1.336 1.335 3.619.388 3.619-1.5V13.978zm-5.41-3.292c2.929-2.931 7.944-.854 7.944 3.292v10.827h1.267a6.336 6.336 0 010 12.67H48.11v10.83c0 4.145-5.015 6.22-7.944 3.292-5.319-5.32-10.19-8.408-17.02-8.963l3.126 12.497c.978 3.903-1.974 7.683-5.998 7.683a6.186 6.186 0 01-6-4.682l-3.897-15.59H8.835A6.334 6.334 0 012.5 36.209V26.072a6.336 6.336 0 016.335-6.334h12.013c8.086 0 13.43-3.163 19.319-9.052zM12.993 42.543l3.74 14.975a3.65 3.65 0 007.082-1.771l-3.301-13.204h-7.521zm35.118-7.602h1.267a3.8 3.8 0 100-7.602H48.11v7.602z"
        transform="translate(-1485 -3453) translate(1482.5 3444.05)"
        fill="#FFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}