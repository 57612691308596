export const sectionLabel = {
  brand_description: 'Brand Overview',
  general_info: 'Brand Insights',
  google_searches: 'SEO Keyword Trends',
  competitors: 'Competitive Landscape',
  organic_growth: 'Organic growth Tracker',
  potential_users: 'Potential clients detection',
  community_sentiment: 'Community Sentiment Analysis',
}

export const sectionOpenItems = {
  brand_description: 'brand_description',
  general_info: 'general_info',
  google_searches: 'google_searches',
  competitors: 'competitors',
  organic_growth: 'organic_growth',
  potential_users: 'potential_users',
  community_sentiment: 'community_sentiment',
}