import YourBrand from "./yourBrand/YourBrand";
import PromotionalStatus from "./promotionalStatus/PromotionalStatus";
import { SectionWrapper } from "./shared/styles/layout";
import { BrandInfoTitle } from "./BrandInfo.styles";
import BrandAnalysis from "../../shared/brandAnalysis/BrandAnalysis";
import { useBreakpoints } from "../../../utils/hooks/useBreakpoints";
import BrandInfoMobile from "./mobile/BrandInfoMobile";
import { useContext, useEffect } from "react";
import { BrandInfoContext } from "../../../context/BrandInfoContext";

const BrandInfo = () => {
  const {smallMobileScreen} = useBreakpoints()

  // if(smallMobileScreen){
  //   return <BrandInfoMobile/>
  // }


  return (
    <div style={{ paddingTop: "10rem" }}>
      <SectionWrapper>
        <BrandInfoTitle variant={"h2"}>Brand analysing tool</BrandInfoTitle>
      </SectionWrapper>
      <BrandAnalysis />
      <YourBrand />
      {/*<PromotionalStatus />*/}
    </div>
  );
};

export default BrandInfo;
