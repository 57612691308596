//Core
import React, { useContext } from "react";
//Styles
import {
  BrandAnalysisBackground,
  BrandAnalysisButton,
  BrandAnalysisFlexWrap,
  BrandAnalysisForm,
  BrandAnalysisFormLabel,
  BrandAnalysisMobileBox,
  BrandAnalysisMobileCloseInfo,
  BrandAnalysisMobileOpenInfo,
  BrandAnalysisRequiredText,
  BrandAnalysisSectionInfo,
  BrandAnalysisSectionInfoMobile,
  BrandAnalysisTitleBackground,
  BrandAnalysisTitleContainer,
  ErrorMessage,
} from "./BrandAnalysis.styles";
import { SectionContainer } from "../../mainContent/brandInfo/shared/styles/layout";
import { capitalize, OutlinedInput, Typography } from "@mui/material";
import { FlexBox, FlexResponsive } from "../../../styles/components/Layout";
import { CheckboxWithLabel } from "./styles/checkbox";
import { BrandAnalysisOtherTooltip } from "./tooltip/other/otherSectionsTooltip.styles";
//Components
import { OtherSectionsTooltip } from "./tooltip/other/otherSectionsTooltip";
//Context
import { BrandInfoContext } from "../../../context/BrandInfoContext";
//Hooks
import { useBrandAnalysis } from "./useBrandAnalysis";
//Images
import { FreeSvg } from "./img/free";
//Utils
import { sectionLabel, sectionOpenItems } from "./help/sectionLabel";
import { fetchItems, fetchSectionsItems } from "../../../utils/fetchItems";
import { useBreakpoints } from "../../../utils/hooks/useBreakpoints";
import { BrandAnalysisSectionTooltip } from "./tooltip/section/sectionTooltip.styles";
import { SectionTooltip } from "./tooltip/section/sectionTooltip";

//TODO: Refactor this component
const BrandAnalysis = () => {
  const { fetchList, url, brandSectionsList, isButtonRunWasClicked } = useContext(BrandInfoContext);
  const { tabletScreen } = useBreakpoints();
  const {
    fetchBrandInfoData,
    input,
    handleChangeInput,
    error,
    buttonRunDisabled,
    brandSections,
    otherSections,
    reasonRadio,
    setReasonRadio,
    handleBrandSections,
    openTooltip,
    handleTooltip,
    handleTooltipButton,
    isBrandInfoPage,
    hideMobileInfo,
    setHideMobileInfo,
    openSectionTooltip,
    setOpenSectionTooltip,
  } = useBrandAnalysis();

  return (
    <BrandAnalysisBackground>
      <BrandAnalysisTitleBackground>
        <BrandAnalysisTitleContainer>
          <FreeSvg id={"free"} />
          <Typography variant={"h2"} width={"fit-content"}>
            {"BI TOOL FOR WEB3 BRANDS"}
          </Typography>
        </BrandAnalysisTitleContainer>
      </BrandAnalysisTitleBackground>

      {!hideMobileInfo && (
        <>
          {/*Input*/}
          <BrandAnalysisMobileBox className={"input"}>
            <SectionContainer>
              <BrandAnalysisForm>
                <BrandAnalysisFormLabel>{"Enter your brand’s website URL:"}</BrandAnalysisFormLabel>
                <OutlinedInput
                  placehloder="https://"
                  value={input}
                  onChange={handleChangeInput}
                />
              </BrandAnalysisForm>
            </SectionContainer>
          </BrandAnalysisMobileBox>

          {/*Checkboxes*/}
          <BrandAnalysisMobileBox className={"checkboxes"}>
            <SectionContainer>
              <FlexResponsive>
                <BrandAnalysisFormLabel>{"Choose the data what you interested in:"}</BrandAnalysisFormLabel>
                <BrandAnalysisRequiredText>{"(*Choose at least 1 parameter)"}</BrandAnalysisRequiredText>
              </FlexResponsive>
              <BrandAnalysisFlexWrap>
                {!!Object.keys(brandSections).length &&
                  Object.entries(brandSections).map(([section, checked]) => {
                    return (
                      <FlexBox className={`section ${checked ? "checked" : ""}`} key={section}>
                        {/*Checkbox with label*/}
                        <CheckboxWithLabel
                          name={section}
                          label={sectionLabel[section]}
                          checked={checked}
                          onChange={handleBrandSections}
                          className={"border"}
                          disabled={isBrandInfoPage && url !== "" && brandSectionsList.includes(sectionOpenItems[section])}
                        />
                        {/*Info tooltip*/}
                        <BrandAnalysisSectionTooltip
                          title={<SectionTooltip section={section} />}
                          open={openSectionTooltip === section}
                          // open={checked}
                          onClose={() => setOpenSectionTooltip("")}
                          onOpen={() => setOpenSectionTooltip(section)}
                        >
                          <BrandAnalysisSectionInfoMobile
                            onClick={() => setOpenSectionTooltip(openSectionTooltip === section ? "" : section)}
                          >
                            <BrandAnalysisSectionInfo className={`${checked ? "selected" : ""}`}>
                              <Typography>{"i"}</Typography>
                            </BrandAnalysisSectionInfo>
                          </BrandAnalysisSectionInfoMobile>
                        </BrandAnalysisSectionTooltip>
                      </FlexBox>
                    );
                  })}
                {!!otherSections.length &&
                  otherSections.map((section) => (
                    <CheckboxWithLabel
                      key={section}
                      name={section}
                      label={capitalize(section)}
                      checked={true}
                      onChange={() => {}}
                      className={"others"}
                    />
                  ))}

                <FlexResponsive sx={{ order: { xs: 2, md: 0 } }}>
                  <BrandAnalysisOtherTooltip
                    title={<OtherSectionsTooltip handleButton={handleTooltipButton} />}
                    open={openTooltip}
                    onClose={handleTooltip}
                  >
                    <BrandAnalysisFormLabel className={"other"} onClick={handleTooltip}>
                      {"Other:"}
                    </BrandAnalysisFormLabel>
                  </BrandAnalysisOtherTooltip>
                  <BrandAnalysisRequiredText>{"(Add  parameter you would like to see here)"}</BrandAnalysisRequiredText>
                </FlexResponsive>
              </BrandAnalysisFlexWrap>
            </SectionContainer>
          </BrandAnalysisMobileBox>

          {/*Reason radio buttons*/}
          {!isButtonRunWasClicked &&
            <BrandAnalysisMobileBox className={"radio"}>
              <SectionContainer>
                <FlexResponsive>
                  <BrandAnalysisFormLabel>{"Choose the reason to use WEB3M BI tool: "}</BrandAnalysisFormLabel>
                  <BrandAnalysisRequiredText>{"(*Choose 1 parameter)"}</BrandAnalysisRequiredText>
                </FlexResponsive>
                <BrandAnalysisFlexWrap>
                  <CheckboxWithLabel
                    name={"bi"}
                    label={"Just to get more data about my WEB3 brand"}
                    checked={reasonRadio === "bi"}
                    onChange={() => setReasonRadio("bi")}
                    className={"border reason"}
                  />
                  <CheckboxWithLabel
                    name={"copilot"}
                    label={"Use this data to run AD campaign"}
                    checked={reasonRadio === "copilot"}
                    onChange={() => setReasonRadio("copilot")}
                    className={"border reason"}
                  />
                </BrandAnalysisFlexWrap>
              </SectionContainer>
            </BrandAnalysisMobileBox>
          }

          {/*Run/Add button*/}
          <BrandAnalysisMobileBox className={"run"}>
            <SectionContainer>
              <FlexResponsive>
                <FlexBox sx={{ justifyContent: { xs: "center", md: "flex-start" } }}>
                  <BrandAnalysisButton
                    onClick={fetchBrandInfoData}
                    loading={
                      !!brandSectionsList.filter((el) => fetchList.includes(fetchSectionsItems[el])).length ||
                      fetchList.includes(fetchItems.feedback) ||
                      fetchList.includes(fetchItems.brand_profile)
                    }
                    disabled={buttonRunDisabled}
                  >
                    {isButtonRunWasClicked ? "Add" : "Run"}
                  </BrandAnalysisButton>
                  {buttonRunDisabled && !fetchList.length && (
                    <BrandAnalysisRequiredText>{"*Please select required"}</BrandAnalysisRequiredText>
                  )}
                </FlexBox>
                {tabletScreen && (
                  <BrandAnalysisMobileCloseInfo onClick={() => setHideMobileInfo(true)}>{"Fold"}</BrandAnalysisMobileCloseInfo>
                )}
              </FlexResponsive>
            </SectionContainer>
          </BrandAnalysisMobileBox>
        </>
      )}
      {/*Add info mobile*/}
      {hideMobileInfo && tabletScreen && (
        <BrandAnalysisMobileBox className={"run"}>
          <SectionContainer sx={{ display: "flex", justifyContent: "center" }}>
            <BrandAnalysisMobileOpenInfo onClick={() => setHideMobileInfo(false)}>{"Add / change info"}</BrandAnalysisMobileOpenInfo>
          </SectionContainer>
        </BrandAnalysisMobileBox>
      )}

      <ErrorMessage variant={"filled"} severity={"error"} className={error !== "" ? "display" : ""}>
        {error}
      </ErrorMessage>
    </BrandAnalysisBackground>
  );
};

export default BrandAnalysis;
