import { Box, Stack, styled } from "@mui/material";
import { mobileScreen } from "../../../../utils/themeBreakpoints";
import { Link } from "react-router-dom";

export const NetworkBackground = styled(Box)`
  background-color: #fafafa;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const NetworkListItem = styled(Stack)`
  > svg {
    height: 8rem;
    align-self: flex-start;
    margin-bottom: 2rem;

    ${mobileScreen} {
      align-self: center;
    }

    > path {
      fill: #5680ad;
    }
  }

  ${mobileScreen} {
    p {
      text-align: center;
      width: 80%;
      margin: 0 auto 5rem;
    }
  }
`;

export const NetworkLink = styled(Link)`
  ${mobileScreen}{
    width: 100%;
  }
`