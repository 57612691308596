//Core
import React from "react";
import { PopupButton } from "@typeform/embed-react";
//Styles
import { Box } from "@mui/material";
import { NetworkBackground, NetworkLink, NetworkListItem } from "./Network.styles";
import { FlexResponsive, ImgBox, SectionContainer } from "../../../../styles/components/Layout";
import Grid2 from "@mui/material/Unstable_Grid2";
import { SecondaryButton } from "../../../../styles/components/buttons";
import { SecondaryTitle, SectionParagraph } from "../../../../styles/components/typography";
//Images
import image from "./img/Network.image.webp";
import { BulbIcon, PixelIcon } from "../../../../assets/svg/icons";
//Utils
import { networkList } from "./Network.data";
import { pathWithUtm } from "../../../../utils/pathWithUtm";

const Network = () => {
  return (
    <Box position={"relative"}>
      <NetworkBackground />
      <SectionContainer sx={{ p: "15rem 0"}}>
        <SecondaryTitle maxWidth={"65rem"} m={{ xs: "0 auto 12rem", md: "0 0 12rem" }}>
          Introducing the web3 data network. <br /> It’s better for everyone
        </SecondaryTitle>
        <Grid2 container>
          <Grid2 xs={12} md={6} container rowSpacing={"2rem"} columnSpacing={"12rem"}>
            {networkList?.map((el) => (
              <Grid2 xs={12} md={6} key={el.text}>
                <NetworkListItem>
                  {el.icon}
                  <SectionParagraph>{el.text}</SectionParagraph>
                </NetworkListItem>
              </Grid2>
            ))}
            <Grid2 xs={12}>
              <FlexResponsive columnGap={"2.8rem"} rowGap={"4rem"} sx={{transform: 'translateY(6rem)', mb: {xs: '3rem', br480: 0}}}>
                <NetworkLink to={pathWithUtm("/pixel")}>
                  <SecondaryButton startIcon={<BulbIcon />}>Learn more</SecondaryButton>
                </NetworkLink>{" "}
                {/*<NetworkLink href={"/signup"} >*/}
                <SecondaryButton className={"dark"} startIcon={<PixelIcon />} component={PopupButton} id={"WRwT04gx"}>
                  Add Web3 pixel
                </SecondaryButton>
                {/*</NetworkLink>*/}
              </FlexResponsive>
            </Grid2>
          </Grid2>
          <Grid2 xs={12} md={6} className={"desktopOnly"}>
            <ImgBox sx={{transform: 'translateX(5rem) scale(1.1)'}}>
              <img src={image} alt={'network'} />
            </ImgBox>
          </Grid2>
        </Grid2>
      </SectionContainer>
    </Box>
  );
};

export default Network;
