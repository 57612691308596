import {
  AnalyticsIcon,
  CommunityIcon,
  DefiIcon,
  DEXIcon,
  GamingIcon,
  MetaverseIcon,
  NFTIcon,
  SocialIcon
} from "./img/Promote.icons";

export const promoteList = [
  { title: "DEFI", icon: DefiIcon() },
  { title: "Social Media channel", icon: SocialIcon() },
  { title: "Metaverse", icon: MetaverseIcon() },
  { title: "Gaming \n platform", icon: GamingIcon() },
  { title: "NFT", icon: NFTIcon() },
  { title: "Crypto analytic tools", icon: AnalyticsIcon() },
  { title: "DEX", icon: DEXIcon() },
  { title: "Community platform", icon: CommunityIcon() },
];
