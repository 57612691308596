//Core
import {Fragment, useEffect, useId} from "react";
import {Formik} from "formik";
//Styles
import {
    ButtonsBox,
    FormBox, FormContainer, FormInput, FormInputName, LoaderButton,
    SupportFileAdd, SupportFootnote,
    SupportImageBox,
    SupportThankYouText, SupportThankYouTitle,
    SupportTitle
} from "./Forms.styles";
import {Box, IconButton, Typography} from "@mui/material";
//Images
import ThankYou from "./img/thankYou";
import {Trash} from "./img/trash";
import AddIcon from "./img/addIcon";
//Hooks
import {useSupportForm} from "./useSupportForm";
import {FlexBox} from "../../../../styles/components/Layout";


export const SupportForm = ({setIs404}) => {
    const {
        formData,
        uploadFile,
        deleteFile,
        loadingImage,
        loadingForm,
        sendForm,
        validationSchema,
        status,
        setStatus,
    } = useSupportForm(setIs404)
    const id = useId()

    useEffect(() => {
        setStatus(false)
    }, [])

    return (<FormContainer>
        {status
            ? <FormBox alignItems={'center'} margin={'15vh auto 22.5rem'}>
                <FlexBox marginTop={'4.3rem'}><ThankYou/></FlexBox>
                <SupportThankYouTitle>{'Thank you !'}</SupportThankYouTitle>
                <SupportThankYouText>{'Your message was successfully sent, we will answer you as soon as possible.'}</SupportThankYouText>

            </FormBox>
            : <FormBox margin={'auto'}>
                <SupportTitle>
                    {'Please get in touch and our expert support team will answer all your questions.'}
                </SupportTitle>
                <Formik initialValues={formData}
                        validationSchema={validationSchema}
                        onSubmit={async (values, {setSubmitting}) => {
                            await sendForm(values)
                            setSubmitting(false);
                        }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <FormInputName>{'First Name *'}</FormInputName>
                            <FormInput
                                placeholder={'Type here'}
                                name={'first_name'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.first_name}
                                error={touched.first_name && Boolean(errors.first_name)}
                                helperText={errors.first_name && touched.first_name && errors.first_name}
                            />
                            <FormInputName>{'Last Name'}</FormInputName>
                            <FormInput
                                placeholder={'Type here'}
                                name={'last_name'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.last_name}
                            />
                            <FormInputName>{'Email to return *'}</FormInputName>
                            <FormInput
                                placeholder={'Type here'}
                                name={'email_to_return'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email_to_return}
                                error={touched.email_to_return && Boolean(errors.email_to_return)}
                                helperText={errors.email_to_return && touched.email_to_return && errors.email_to_return}
                            />
                            <FormInputName>{'Title *'}</FormInputName>
                            <FormInput
                                placeholder={'Type here'}
                                name={'issue_title'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.issue_title}
                                error={touched.issue_title && Boolean(errors.issue_title)}
                                helperText={errors.issue_title && touched.issue_title && errors.issue_title}
                            />
                            <FormInputName>{'Description *'}</FormInputName>
                            <FormInput
                                placeholder={'Type here'}
                                name={'issue_desc'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.issue_desc}
                                error={touched.issue_desc && Boolean(errors.issue_desc)}
                                helperText={errors.issue_desc && touched.issue_desc && errors.issue_desc}
                                multiline
                                minRows={5}
                            />
                            <FormInputName>{'Add files ( screenshots )'}</FormInputName>
                            <ButtonsBox>
                                {Array(4).fill('').map((_, i) => (
                                    <Fragment key={id + i}>{!formData.issue_screenshots_links[i]
                                        ? <SupportFileAdd
                                            component={'label'}
                                            loading={loadingImage[id + i]}
                                            loadingIndicator="Loading…"
                                        >
                                            <input hidden
                                                   type={'file'}
                                                   name={'issue_screenshots_links'}
                                                   accept="image/*"
                                                   id={id + i}
                                                   onChange={(e) => uploadFile(e, i)}
                                            />
                                            {!loadingImage[id + i] && <>
                                                <AddIcon/>
                                                <Typography>{'Click to add'}</Typography>
                                            </>}
                                        </SupportFileAdd>
                                        : <SupportImageBox>
                                            <IconButton onClick={() => deleteFile(i)}>
                                                <Trash/>
                                            </IconButton>
                                            < img src={formData.issue_screenshots_links[i]} alt={'screenshot'}/>
                                        </SupportImageBox>
                                    }
                                    </Fragment>
                                ))}
                            </ButtonsBox>
                            <ButtonsBox>
                                <LoaderButton
                                    variant={'contained'}
                                    color={'success'}
                                    type={'submit'}
                                    loading={loadingForm}
                                    disabled={isSubmitting}
                                >
                                    {'Send'}
                                </LoaderButton>
                                <SupportFootnote>{'* Required field'}</SupportFootnote>
                            </ButtonsBox>
                        </form>
                    )}
                </Formik>
            </FormBox>
        }
    </FormContainer>)
}