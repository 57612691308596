export function StarIcon(props) {
  return (
    <svg
      viewBox="0 0 113 109"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path id="star-a" d="M0 0L112.108461 0 112.108461 108 0 108z" />
      </defs>
      <g
        transform="translate(-589 -7983) translate(-20 5305.764) translate(609.74 2678.2)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <mask id="star-b" fill="#53baff">
          <use xlinkHref="#star-a" />
        </mask>
        <path
          d="M49.425 4.12c2.715-5.494 10.55-5.494 13.26 0l13.354 27.088 29.763 4.44c6.026.897 8.436 8.288 4.1 12.57L88.376 69.471l5.012 29.9c1.01 6.032-5.322 10.61-10.732 7.762l-26.6-14-26.604 14c-5.406 2.848-11.743-1.73-10.728-7.761l5.002-29.901L2.208 48.218c-4.336-4.282-1.927-11.673 4.1-12.57l29.763-4.44L49.425 4.12zm8.84 2.179c-.907-1.828-3.519-1.828-4.42 0L39.348 35.702l-32.316 4.82c-2.005.3-2.809 2.764-1.365 4.188L29.01 67.766l-5.425 32.419c-.335 2.01 1.77 3.538 3.573 2.592l28.9-15.212 28.896 15.212c1.798.946 3.912-.582 3.577-2.592L83.1 67.766l23.342-23.056c1.444-1.424.64-3.888-1.37-4.189l-32.31-4.82L58.265 6.3z"
          fill="#53BAFF"
          mask="url(#star-b)"
        />
      </g>
    </svg>
  )
}

export function AvatarIcon(props) {
  return (
    <svg
      width="108px"
      height="109px"
      viewBox="0 0 108 109"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1191 2682.895c-27.23 0-49.304 22.074-49.304 49.305 0 12.34 4.531 23.619 12.025 32.268 4.532-11.406 14.195-20.234 26.15-23.666-6.048-3.719-10.086-10.382-10.086-17.994 0-11.678 9.509-21.13 21.215-21.13s21.215 9.452 21.215 21.13c0 7.612-4.038 14.275-10.086 17.994 11.955 3.432 21.614 12.26 26.15 23.666 7.494-8.65 12.025-19.928 12.025-32.268 0-27.23-22.074-49.305-49.304-49.305m33.649 85.339c-4.611-14.101-17.933-24.295-33.649-24.295-15.716 0-29.038 10.194-33.654 24.295 8.814 8.231 20.642 13.27 33.654 13.27 13.012 0 24.84-5.039 33.649-13.27M1137 2732.2c0-29.823 24.173-54 54-54s54 24.177 54 54c0 15.02-6.137 28.61-16.031 38.396-9.753 9.645-23.168 15.604-37.969 15.604-14.8 0-28.22-5.96-37.969-15.604-9.894-9.786-16.031-23.375-16.031-38.396m54-25.827c-9.133 0-16.52 7.368-16.52 16.435 0 9.068 7.387 16.435 16.52 16.435s16.52-7.367 16.52-16.435c0-9.067-7.387-16.435-16.52-16.435"
        transform="translate(-1117 -7983) translate(-20 5305.764)"
        fill="#53BAFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}

export function GlobeIcon(props) {
  return (
    <svg
      width={'10.9rem'}
      height={'10.9rem'}
      viewBox="0 0 109 109"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path id="globe-a" d="M0 0L107.998591 0 107.998591 108 0 108z" />
      </defs>
      <g
        transform="translate(-1679 -7983) translate(-20 5305.764) translate(1699.706 2678.2)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <mask id="globe-b" fill="#fff">
          <use xlinkHref="#globe-a" />
        </mask>
        <path
          d="M4.75 51.652h28.137c.216-13.542 2.3-25.835 5.62-34.978 1.607-4.404 3.532-8.18 5.762-11.016C22.444 10.025 5.826 28.803 4.751 51.652zM54 0c-29.822 0-54 24.178-54 54s24.178 54 54 54c29.826 0 54-24.178 54-54s-24.174-54-54-54zm0 4.696c-1.649 0-3.532.91-5.527 3.24-1.996 2.324-3.902 5.818-5.546 10.344-3.099 8.523-5.123 20.257-5.343 33.372h32.832c-.216-13.115-2.24-24.85-5.34-33.372-1.648-4.526-3.55-8.02-5.545-10.344-2-2.33-3.879-3.24-5.531-3.24zM75.11 51.652c-.216-13.542-2.3-25.835-5.625-34.978-1.601-4.404-3.527-8.18-5.752-11.016 21.825 4.367 38.443 23.145 39.518 45.994h-28.14zm-4.695 4.696H37.583c.22 13.115 2.244 24.85 5.343 33.372 1.644 4.526 3.55 8.02 5.546 10.344 1.995 2.33 3.878 3.24 5.527 3.24 1.652 0 3.53-.91 5.531-3.24 1.996-2.324 3.897-5.818 5.546-10.344 3.099-8.523 5.123-20.257 5.339-33.372zm-6.682 45.994c2.225-2.836 4.15-6.612 5.752-11.016 3.324-9.143 5.41-21.436 5.625-34.978h28.141c-1.075 22.849-17.693 41.627-39.518 45.994zm-19.464 0c-2.23-2.836-4.155-6.612-5.761-11.016-3.32-9.143-5.405-21.436-5.621-34.978H4.75c1.075 22.849 17.693 41.627 39.518 45.994z"
          fill="#53BAFF"
          mask="url(#globe-b)"
        />
      </g>
    </svg>
  )
}