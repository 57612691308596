import HeroNft from "./hero/HeroNft";
import { Box } from "@mui/material";
import { FaqNft } from "./faq/FaqNft";
import CommunityNft from "./community/CommunityNft";
import MembershipNFT from "./membership/MembershipNFT";
import { TrustedBlock } from "../../shared/trustedBlock/TrustedBlock";
import HowItWorksNft from "./howItWorks/HowItWorksNft";
import { StepsNft } from "./steps/StepsNft";

const Nft = () => {
  return (
    <Box>
      <HeroNft />
      <CommunityNft />
      <MembershipNFT />
      <HowItWorksNft />
      <StepsNft />
      <FaqNft />
      <TrustedBlock />
    </Box>
  );
};

export default Nft;