import { useContext, useState } from "react";
import { BrandInfoContext } from "../../../../../context/BrandInfoContext";
import { getMonthlyData } from "../../shared/data/getMonthlyData";
import { mergeWith } from "lodash";

export const useCommunitySentiment = () => {
  const { generalTwitterInfo } = useContext(BrandInfoContext);
  const { community_sentiment, community_sentiment_by_month } = generalTwitterInfo || {};

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const sentimentData = [community_sentiment?.positive?.slice(0, 3), community_sentiment?.negative?.slice(0, 3)];

  const getGraphData = () => {
    if (!community_sentiment_by_month) return [];

    type TGraphData = {
      xAxis: string,
      line1: number,
      line2: number,
    };

    function customizer(objValue: TGraphData, srcValue: TGraphData) {
      // console.log(objValue);
      return {
        xAxis: objValue?.xAxis,
        line1: Math.max(objValue?.line1, srcValue?.line1),
        line2: Math.max(objValue?.line2, srcValue?.line2),
      };
    }

    const monthlyDataPos = getMonthlyData(community_sentiment_by_month?.positive)?.map((el) => ({
      xAxis: el.month,
      line1: el.eng ?? 0,
      line2: 0,
    }));
    const monthlyDataNeg = getMonthlyData(community_sentiment_by_month?.negative)?.map((el) => ({
      xAxis: el.month,
      line1: 0,
      line2: el?.eng ?? 0,
    }));

    return mergeWith(monthlyDataPos, monthlyDataNeg, customizer);
  };

  return { activeTab, handleTabChange, sentimentData, graphData: getGraphData() };
};
