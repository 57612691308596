//Core
import React from "react";
import { Link } from "react-router-dom";
//Styles
import { HeroContent, HeroImageContainer } from "./Hero.styles";
import { FlexResponsive, SectionContainer } from "../../../../styles/components/Layout";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { MainButton } from "../../../../styles/components/buttons";
import { SectionMainTitle } from "../../../../styles/components/typography";
//Data
import { heroListData } from "./Hero.data";
//Images
import heroImage from './img/hero.webp'
//Utils
import { pathWithUtm } from "../../../../utils/pathWithUtm";

const Hero = () => {
  return (
      <SectionContainer sx={{mb: '5.5rem'}}>
        <FlexResponsive>
          <HeroContent>
            <SectionMainTitle>
              The data your business need <b> to grow on web3 </b>
            </SectionMainTitle>
            <Typography m={{ xs:"0 auto 8rem", md: '0 0 8rem' }} width={'85%'}>
              Enjoy powerful web3 analytics. Seamlessly create and monitor ad
              campaigns. Access the most personalized marketing data available
              in web3.
            </Typography>
            <List sx={{mb: '16rem'}}>
              {heroListData?.map((el) => (
                <ListItem key={el.text}>
                  <ListItemIcon>{el.icon}</ListItemIcon>
                  <ListItemText>{el.text}</ListItemText>
                </ListItem>
              ))}
            </List>
            <Link to={pathWithUtm('/pixel')}>
              <MainButton sx={{transform: 'translateY(-100%)'}}>I WANT TO COLLECT THIS DATA</MainButton>
            </Link>
          </HeroContent>
          <HeroImageContainer>
            <img src={heroImage} alt={'hero image'}/>
          </HeroImageContainer>
        </FlexResponsive>
      </SectionContainer>
  );
};

export default Hero;
