import bitmediaImg from "./img/bitmedia.webp";
import coinzillaImg from "./img/coinzilla.webp";
// import veeFriendsImg from "./img/veeFriends.webp";
import sandboxImg from "./img/sandbox.webp";
import galaImg from "./img/gala.webp";
import decentraland from './img/Decentraland.webp';

export const partnersList = [
  { company: "BitMedia", img: bitmediaImg },
  { company: "Coinzilla", img: coinzillaImg },
  { company: "Decentraland", img: decentraland },
  { company: "Sandbox", img: sandboxImg },
  { company: "Gala Games", img: galaImg },
];
