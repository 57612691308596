import { getSessionToken } from "./gatewayService";
import clientBuilder from "./clientBuilder";

const web3mBetaClient = clientBuilder("https://web3m-beta.com/internal-apis");

export const getCompetitors = async (url, brand_name) => {
  const token = await getSessionToken();

  const getData = async () => {
    return (await web3mBetaClient.get(`/competitors.php`, {
      params: {
        session: token,
        company: brand_name,
        domain: url?.replace("https://", ""),
      },
    }));
  };

  try {
    const res = await getData();
    if (!res || res?.status === 403 || !!res?.code) {
      return null;
    }
    const competitorsData = {};

    res?.data?.similar && Object?.entries(res?.data?.similar).forEach(([key, value]) => {
      competitorsData[key] = {
        company_name: value.company_name ?? "",
        monthly_traffic: value.monthly_traffic ?? value.monthly_visits ?? 0
      };
    });

    return competitorsData;
  } catch (error) {
    console.log(error);
    return null;
  }

};