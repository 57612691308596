import { DEV_ONLY, TOKEN } from "../utils/helpers";
import clientBuilder from "./clientBuilder";

const BASE_URL = `https://gateway-${DEV_ONLY ? "develop-" : ""}dot-winky-apis.ew.r.appspot.com/`;
export const gatewayClient = clientBuilder(BASE_URL);
gatewayClient.defaults.headers.common["Authorization"] = TOKEN;

export const gatewayRequestHandler = async (path, args) => {
  try {
      const response = await gatewayClient.post("/", { path, args });
      // console.log(response);
      return response || null;
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
};
