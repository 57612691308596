//Core
import React, { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
//Styles
import { RequestDemoButton, StyledMenu } from "../Header.styles";
import { MenuItem, Typography, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
//Images
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//Hooks
import { useBreakpoints } from "../../../../utils/hooks/useBreakpoints";
//Utils
import { pathWithUtm } from "../../../../utils/pathWithUtm";

export const ButtonHeader = (page, toggleMobileMenu) => {
  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);
  const [openHover, setOpenHover] = useState(false);
  const { tabletScreen } = useBreakpoints();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const location = useLocation();

  const key = page.link?.startsWith("https")
    ? "link"
    : page.list && !!page.menu
    ? "list"
    : page.name === "Request Demo" || page.name === "User Account" || page.name === "Logout"
    ? "outlined"
    : "button";

  // console.log(key, page.name);

  const link = () => {
    return (
      <a href={page.disabled ? "#" : pathWithUtm(page.link)}
         target={page.name}
         onClick={() => (tabletScreen ? toggleMobileMenu() : null)}
      >
        <Typography variant="body2">{page.name}</Typography>
      </a>
    );
  };

  const button = () => {
    return (
      <Link
        to={pathWithUtm(page.link)}
        disabled={page.disabled}
        className={page.disabled ? "disabled-link" : ""}
        onClick={() => (tabletScreen ? toggleMobileMenu() : null)}
      >
        <Typography variant="body2">{page.name}</Typography>
      </Link>
    );
  };

  const outlinedButton = () => {
    return (
      <Link
        to={pathWithUtm(page.link)}
        disabled={page.disabled}
        className={page.disabled ? "disabled-link" : ""}
        onClick={() => (tabletScreen ? toggleMobileMenu() : null)}
      >
        <RequestDemoButton variant="outlined">
          <Typography variant="body2">{page.name}</Typography>
        </RequestDemoButton>
      </Link>
    );
  };

  const list = () => {
    return (
      <div>
        <Typography
          variant="body2"
          id={page.name + "_button"}
          sx={{
            "& svg": { fontSize: "2rem", marginLeft: ".5rem" },
            alignItems: "center",
            display: "inline-flex",
          }}
          ref={anchorEl}
          aria-controls={open ? `${page.name + "_menu"}` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          // disableElevation
          onMouseOver={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          {page.name}
          <KeyboardArrowDownIcon />
        </Typography>
        <StyledMenu
          id={page.name + "_menu"}
          open={open || openHover}
          onClose={() => {
            setOpen(false);
            setOpenHover(false);
          }}
          anchorEl={anchorEl.current}
          sx={{ pointerEvents: "none" }}
          PaperProps={{
            sx: {
              pointerEvents: "auto",
            },
            onMouseOver: () => setOpenHover(true),
            onMouseLeave: () => setOpenHover(false),
          }}
          keepMounted
        >
          {page.menu?.map((item, i) => (
            <MenuItem
              key={item.name + i}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
                setOpenHover(false);
              }}
              sx={{ backgroundColor: location.pathname === item.link ? "rgba(0,33,58,0.1)" : "none" }}
            >
              <Link to={pathWithUtm(item.link)} disabled={item.disabled} className={item.disabled ? "disabled-link" : ""} width={"100%"}>
                <Typography sx={{ fontSize: "1.6rem !important" }}>{item.name}</Typography>
              </Link>
            </MenuItem>
          ))}
        </StyledMenu>
      </div>
    );
  };

  const mobileList = () => {
    return (
      <Accordion expanded={expanded === page.name} onChange={handleChange(page.name)} sx={{ boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ width: "3rem", height: "3rem" }} />}
          aria-controls={`${page.name}-content`}
          id={`${page.name}-header`}
          sx={{
            padding: "0",
            "& .MuiAccordionSummary-content": {
              margin: "0 !important",
            },
            minHeight: "fit-content",
            "&.MuiAccordionSummary-root.Mui-expanded": {
              minHeight: "fit-content",
            },
          }}
        >
          <Typography variant="body2">{page.name}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "16px 0 0 16px" }}>
          {page.menu?.map((item, i) => (
            <Link
              to={pathWithUtm(item.link)}
              key={item.name + i}
              disabled={item.disabled}
              className={item.disabled ? "disabled-link" : ""}
              onClick={toggleMobileMenu}
              style={{ display: "block", marginTop: "2.5rem" }}
            >
              <Typography variant={"body2"} fontSize={"2.6rem !important"}>
                {item.name}
              </Typography>
            </Link>
          ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  const render = {
    link: link(),
    outlined: outlinedButton(),
    button: button(),
    list: tabletScreen ? mobileList() : list(),
  };

  return render[key] ?? render.button;
};
