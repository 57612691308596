export const FAQdata = {
  aboutUs: [
    {
      q: "What is Web3m?",
      a: "A decentralized multi-channel ad network that allows advertisers to promote their projects across crypto.",
    },
    {
      q: "What is our mission?",
      a: "Our mission is to make it easy and affordable for web3 companies to reach their audiences through targeted advertising.",
    },
    {
      q: "What is our vision?",
      a: `Our vision is to be the network of advertising and personalization for web3, while putting data back into the hands of internet users.
       The old model essentially locked up user data in a handful of major tech conglomerates.
        Our model decentralizes that data by sending all data back to the blockchain, giving power to each user—and every advertiser—in equal measure.
         In other words, we’re not adapting to the changing marketing landscape. It’s more accurate to say that we’re the ones who are changing it.`,
    },
    {
      q: "How can I learn more about Web3m?",
      a: "The best way to learn more about Web3m is by joining our community on Twitter and Discord. We’re also happy to answer any of your questions via email.",
    },
    {
      q: "How to get access to Web3m?",
      a: `The early access is granted currently only for our NFT holders. To claim your membership NFT, go here `,
      l: "https://web3m.io",
    },
  ],
  benefits: [
    {
      q: "What companies can promote using Web3m?",
      a: "Web3m is designed to help companies within the crypto, web3, metaverse, and NFT sectors connect with relevant customers and audiences.",
    },
    {
      q: "Why are we the best at what we do?",
      a: `Not to toot our own horn, but Web3m is unique in the diversity of our available advertising channels. We partner with some of the largest advertising channels available in crypto and web3.
       
       Plus, we offer an easy-to-use dashboard for managing every campaign. 
       
       And last, we own the largest data network on Web3 users, allowing our clients to target their audience in the most accurate and comprehensive way possible in web3 so far.`,
    },
    {
      q: "How do I know if Web3m is the right solution for my needs?",
      a:
        "Web3m is built for marketers and entrepreneurs in web3 and crypto. \n" +
        "If you own a company or work for a company in the ecosystem, you will find our solutions valuable.\n" +
        "If you’d like to learn more about the platform or community, reach out to us via Discord, Telegram, or email. \n",
    },
  ],
  monetization: [
    {
      q: "What is Web3m data network?",
      a: `There’s no way around it: Advertising performs best for everyone (the advertiser, the consumer, and the network) with data.
            Using our data network, brands & advertisers can now access an ecosystem of web3 data.
            The data is gathered using pixels across web3 websites, applications, and wallets, empowering the network while being stored on the blockchain (anonymously), in a decentralized way.
`,
    },
    {
      q: "What is special about the Web3m data network?",
      a: `Web3m’s data-forward approach takes it two steps further.
First, the data is much more detailed while remaining fully anonymous, giving advertisers better insights for every campaign, and the ability for brands to optimize the experience for their users.
Second, we pay the site visitor (consumer) and the website for letting us use their data.
`,
    },
    {
      q: "How can I monetize my data as a company?\n",
      a: `Each website can start monetizing its data in two minutes of work,
            By implementing the pixel code in their website. The data collected benefits you in two ways.
            First, you get paid for sharing data that later on will be demanded by advertisers.
            Second, the data you collect about your users will help you to personalize your offer to them.
            Of course you can also use the data to retarget them in advertising.
`,
    },
    {
      q: "What type of data do you have on users?",
      a: `Combining On-chain data with our fingerprinting technology we have access to demographic, psychographic, geographic, user behavior and interests, and wallet-specific data like previous transactions or holdings, and more.
            Also, by sending all their data to the blockchain, we keep them anonymous.
`,
    },
  ],
};
