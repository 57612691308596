export function WalletIcon(props) {
  return (
    <svg
      width="2.4rem"
      height="2.1rem"
      viewBox="0 0 24 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M466 624.372a3 3 0 013-3h16a3 3 0 013 3v1.17c1.165.413 2 1.524 2 2.83v10a3 3 0 01-3 3h-18a3 3 0 01-3-3v-14zm2 2.829v11.17a1 1 0 001 1h18a1 1 0 001-1v-2h-3a3 3 0 110-6h3v-2a1 1 0 00-1-1h-18c-.351 0-.687-.06-1-.17zm20 5.17h-3a1 1 0 000 2h3v-2zm-2-7h-17a1 1 0 010-2h16a1 1 0 011 1v1z"
        transform="translate(-466 -621)"
        fill="#53BAFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}

export function WorldIcon(props) {
  return (
    <svg
      width="2.4rem"
      height="2.5rem"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M468.049 681.135h4.966c.087-2.733.527-5.223 1.217-7.12.176-.486.374-.945.592-1.365a10.008 10.008 0 00-6.775 8.485zm9.951-11c-6.628 0-12 5.373-12 12s5.372 12 12 12c6.627 0 12-5.373 12-12s-5.373-12-12-12zm0 2c-.152 0-.425.081-.799.516-.371.432-.751 1.116-1.09 2.047-.597 1.644-1.008 3.893-1.094 6.437h5.966c-.086-2.544-.497-4.793-1.095-6.437-.339-.93-.718-1.615-1.089-2.047-.375-.435-.648-.516-.799-.516zm4.984 9c-.087-2.733-.526-5.223-1.216-7.12a11.56 11.56 0 00-.593-1.365 10.006 10.006 0 016.775 8.485h-4.966zm-2.001 2h-5.966c.086 2.544.497 4.793 1.094 6.437.339.931.719 1.615 1.09 2.047.374.435.647.516.799.516.151 0 .424-.08.799-.516.371-.432.75-1.116 1.089-2.047.598-1.644 1.009-3.893 1.095-6.437zm.192 8.485c.218-.42.416-.879.593-1.365.69-1.897 1.129-4.387 1.216-7.12h4.966a10.006 10.006 0 01-6.775 8.485zm-6.351 0c-.218-.42-.416-.879-.592-1.365-.69-1.897-1.13-4.387-1.217-7.12h-4.966a10.008 10.008 0 006.775 8.485z"
        transform="translate(-466 -670)"
        fill="#53BAFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}

export function GeoLocationIcon(props) {
  return (
    <svg
      width="2rem"
      height="2.5rem"
      viewBox="0 0 20 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M472 727.5a6 6 0 0112 0c0 2.844-1.813 5.549-3.649 7.531a23.189 23.189 0 01-2.053 1.958c-.108.09-.207.173-.298.246-.091-.073-.19-.156-.298-.246a23.189 23.189 0 01-2.053-1.958c-1.836-1.982-3.649-4.687-3.649-7.531zm14 0c0 2.92-1.464 5.554-3.034 7.55 1.091.262 2.062.614 2.85 1.045 1.121.612 2.184 1.569 2.184 2.905 0 .857-.451 1.565-1.03 2.099-.579.532-1.361.964-2.243 1.308-1.771.689-4.15 1.093-6.727 1.093s-4.956-.404-6.727-1.093c-.882-.344-1.664-.776-2.243-1.308-.579-.534-1.03-1.242-1.03-2.099 0-1.336 1.063-2.293 2.184-2.905.788-.431 1.759-.783 2.85-1.045-1.57-1.996-3.034-4.63-3.034-7.55a8 8 0 0116 0zm-10 0a2 2 0 114.001.001A2 2 0 01476 727.5zm2-4a4 4 0 100 8 4 4 0 000-8zm-.583 15.813l-.001-.001-.005-.003-.016-.012-.057-.042-.205-.155a25.308 25.308 0 01-2.583-2.32c-1.423.243-2.593.625-3.407 1.07-.974.532-1.143.974-1.143 1.15 0 .109.053.321.384.626.332.306.868.627 1.615.917 1.486.578 3.607.957 6.001.957s4.515-.379 6.001-.957c.747-.29 1.283-.611 1.615-.917.331-.305.384-.517.384-.626 0-.176-.169-.618-1.143-1.15-.814-.445-1.984-.827-3.407-1.07a25.308 25.308 0 01-2.583 2.32l-.205.155-.057.042-.016.012-.005.003-.001.001h-.001a1 1 0 01-1.164 0h-.001z"
        transform="translate(-468 -719)"
        fill="#53BAFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}

export function HeroBg(props) {
  return (
    <svg viewBox="0 0 2371 879" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient
          x1="87.0009279%"
          y1="56.5416278%"
          x2="-9.87628712%"
          y2="44.9049231%"
          id="hero-bg-a"
        >
          <stop stopColor="#0F143F" offset="0%" />
          <stop stopColor="#0E75FC" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M-16-196h2387v375c0 386.6-313.4 700-700 700H-16V-196z"
        fill="url(#hero-bg-a)"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}