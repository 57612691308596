import { styled, Typography } from "@mui/material";
import { mobileScreen, smallMobileScreen } from "../../utils/themeBreakpoints";

export const MainHeading = styled(Typography)`
  b {
    color: #53baff;
  }

  ${mobileScreen} {
    text-align: center;
  }
`;

export const MainTitle = ({ children, ...otherProps }) => {
  return (
    <MainHeading variant={"h1"} {...otherProps}>
      {children}
    </MainHeading>
  );
};

export const SectionMainTitle = ({ children, ...otherProps }) => {
  const StyledText = styled(MainHeading)`
    color: #0f3987;

    ${mobileScreen} {
      font-size: 6rem;

      br {
        display: none;
      }
    }

    ${smallMobileScreen} {
      font-size: 5rem;

      br {
        display: none;
      }
    }
  `;

  return (
    <StyledText variant={"h1"} {...otherProps}>
      {children}
    </StyledText>
  );
};

export const SecondaryTitle = ({ children, ...otherProps }) => {
  const StyledText = styled(Typography)`
    ${mobileScreen} {
      text-align: center;
      font-size: 4.4rem;
    }

    ${smallMobileScreen} {
      font-size: 4rem;
    }
  `;

  return (
    <StyledText variant={"h2"} color={"#010A10"} mb={"3rem"} {...otherProps}>
      {children}
    </StyledText>
  );
};

export const SectionParagraph = styled(Typography)`
  color: #3d5f78;

  ${mobileScreen} {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`;
