import { SectionContainer } from "../../../../styles/components/Layout";
import { Box, Typography } from "@mui/material";
import { PromoteBackground, PromoteList, PromoteListItem } from "./Promote.styles";
import { promoteList } from "./Promote.data";
import { PromoteMobile } from "./img/Promote.icons";
import { SecondaryTitle } from "../../../../styles/components/typography";

const Promote = () => {
  return (
    <Box position={"relative"}>
      <PromoteBackground />
      <SectionContainer p={"14rem 0"}>
        <SecondaryTitle mb={"7rem"}>
          {`No matter your project, 
          promote it with Web3m`}
        </SecondaryTitle>
        <PromoteList>
          {promoteList.map((el) => (
            <PromoteListItem key={el.title}>
              <Typography>{el.title}</Typography>
              {el.icon}
            </PromoteListItem>
          ))}
        </PromoteList>
        <PromoteMobile/>
      </SectionContainer>
    </Box>
  );
};

export default Promote;
