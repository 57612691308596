import { Box, List, styled } from "@mui/material";
import { FlexBox } from "../../../../../styles/components/Layout";
import { mobileScreen, smallMobileScreen } from "../../../../../utils/themeBreakpoints";

export const TargetingCard = styled(Box)`
  display: flex;
  flex-direction: column;
  border-radius: 2.3rem;
  box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  height: 100%;
  
  padding: 10rem 10rem 7.2rem 8rem;
  
  ${mobileScreen}{
    max-width: 70rem;
    margin: 0 auto;
  }

  ${smallMobileScreen}{
    padding: 5rem;
  }
`

export const TargetingCardHead = styled(FlexBox)`
  margin-bottom:5rem;

  ${smallMobileScreen}{
    margin: 3rem 0 7rem;
  }

  p{
    margin-left: 3rem;
    font-size: 3.9rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.39px;
    color: #3d5f78;

    ${smallMobileScreen}{
      font-size: 3rem;
    }
  }
  
  svg{
    flex-shrink: 0;
    
    ${smallMobileScreen}{
      width: 25%;
    }
  }
`

export const TargetingCardList = styled(List)`
margin-bottom: 3rem;
  .MuiListItem-root{
    padding-left: 0;

    
    .MuiListItemIcon-root{
      min-width: auto;
      margin-right: 1.6rem;
      margin-top: 1.1rem;
      align-self: flex-start;
    }

    .MuiListItemText-primary{
      font-size: 2.9rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: -0.31px;
      color: #3d5f78;
    }
  }
`

export const ExtraFeature = styled(FlexBox)`
margin-top: auto;
  color: #3d5f78;
  
  > div{
    margin-right: 1.6rem;
    flex-shrink: 0;
    align-self: flex-start;
    margin-top: .7rem;
  }
  

`

export const Dot = styled(Box)`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  background-color: #3d5f78;
  
  &.white{
    background-color: #fff;
    border: 1px solid #3d5f78;
  }

`