import {Button, Stack, styled, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import theme from "../../../../styles/theme";
import { FlexSpaceBetween, ImgBox } from "../../../../styles/components/Layout";

export const LoginTitle = styled(Typography)`
  font-family: 'F37 Judge', sans-serif;
  font-size: 3.12rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  color: #385261;
  margin-bottom: 1.6rem;
  ${theme.breakpoints.down("br768")} {
    text-align: center;
  }
`

export const LoginDescription = styled(Typography)`
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.16px;
  color: #385261;
  max-width: 30rem;
  margin-bottom: 4.3rem;
  ${theme.breakpoints.down("br768")} {
    text-align: center;
    align-self: center;
  }
`

export const LoginFormStack = styled(Stack)`
  flex-grow: 1;
  padding: 5rem 9rem;
  width: 70%;
    ${theme.breakpoints.down("br768")} {
      width: 100%;
      padding: 9rem;
    }
  ${theme.breakpoints.down("sm")} {
    padding: 5rem;
  }
  ${theme.breakpoints.down("br480")} {
    padding: 3rem;
  }
`

export const LoginButton = styled(LoadingButton)`
  text-transform: unset;
  border-radius: 8px;
  background-color: #195afe;
  box-shadow: none;
  padding: 1rem 0;
  width: 100%;
  margin-top: 4.55rem;
  font-size: 2rem;
  letter-spacing: 0.22px;
  &:hover {
    background-color: ${theme.palette.primary.main};
    box-shadow: none;
  }
`

export const OrBox = styled(FlexSpaceBetween)`
  margin: 2.9rem 0;
  align-items: center;
  & hr {
    width: 44%;
  }
  & p {
    font-size: 1.2rem;
    letter-spacing: 0.13px;
    color: #385261;
    margin: 0 1rem;
  }
`

export const LoginWithGoogleButton = styled(Button)`
  font-size: 1.4rem;
  text-transform: unset;
  border-radius: 8px;
  color: #000000;
  padding: 0.7rem 0;
  & svg {
    width: 3.3rem;
  }
  &:hover {
    background-color: #FFFFFF;
  }
`

export const LoginImgBox = styled(ImgBox)`
  width: 30%;
  max-height: 77rem;
  margin: -2px;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    object-fit: cover;
  }
  ${theme.breakpoints.down("md")} {
    max-height: 71rem;
    img {
      height: 650px;
    }
  }
  //img {
  //  width: 26.4rem;
  //  height: 77rem;
  //}
`

export const LoginImgStack = styled(Stack)`
  position: absolute;
  left: 2rem;
  bottom: 2rem;
  align-items: flex-start;
  & p {
    font-size: 1.4rem;
    line-height: 1.31;
    letter-spacing: 0.16px;
  }
    & p:first-of-type {
      color: #3a3f43;
    }
  & p:last-of-type {
    font-weight: 600;
    color: #4f6bb1;
    cursor: pointer;
    text-decoration: underline;
  }
    ${theme.breakpoints.down("br768")} {
      position: relative;
      align-items: center;
      margin: 1rem auto 3rem;
      left: 0;
      bottom: 0;
    }
`

export const LoginShowPasswordButton = styled(Button)`
  text-transform: unset;
  margin-left: auto;
  font-size: 1.08rem;
  letter-spacing: 0.12px;
  align-self: flex-end;
  color: #385261;
    & svg {
        width: 1.6rem;
        height: 1.6rem;
    }
`

