import * as yup from "yup";
import {useCallback, useState} from "react";
import {DATABASE_CONNECTION} from "../useLogin";

export const validationSchema = yup.object({
    username: yup.string().email("Email not valid").required("Email is required"),
});

export const useForgotPassword = (webAuth, changePage) => {
    const formData = {username: ''};
    const [email, setEmail] = useState('');
    const [errorResponse, setErrorResponse] = useState(null);

    const changePassword = useCallback((username) => {
        return new Promise((resolve, reject) => {
            webAuth.changePassword({
                connection: DATABASE_CONNECTION,
                email: username,
            }, (error, result) => {
                if (error) {
                    reject(error);
                    setErrorResponse(error.error_description || error.description || 'something went wrong')
                    return;
                }
                resolve(result);
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webAuth]);

    const sendForm = async (values) => {
        try {
            setEmail(values.username)
            await changePassword(values.username);
            changePage('requestSent');
        } catch (e) {
            console.log(e);
        }
    }

    return {
        email,
        formData,
        errorResponse,
        sendForm
    }
}