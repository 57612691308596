import { Box, Stack, styled } from "@mui/material";
import { FlexBox } from "../../../../styles/components/Layout";
import { mobileScreen, smallMobileScreen } from "../../../../utils/themeBreakpoints";

export const PartnersBackground = styled(Box)`
  background-color: #010a10;
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const PartnerCardList = styled(FlexBox)`
  justify-content: space-between;

  ${mobileScreen} {
    flex-wrap: wrap;
    justify-content: space-evenly;
    column-gap: 15%;
    row-gap: 5rem;
    max-width: 70rem;
    margin: 0 auto;
  }

  ${smallMobileScreen} {
    column-gap: 10%;
  }
`;

export const PartnerCard = styled(Stack)`
  //width: 12rem;
  align-items: center;
  ${mobileScreen} {
    flex-basis: 30%;
    max-width: fit-content;
  }

  > div:first-of-type {
    height: 12rem;
    max-width: 12rem;
    margin-bottom: 4rem;

    ${smallMobileScreen} {
      height: auto;
      margin-bottom: 2rem;
    }
  }

  p {
    margin-top: auto;
    font-size: 2.4rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.84px;
    color: #fff;
    white-space: nowrap;
  }
`;
