import { styled } from "@mui/material/styles";
import { Grid, Tooltip } from "@mui/material";
import { WebSvg } from "../../../../../../assets/svg/buttonIcons";
import { FlexBox } from "../../../../../../styles/components/Layout";
import theme from "../../../../../../styles/theme";

export const GeneralInfoListItem = ({ children }) => {
  const StyledGrid = styled(Grid)`
    display: flex;
    align-items: center;
    padding: 1rem;
    > p:first-of-type {
      font-size: 1.8rem;
      font-weight: 500;
      letter-spacing: 0.2px;
      color: #4a5767;
      margin-right: 0.7rem;
      flex-shrink: 0;

      &.multi-list {
        align-self: flex-start;
        margin-top: 0.4rem;
      }
    }
    > p:last-of-type {
      font-size: 2.2rem;
      font-weight: bold;
      letter-spacing: 0.24px;
      color: #383b40;
      text-transform: uppercase;

      svg {
        width: 3.3rem;
        height: 3.3rem;
        vertical-align: text-bottom;
        path {
          opacity: 1;
        }
      }

      &.link {
        color: #1a418e;
        transition: 0.3s;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          opacity: 0.71;
        }
      }

      span {
        svg path {
          fill: #1a418e;
        }

        &.link {
          color: #1a418e;
          transition: 0.3s;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            opacity: 0.71;
          }
        }

        &.list-item {
          border: 1px solid #1a418e;
          border-radius: 1.2rem;
          padding: 0.5rem 1rem;
          font-size: 1.4rem;
          font-weight: 600;
          text-transform: none;
          line-height: normal;
          white-space: nowrap;
        }
      }
    }

    ${theme.breakpoints.down("md")} {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 3rem 2rem;
      margin-bottom: 2rem;

      &:not(:last-of-type) {
        border-bottom: dashed 1px rgba(154, 158, 172, 0.41);
      }

      p {
        &:first-of-type {
          //color: #677194;
          padding-top: 1.5rem;
        }
        &:last-of-type {
          font-size: 2.6rem;
          //margin-bottom: .5rem;
          //color: #677194;

          span.link,
          &.link {
            //color: #b2bbda;

            svg {
              path {
                //fill: #b2bbda;
              }
            }

            &.list-item {
              //border-color: #b2bbda;
            }
          }
        }
      }
    }
  `;

  return (
    <StyledGrid item xs={12} md={6}>
      {children}
    </StyledGrid>
  );
};

export const LinkTooltip = ({ text = "", children, onClick, ...otherProps }) => {
  const TooltipWrapper = styled(FlexBox)`
    width: fit-content;
    max-width: 50rem;
    overflow: hidden;
    align-items: center;
    color: #4a5767;
    border-radius: 1.6rem;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.22);
    background-color: #fff;
    font-size: 1.4rem;
    padding: 0.3rem 1.8rem;
    cursor: pointer;
    svg {
      height: 2rem;
      margin-right: 0.8rem;
      flex-shrink: 0;
    }
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `;

  return (
    <Tooltip
      placement="top-start"
      disableHoverListener={!text}
      title={
        <TooltipWrapper onClick={onClick}>
          <WebSvg />
          <span>{text}</span>
        </TooltipWrapper>
      }
      componentsProps={{
        tooltip: { sx: { background: "#fff" } },
      }}
    >
      {children}
    </Tooltip>
  );
};
