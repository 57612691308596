import { BrandAnalysisSectionTooltipContent } from "./sectionTooltip.styles";

export const SectionTooltip = ({ section }) => {
  return (
    <BrandAnalysisSectionTooltipContent>{sectionsTooltipInfo[section] ?? ''}</BrandAnalysisSectionTooltipContent>
  )
}

const sectionsTooltipInfo = {
  brand_description: "An overview of your brand that paints a broad picture as seen by the online world",
  general_info: "Dive into your brand's public profile with details like Brand Type, Social Media Channels, Engagement Rates, Founders' Info, and insights from Web Mentions and Publications",
  google_searches: "Discover trending keywords associated with your brand and understand their search volumes to optimize your SEO strategies",
  competitors: "Identify your competition within the same audience space, ranked by niche dominance, to strategize your growth and differentiation",
  organic_growth: "Track your brand's organic performance across various social channels to understand your natural reach and engagement",
  potential_users: "Understand your social media follower base's potential by contrasting your overall followers with those showing a higher degree of engagement",
  community_sentiment: "Visualize your brand's public sentiment with an easy-to-understand graph, capturing the thoughts and feelings of your community towards your brand",
}