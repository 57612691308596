import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  popup: "",
  popupData: {},
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppField: (state, { payload }) => {
      return { ...state, ...payload };
    },
    setActivePopup: (state, { payload }) => {
      state.popup = payload;
    },
    setPopupData: (state, { payload }) => {
      state.popupData = payload;
    },
  },
});

export const {
  setAppField,
  setActivePopup,
  setPopupData,
} = appSlice.actions;

export default appSlice.reducer;
