import {useCallback, useState} from "react";
import * as yup from "yup";
import {DATABASE_CONNECTION} from "../useLogin";

export const validationSignInSchema = yup.object({
    username: yup.string().email("Email not valid").required("Email is required"),
    password: yup.string().required("Password is required")
});
export const validationSignUpSchema = yup.object({
    username: yup.string().email("Email not valid").required("Email is required"),
    password: yup.string()
        .required("Password is required")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Password have to be 8 characters and include at least 1 number, 1 uppercase letter and 1 special symbol"
        )
});
export const useSignInSignUp = (webAuth, activePage) => {
    const formData = {
        username: "",
        password: "",
    }
    const [errorResponse, setErrorResponse] = useState(null);
    const [passwordHidden, setPasswordHidden] = useState(true);
    const urlParams = new URLSearchParams(window?.location?.search);
    const redirectUri = `${window?.location?.origin}/login`;
    const stateParam = urlParams.get('state') || '';

    const loginWithUsernamePassword = useCallback((data) => {
        return new Promise((resolve, reject) => {
            webAuth.login({
                connection: DATABASE_CONNECTION,
                username: data.username,
                password: data.password,
                realm: DATABASE_CONNECTION,
                state: stateParam,
            }, (error, result) => {
                if (error) {
                    reject(error);
                    setErrorResponse(error.error_description || error.description || 'something went wrong')
                    return;
                }
                resolve(result);
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webAuth]);

    const loginWithGoogle = useCallback(() => {
        webAuth.authorize({connection: 'google-oauth2', redirectUri});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webAuth]);


    const signUp = useCallback((data) => {
        return new Promise((resolve, reject) => {
            webAuth.signup({
                    connection: DATABASE_CONNECTION,
                    password: data.password,
                    email: data.username,
                },
                (error, result) => {
                    if (error) {
                        reject(error);
                        setErrorResponse(error.description || error.error_description || 'something went wrong')
                        return;
                    }
                    resolve(result);
                    webAuth.login({
                        connection: DATABASE_CONNECTION,
                        username: data.username,
                        password: data.password,
                        realm: DATABASE_CONNECTION,
                        state: stateParam,
                        prompt: 'consent',
                    }, (error, result) => {
                        if (error) {
                            reject(error);
                            setErrorResponse(error.error_description || error.description || 'something went wrong')
                            return;
                        }
                        resolve(result);
                    })
                })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webAuth]);

    const actions = {
        signIn: (data) => loginWithUsernamePassword(data),
        signUp: (data) => signUp(data),
    }

    const sendForm = async (values) => {
        try {
            await actions[activePage](values);
        } catch (err) {
            console.log(err)
        }
    }

    const handlePasswordHidden = () => {
        setPasswordHidden(!passwordHidden);
    }

    return {
        formData,
        sendForm,
        loginWithGoogle,
        errorResponse,
        passwordHidden,
        handlePasswordHidden
    }
}