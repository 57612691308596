import SignInLogoMobile from "./img/SignInLogoMobile.png";
import SignInLogo from "./img/signInLogo.webp";
import SignUpLogoMobile from "./img/SignUpLogoMobile.png";
import SignUpLogo from "./img/signUpLogo.webp";
import ForgotPasswordLogoMobile from "./img/ForgotPasswordLogoMobile.png";
import ForgotPasswordLogo from "./img/forgotPasswordLogo.webp";
import RequestSentLogoMobile from "./img/RequestSentLogoMobile.png";
import RequestSentLogo from "./img/requestSentLogo.webp";

export const titles= {
    signIn: "Welcome back!",
    signUp: "Sign up",
    forgotPassword: "Forgot password?",
    requestSent: "Request sent",
}

export const changePageButtonText = {
    signIn: "Sign up",
    signUp: "Login",
    forgotPassword: "Login",
    requestSent: "Login",
}

export const changePageText = {
    signIn: "Don't have an account?",
    signUp: "Already have an account?",
    forgotPassword: "Remembered your password?",
    requestSent: "Return to sign in",
}

export const loginDescriptions = {
    signIn: () => "We are pleased to inform you that while you were away, we have been working to improve our product and service for you.",
    signUp: () => "Welcome to Web3m sign up page. Complete this short process to access all of our products and services.",
    forgotPassword: () => "To reset and update your password, please enter your email address.",
    requestSent: (props) => `Please check your email ( ${props} ) to complete password recovery proccess`,
}

export const loginImage = {
    signIn: SignInLogo,
    signUp: SignUpLogo,
    forgotPassword: ForgotPasswordLogo,
    requestSent: RequestSentLogo,
}

export const loginImageMobile = {
    signIn: SignInLogoMobile,
    signUp: SignUpLogoMobile,
    forgotPassword: ForgotPasswordLogoMobile,
    requestSent: RequestSentLogoMobile,
}