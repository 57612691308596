import { Logo } from "../../../assets/svg/logo";
import {
  ConnectionContainer,
  ConnectionContent, ConnectionDescription,
  ConnectionDotsFlashing, ConnectionImage,
  ConnectionTitle,
  LogoBox
} from "./ConnectionPage.styles";
import ConnectionScheme from "./img/ConnectionScheme.webp";
import { useNavigate } from "react-router-dom";
import { useConnectionPage } from "./useConnectionPage";
import { FlexBox } from "../../../styles/components/Layout";

const ConnectionPage = () => {
  const navigate = useNavigate();
  useConnectionPage();

  return (
    <ConnectionContainer>
      <LogoBox>
        <Logo />
      </LogoBox>
      <ConnectionContent>
        <FlexBox sx={{alignItems: 'flex-end'}}>
          <ConnectionTitle>{"Connecting"}</ConnectionTitle>
          <ConnectionDotsFlashing className={"dot-flashing"}></ConnectionDotsFlashing>
        </FlexBox>
        <ConnectionDescription>
          {"We are setting up your connection. Thank you for your patience, it won't take longer than a few seconds."}
        </ConnectionDescription>
        <ConnectionImage>
          <img src={ConnectionScheme} alt={"connection"} />
        </ConnectionImage>
        <ConnectionDescription>
          {'Problems with connection?'}
        </ConnectionDescription>
        <ConnectionDescription className={'link'} onClick={() => navigate('/')}>
          {'Click here'}
        </ConnectionDescription>
      </ConnectionContent>
    </ConnectionContainer>
  );
};

export default ConnectionPage;