import { Box, Stack, styled } from "@mui/material";
import { mobileScreen, smallMobileScreen } from "../../../../utils/themeBreakpoints";
import { FlexResponsive } from "../../../../styles/components/Layout";

export const StepContainer = styled(Stack)`
  height: 100%;
  justify-content: flex-end;
  ${mobileScreen} {
    width: 80%;
    margin: 0 auto;
  }
  > div:first-of-type {
    //height: 27rem;
    //margin-bottom: 6rem;
    width: 85%;
    margin: 0 auto;

    ${mobileScreen} {
      height: auto;
      margin-bottom: 6rem;
    }
  }
  > div:last-of-type {
    padding-top: 3rem;
    //margin-top: auto;
  }

  p {
    font-size: 2.4rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.84px;
    color: #5680ad;
    ${mobileScreen} {
      text-align: center;
    }

    ${smallMobileScreen} {
      width: 80%;
      margin-top: -2.5rem;
    }
  }
`;

export const StepNumber = styled(Box)`
  border: solid 1px #5680ad;
  border-radius: 50%;
  width: 11rem;
  height: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 1.6rem;

  span {
    font-family: "F37 Judge", sans-serif;
    font-size: 6.2rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.17px;
    color: #5680ad;
  }

  ${mobileScreen} {
    background-color: #fff;
    margin-top: -13rem;
    margin-bottom: 5rem;
  }
`;

export const StepsButtons = styled(FlexResponsive)`
  row-gap: 3rem;
  column-gap: 3rem;

  ${mobileScreen} {
    > a {
      width: 100%;
    }
  }
`;
