//Core
import { useContext } from "react";
//Styles
import {
  ArrowExpanded,
  SectionBlock,
  SectionBlockTitle,
  SectionDescription,
  SectionTitleBackground
} from "../../shared/styles/layout";
import { Grid, Tabs, Typography, Box, Stack } from "@mui/material";
import { SentimentComments, SentimentCustomTab } from "./CommunitySentiment.styles";
import SectionBlockWithLoader from "../../shared/components/loaders/SectionBlockWithLoader";
//Images
import { SadSvg, SmileSvg } from "../../../../../assets/svg/buttonIcons";
import RemoveIcon from "@mui/icons-material/RemoveOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";
//Components
import LineGraph from "../../shared/components/lineGraph/LineGraph";
//Hooks
import { useCommunitySentiment } from "./useCommunitySentiment";
//Context
import { BrandInfoContext } from "../../../../../context/BrandInfoContext";
//Utils
import { fetchItems } from "../../../../../utils/fetchItems";
import { sectionOpenItems } from "../../../../shared/brandAnalysis/help/sectionLabel";

const legendData = { line1: "Positive Feedback", line2: "Negative Feedback" };

const CommunitySentiment = () => {
  const {
    fetchList,
    generalTwitterInfo,
    sectionOpen,
    handleSectionOpen,
    brandSectionsList
  } = useContext(BrandInfoContext);
  const isFetching = fetchList.includes(fetchItems.brand_profile) || fetchList.includes(fetchItems.general_twitter_info);

  const { activeTab, handleTabChange, sentimentData, graphData } = useCommunitySentiment();

  const description =
    "The heart of the company is its community; every company must understand the community's mindset, and what the community thinks of the company, whether the mindset is positive or negative - and thus identify its own weak points.";

  if ((!isFetching && !generalTwitterInfo) || !brandSectionsList.includes(sectionOpenItems.community_sentiment)) return null;


  return (
    <SectionBlock className={isFetching ? "loading" : ""}>
      <SectionTitleBackground />
      <SectionBlockTitle onClick={() => !isFetching && handleSectionOpen(sectionOpenItems.community_sentiment)}>
        {'Community Sentiment'}
      </SectionBlockTitle>
      <SectionDescription mb="6rem"
                          // onClick={() => !isFetching && handleSectionOpen(sectionOpenItems.community_sentiment)}
      >
        {description}
      </SectionDescription>
      <SectionBlockWithLoader loading={isFetching}>
        {(sectionOpen === sectionOpenItems.community_sentiment) &&
          <Grid container spacing={{ xs: "5rem", lg: "10rem" }}>
          <Grid item xs={12} br1024={6}>

            {/*<RabbitTip text={description} />*/}
            <LineGraph data={graphData} legend={legendData} />
          </Grid>
          <Grid item xs={12} br1024={6}>
            <Stack height="100%">
              <Tabs value={activeTab} onChange={handleTabChange}
                    TabIndicatorProps={{ sx: { display: { xs: "none", br480: "block" } } }}>
                <SentimentCustomTab label="Positive Feedback" icon={<SmileSvg />} />
                <SentimentCustomTab label="Negative Feedback" icon={<SadSvg />} />
              </Tabs>
              <SentimentComments spacing={"3rem"}>
                {sentimentData[activeTab]?.slice(0, 3).map((el, i) => (
                  <Box key={el.text + i}>
                    <Typography variant="h4">{el.name}</Typography>
                    <Typography variant="body1">{el.text}</Typography>
                  </Box>
                ))}
              </SentimentComments>
            </Stack>
          </Grid>
        </Grid>
        }
        <ArrowExpanded onClick={() => !isFetching && handleSectionOpen(sectionOpenItems.community_sentiment)}>
          {(sectionOpen === sectionOpenItems.community_sentiment)
            ? <RemoveIcon />
            : <ExpandMoreIcon />
          }
        </ArrowExpanded>
      </SectionBlockWithLoader>
    </SectionBlock>
  );
};

export default CommunitySentiment;
