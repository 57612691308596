import { Checkbox, FormControlLabel, styled } from "@mui/material";
import theme from "../../../../styles/theme";

export const CheckboxCustom = ({round=true, ...props}) => {
  const IconCheckbox = styled('span')`
      width: 2.5rem;
      height: 2.5rem;
      box-shadow: inset 0 1px 6px 0 rgba(133,133,133,0.25);
      border: solid 1px #FFFFFF;
      border-radius: ${round ? '50%' : '.5rem'};
      ${theme.breakpoints.down('br480')} {
        box-shadow: unset;
      }
      
    `
  const CheckedIcon = styled(IconCheckbox)({
    // backgroundColor: theme.palette.primary.dark,
    backgroundColor: '#FFFFFF',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&.Mui-disabled': {
      '& span': {
        opacity: '0.5'
      }
    },
    '&:before': {
      display: 'block',
      width: '2.4rem',
      height: '2.4rem',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23FBB32C'/%3E%3C/svg%3E\")",
      content: '""',
    },
  });

  return <Checkbox
    {...props}
    sx={{pointerEvents: 'auto'}}
    icon={<IconCheckbox className={props.className}/>}
    checkedIcon={<CheckedIcon className={props.className}/>}
  />
}

export const CheckboxWithLabel = ({label, checked, name, onChange, disabled, labelPlacement, className}) => {
  const FormControlLabelStyled = styled(FormControlLabel)`
      display: flex;
      //padding: 1rem 2rem 1rem 1rem;
      pointer-events: none;
    
      &.border {
        padding: 1rem 2rem 1rem 1rem;
        //border: 1px ${checked ? 'solid' : 'dashed'} #FFFFFF;
        border-radius: 1rem;
        // box-shadow: ${checked ? '0 10px 22px 0 rgba(3, 19, 53, 0.59)' : 'none'};
      }
    
      &.reason {
        border: 1px ${checked ? 'solid' : 'dashed'} #FFFFFF;
        box-shadow: ${checked ? '0 10px 22px 0 rgba(3, 19, 53, 0.59)' : 'none'};
        background-image: ${checked 
                ? 'linear-gradient(to top, #134f9e 193%, #8da8dd 161%)' 
                : 'linear-gradient(143deg, #134f9e 10%, #082765 57%)'};
      }
    
      & .MuiFormControlLabel-label {
        font-size: 2.16rem;
        color: #FFFFFF;
        letter-spacing: 0.24px;
        &.Mui-disabled {
          color: #FFFFFF;
        }
      }
    
      &.others {
        & .MuiFormControlLabel-label {
          opacity: 0.5;
        }
      }
      width: fit-content;
      //margin-right: 5rem;
      & p {
        align-self: center;
      }
        
      
    `
  return <FormControlLabelStyled
    className={className}
    control={
      <CheckboxCustom
        checked={checked}
        name={name}
        onChange={onChange}
        disabled={disabled}
        className={className}
      />
    }
    label={label}
    labelPlacement={labelPlacement? labelPlacement : 'end'}/>
}