import { styled } from "@mui/system";
import { Autocomplete, Box, FormControl, Typography } from "@mui/material";
import theme from "../../../../styles/theme";
import { FlexBox } from "../../../../styles/components/Layout";

export const SignUpFormContainer = styled(Box)`
  width: 77rem;
  padding: 12.5rem 9rem 8.5rem;
  border-radius: 3rem;
  //border: 1px solid #000;
  box-shadow: -1px 51px 45px -6px rgba(0, 0, 0, 0.17);
  border: solid 1px #c6d6e3;
  background-color: #ebf1f5;
  margin-top: -30%;
  margin-bottom: 15rem;

  ${theme.breakpoints.down("md")} {
    margin-top: -10rem;
    width: 100%;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 15% 10% 10% 10%;
    box-shadow: -1px 21px 25px -6px rgba(0, 0, 0, 0.1);
  }

  label {
    font-size: 2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: 4.2px;
    color: #42475b;
    margin-bottom: 0.7rem;

    ${theme.breakpoints.down("sm")} {
      margin-bottom: 0;
      width: fit-content;
    }
  }

  .MuiInputBase-root {
    width: 100%;
    border-radius: 1.3rem;
    box-shadow: 0 6px 13px 0 #d6dee3;
    border: solid 1.3px #97a0c3;
    background-color: #fafcfe;
    margin-bottom: 5.5rem;

    ${theme.breakpoints.down("sm")} {
      margin-bottom: 4.5rem;
    }

    input,
    textarea {
      padding: 1.1rem 2rem 1.3rem;
      font-size: 2.1rem;
      letter-spacing: 0.65px;
      color: #636e95;
    }
    textarea {
      padding: 0 0.5rem;
    }
    fieldset {
      border: none;
    }
  }

  .MuiTextField-root {
    width: 100%;
  }

  form > button {
    font-size: 2.2rem;
    font-weight: bold;
    line-height: normal;
    padding: 2.2rem 3.9rem;
    margin-bottom: 5.1rem;
    ${theme.breakpoints.down("sm")} {
      margin-top: 2rem;
      width: 100%;
    }
  }

  p {
    max-width: 42rem;
    opacity: 0.9;
    font-size: 1.96rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.48;
    letter-spacing: 1.13px;
    color: #42475b;

    &.alert {
      color: #e12b2c;
      margin-top: 5rem;
    }

    ${theme.breakpoints.down("sm")} {
      text-align: center;
      margin-bottom: 3rem;
    }
  }
`;

export const FormError = styled(FlexBox)`
  display: none;
  margin-bottom: 1rem;
  svg {
    height: 2rem;
    margin-right: 0.5rem;

    path {
      fill: #e12b2c;
    }
  }
  p {
    opacity: 0.9;
    font-size: 1.56rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.6px;
    color: #e12b2c;
  }
`;

export const FormAutocomplete = styled(Autocomplete)`
  width: 100%;
  .MuiOutlinedInput-root {
    padding: 0.2rem 0;

    .MuiAutocomplete-input {
      padding: 1.1rem 2rem 1.3rem;
      //width: 100%;
    }

    svg {
      //cursor: pointer;
      //position: absolute;
      //right: 0;
      //pointer-events: none;
    }
  }

  &.MuiAutocomplete-hasClearIcon {
    .MuiOutlinedInput-root {
      padding-right: 0;
    }
  }

  .MuiInputBase-adornedEnd {
    > svg,
    > p {
      cursor: pointer;
      margin-right: 1rem;
    }
  }

  .MuiAutocomplete-endAdornment {
    top: calc(50% - 1.4rem);
    &:hover {
      button {
        background-color: transparent;
      }
    }
  }

  .reset {
    opacity: 0.51;
    //font-family: Hellix;
    font-size: 1.78rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.56px;
    color: #636e95;
    margin-right: 1rem;
  }

  //.open,
  //.close,
  //.reset {
  //  display: none;
  //}
  //
  //:not(&.Mui-focused):not(&.MuiAutocomplete-hasClearIcon) {
  //  .open {
  //    display: block;
  //  }
  //}
  //
  //&.MuiAutocomplete-hasClearIcon:not(&.Mui-focused) {
  //  .reset {
  //    display: block;
  //  }
  //}
  //
  //&.Mui-focused {
  //  .close {
  //    display: block;
  //  }
  //}
`;

export const SignupFormControl = styled(Box)`
  width: 100%;

  &.error {
    label {
      color: #e12b2c;
    }
    .MuiInputBase-root {
      background-color: #e12b2c;
      color: #fff;

      input::placeholder {
        color: #fff;
      }
    }

    .required {
      display: flex;
    }
  }
`;

export const FormAutocompleteListbox = styled(Box)`
  background-color: #97a0c3;
`;

export const FormAutocompleteOption = styled(Box)`
  background-color: #97a0c3;
  color: #fff;

  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
  }

  &:hover,
  &.Mui-focused {
    background-color: rgba(151, 160, 195, 0.8);
    opacity: 0.8;
  }
`;
