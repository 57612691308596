import React from "react";
import { ListItem, ListItemIcon, ListItemText, Typography, Stack } from "@mui/material";
import { Checkbox } from "../../../../assets/svg/icons";
import { whoIsItForList } from "./SignUpHelpers";
import { ForWhoListContainer, SubText } from "./ForWho.styles";

const ForWhoList = () => {
  return (
    <Stack
      sx={{
        mt: { xs: "10rem", sm: "17rem" },
        mb: { xs: "13rem", sm: "25rem" },
        ml: { xs: 0, md: "15rem" },
      }}
    >
      <Typography variant="h2" marginBottom={"5.7rem"}>
        Who is it for?
      </Typography>
      <ForWhoListContainer>
        {whoIsItForList.map((el, i) => (
          <ListItem key={el + i}>
            <ListItemIcon>
              <Checkbox width={36} />
              <ListItemText primary={<Typography>{el}</Typography>} />
            </ListItemIcon>
          </ListItem>
        ))}
      </ForWhoListContainer>
      <SubText>Sounds like you? See you inside!</SubText>
    </Stack>
  );
};

export default ForWhoList;
