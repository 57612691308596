import { Box, Stack } from "@mui/material";
import { dashboardLink } from "../../../../utils/helpers";
import { FlexBox } from "../../../../styles/components/Layout";
import { LearnMoreText, OoopsText, TooltipMainTitle, WhyBox } from "./AttentionTooltip.styles";
import { useNavigate } from "react-router-dom";
import { TooltipUserSvg, TooltipWhySvg } from "./TooltipIcons";
import { pathWithUtm } from "../../../../utils/pathWithUtm";

const AttentionTooltip = () => {
  const navigate = useNavigate();
  return (
    <FlexBox>
      <Box>
        <TooltipUserSvg />
      </Box>
      <Stack ml={"3rem"}>
        <TooltipMainTitle>{`THERE IS NO WEB3M NFT DETECTED`}</TooltipMainTitle>
        <OoopsText>
          <span>{"OOPS.. "}</span>
          {"There is no Web3m NFT in this wallet"}
        </OoopsText>
        <WhyBox>
          <TooltipWhySvg />
          <LearnMoreText>
            {`Wrong wallet. Click on `}
            <span onClick={() => window.location.replace(pathWithUtm(`${dashboardLink}/settings`))}>{"User account"}</span>
            {` to fix it!`}
          </LearnMoreText>
        </WhyBox>
        <LearnMoreText>
          {`OR you don't own Web3m NFT - go `}
          <span onClick={() => navigate(pathWithUtm("/signup"))}>{"HERE"}</span>
          {` to learn more and sign up for minting the NFT.`}
        </LearnMoreText>
      </Stack>
    </FlexBox>
  );
};

export default AttentionTooltip;
