import auth0 from "auth0-js";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

export const DATABASE_CONNECTION = "Username-Password-Authentication";

export const useLogin = () => {
  const { popupData } = useSelector((state) => state.app);
  const [activePage, setActivePage] = useState(popupData.type || "signIn");
  const redirectUri = `${window?.location?.origin}/login`;
  const webAuth = useMemo(() => new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: "https://dev-s5y764on.us.auth0.com/api/v2/",
    redirectUri: redirectUri,
    responseType: "code",
    scope: "openid profile email read:current_user update:current_user_metadata offline_access"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  useEffect(() => {
    setActivePage(popupData.type || "signIn");
  }, [popupData]);

  const handleActivePage = () => {
    const data = {
      signIn: "signUp",
      signUp: "signIn",
      forgotPassword: "signIn",
      requestSent: "signIn"
    };
    setActivePage(data[activePage]);
  };

  return {
    webAuth,
    activePage,
    setActivePage,
    handleActivePage
  };
};