import { HowItWorksBg, HowItWorksListMobile } from "./HowItWorksNft.styles";
import { NFTSectionContainer } from "../../../../styles/components/Layout";
import {ReactComponent as SectionSVG} from './img/HIW.svg'
import { MembershipCTA } from "../membership/MembershipNft.styles";
import { ApplyForNFTMask, ApplyForNFTMaskMobile } from "../../../../assets/svg/decorations";
import { CTAButtonNFT } from "../hero/HeroNft.styles";
import { RabbitLogo } from "../../../../assets/svg/logo";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { howItWorksData } from "./HowItWorksNft.data";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoginWithRedirect } from "../../../../utils/hooks/useLoginWithRedirect";

const HowItWorksNft = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('br768'))
const navigate = useNavigate()
  const {search} = useLocation()
  const {onLogin} = useLoginWithRedirect();

  return (
  <HowItWorksBg>
   <NFTSectionContainer>
     {isMobile ? <HowItWorksListMobile>{howItWorksData.map((el) => <Stack key={el.title}>
       <Typography variant={'h2'}>{el.title}</Typography>
       <Typography>{el.desc}</Typography>
       <img src={el.pic} alt={el.title}/>
     </Stack> )}</HowItWorksListMobile> : <SectionSVG/>}

     <MembershipCTA>
       <ApplyForNFTMask className={'desktopOnly'}/>
       <ApplyForNFTMaskMobile className={'mobileOnly mobMask'}/>
       <CTAButtonNFT
         startIcon={<RabbitLogo/>}
         // onClick={() => navigate('/signup' + search)}
          onClick={onLogin}
       >
         {"CREATE MY FIRST AD"}
         {/*Apply for early access*/}
       </CTAButtonNFT>
     </MembershipCTA>
   </NFTSectionContainer>
  </HowItWorksBg>
 );}

export default HowItWorksNft;