import { DEV_ONLY } from "../utils/helpers";
import clientBuilder from "./clientBuilder";
// import gatewayClient, {findToken} from "./gatewayClient";

const baseURL = `https://mvp-web3-${DEV_ONLY ? "develop-" : ""}dot-winky-apis.ew.r.appspot.com/`
const web3Client = clientBuilder(baseURL);

export const Send404Form = async (data) => {
  const body = {
    ...data,
    origin_of_bug: DEV_ONLY ? "advertisers.web3m.io" : "web3m.io",
  };
  try {
    const response = await web3Client.post("send_404_form", body);
    return response.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

// export const CheckNFT = async (wallet) => {
//   try {
//     const response = await web3Client.post("validate_nft", {
//       wallet_address: wallet,
//     });
//     return response.data;
//   } catch (err) {
//     console.log(err);
//   }
// };

export const uploadLogo = async (data) => {
  try {
    const response = await web3Client.post("upload_logo_base64", data);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const sendFormData = async (inputData) => {
  const data = {
    email: inputData.email,
    first_name: inputData.firstName,
    last_name: inputData.lastName,
    company_name: inputData.company,
    hear_about_us: inputData.hearAboutUs,
    session_id: "inputData.session_id",
  };
  if (inputData.utm_source) {
    data.utm_source = inputData.utm_source;
  }

  try {
    const response = await web3Client.post("/sign_up", data);

    if (response.data.status !== "success") {
      throw new Error("Server Error!");
    }
  } catch (err) {
    console.log(err);
  }
};

export const LoginUpdateForm = async (data) => {
  try {
    const response = await web3Client.post('/send_user_login_alert', data)
    if (response?.data) return response.data
    else return null
  }catch (error) {
    console.log(error)
    return null
  }
}
