//Core
import React from "react";
//Styles
import {LoginDescription, LoginFormStack, LoginTitle} from "../Login.styles"
//Data
import {loginDescriptions, titles} from "../Login.data";
//Hooks
import {useForgotPassword} from "./useForgotPassword";
//Components
import {ForgotPasswordForm} from "./content/ForgotPasswordForm";
import {RequestSent} from "./content/RequestSent";

export const ForgotPassword = ({webAuth, activePage, changePage}) => {
    const {formData, email, sendForm, errorResponse} = useForgotPassword(webAuth, changePage);
    return (
        <LoginFormStack>
            <LoginTitle>{titles[activePage]}</LoginTitle>
            <LoginDescription>{loginDescriptions[activePage](email)}</LoginDescription>
            {activePage === 'forgotPassword' &&
                <ForgotPasswordForm formData={formData} sendForm={sendForm} errorResponse={errorResponse}/>
            }
            {activePage === 'requestSent' && <RequestSent email={email}/>}
        </LoginFormStack>
    )
}