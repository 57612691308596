//Core
import React, { useContext, useState } from "react";
//Styles
import { Skeleton } from "@mui/lab";
import { Typography, Box } from "@mui/material";
import {
  ArrowExpanded,
  SectionBlock,
  SectionBlockTitle,
  SectionDescription,
  SectionTitleBackground
} from "../../shared/styles/layout";
import SectionBlockWithLoader from "../../shared/components/loaders/SectionBlockWithLoader";
import { GoogleSearchesDateRange } from "./GoogleSearches.styles";
//Components
import CustomTable from "../../shared/components/customTable/CustomTable";
import { TableSkeleton } from "../../shared/components/skeleton/TableSkeleton";
//Context
import { BrandInfoContext } from "../../../../../context/BrandInfoContext";
//Utils
import { sortGoogleKeywordData } from "./GoogleSearches.data";
import { fetchItems } from "../../../../../utils/fetchItems";
import { sectionOpenItems } from "../../../../shared/brandAnalysis/help/sectionLabel";
//Images
import RemoveIcon from "@mui/icons-material/RemoveOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";

const tableTitles = ["Most relevant keywords", "Amount"];

const GoogleSearches = () => {
  const { fetchList, keywords, sectionOpen, handleSectionOpen, brandSectionsList } = useContext(BrandInfoContext);

  const isLoading = fetchList.includes(fetchItems.keywords_searches) || fetchList.includes(fetchItems.brand_profile);
  const dataExist = !!keywords?.monthly_clicks?.length;
  const sortedData = sortGoogleKeywordData(keywords);

  if((!isLoading && !keywords) || !brandSectionsList.includes(sectionOpenItems.google_searches)) return null;

  return (
    <SectionBlock className={isLoading ? "loading" : dataExist ? "" : "skeleton"}>
      <SectionTitleBackground/>
      <SectionBlockTitle onClick={() => !isLoading && handleSectionOpen(sectionOpenItems.google_searches)}>
        {'Google Searches'}
      </SectionBlockTitle>
      <SectionDescription sx={{maxWidth: '50rem'}}>
      {'Here are the most common keywords that people search on Google when looking for your products and services.'}
    </SectionDescription>
      <SectionBlockWithLoader loading={isLoading}>
        {(sectionOpen === sectionOpenItems.google_searches) &&
          <Box>
            <GoogleSearchesDateRange>
              {dataExist ? (
                <Typography>{keywords?.time_range ?? ""}</Typography>
              ) : (
                <Skeleton
                  animation={false}
                  sx={{
                    backgroundColor: "rgba(130,148,176,0.28)",
                    borderRadius: "1.8rem",
                    width: { xs: "100%", br480: "32.4rem" }
                  }}
                  variant={"rectangular"}
                  height={"3.6rem"}
                />
              )}
            </GoogleSearchesDateRange>
            {dataExist ? <CustomTable titles={tableTitles} data={sortedData} /> : <TableSkeleton data={6} />}
          </Box>
        }
          <ArrowExpanded onClick={() => !isLoading && handleSectionOpen(sectionOpenItems.google_searches)}>
            {(sectionOpen === sectionOpenItems.google_searches)
              ? <RemoveIcon />
              : <ExpandMoreIcon />
            }
          </ArrowExpanded>
      </SectionBlockWithLoader>
    </SectionBlock>
  );
};

export default GoogleSearches;
