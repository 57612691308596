import { Box, styled } from "@mui/material";
import theme from "../theme";
import { MainTitle } from "./typography";
import { textAlignResponsive } from "../../utils/cssHelpers";
import { mobileScreen, smallMobileScreen } from "../../utils/themeBreakpoints";

export const SectionContainer = styled(Box)`
  position: relative;
  max-width: 144rem;
  margin: 0 auto;
  width: 90%;
  ${theme.breakpoints.down("xl")} {
    max-width: 90%;
    width: 90%;
  }

  ${mobileScreen} {
    max-width: 80%;
  }
  
  ${smallMobileScreen}{
    max-width: 85%;

  }
`;

export const NFTSectionContainer = styled(SectionContainer)`
  max-width: 114.4rem;
  //max-width: 144rem;

  .mobMask {
    > path {
      fill: #f4ede4;
    }
  }
`;

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
`;

export const FlexSpaceBetween = styled(FlexBox)`
  width: 100%;
  justify-content: space-between;
`;

export const FlexBoxWrap = styled(FlexBox)`
  flex-wrap: wrap;
`;

export const FlexResponsive = styled(FlexBox)`
  ${mobileScreen}{
    flex-direction: column;
  }
`

export const SvgBox = styled(Box)`
  & svg {
    width: 100%;
    //height: 100%;
  }
`;

export const ImgBox = styled(Box)`
  & img {
    width: 100%;
    //height: 100%;
  }
`;

export const RelativeBox = styled(Box)`
  position: relative;
`;
export const SectionBackground = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const PageTitle = ({ children }) => {
  return (
    <RelativeBox>
      <SectionBackground sx={{ backgroundImage: "linear-gradient(273deg, #0f143f, #0e75fc)" }} />
      <SectionContainer pt={"12rem"}>
        <MainTitle variant={"h1"} p={"6rem 0"} textAlign={textAlignResponsive}>
          {children}
        </MainTitle>
      </SectionContainer>
    </RelativeBox>
  );
};

export const TitleContainer = styled(Box)`
  position: relative;
  width: 100vw;
  background-color: #002541;
  /* margin-top: 5rem; */
  padding-top: 8rem;
  padding-bottom: 9rem;
  margin-bottom: 5rem;
  ${theme.breakpoints.down("br768")} {
    max-width: 100%;
    margin-bottom: 35%;
    padding-bottom: 0;
  }
  ${theme.breakpoints.down("sm")} {
    padding-top: 4rem;
  }
  ${theme.breakpoints.down("br480")} {
    margin-bottom: 40%;
  }
  h1 {
    max-width: 70rem;
    margin-bottom: 2.5rem;
    b {
      color: #f2a626;
    }
    ${theme.breakpoints.down("xl")} {
      max-width: 50%;
    }
    ${theme.breakpoints.down("br768")} {
      max-width: 100%;
      text-align: center;
    }
    ${theme.breakpoints.down("sm")} {
      font-size: 3.3rem;
    }
  }
  p {
    max-width: 50%;
    color: #fff;
    ${theme.breakpoints.down("xl")} {
      max-width: 40%;
    }
    ${theme.breakpoints.down("br768")} {
      max-width: 63rem;
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }
    ${theme.breakpoints.down("br480")} {
      font-size: 1.8rem;
    }
  }
  .tip {
    position: absolute;
    top: calc(8.9rem + 100%);
    left: 9%;
    z-index: 2;
    ${theme.breakpoints.down("br768")} {
      display: none;
    }
  }
`;
