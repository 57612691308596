//Core
import { useLocation, useNavigate } from "react-router-dom";
//Styles
import {
  AnswerText, ApplyButtonBox,
  BackgroundRound, ContactUs, DividerDashed,
  EarthBox, EarthLeftBox, ExtraEarth, FAQButton,
  FaqNftContainer,
  FaqNftContent, LastQuestion, LineBlock,
  MainTitleFAQ,
  QuestionAnswerBlock, QuestionText, RabbitBox
} from "./FaqNft.styles";
// import { MainButton } from "../../../../styles/components/buttons";
//Images
import Earth from './img/Earth-full.webp'
import SpaceRabbit from './img/Space-zai.webp'
import { AnswerSvg, QuestionSvg } from "../../../../assets/svg/icons";
import { RabbitLogo } from "../../../../assets/svg/logo";
import { ApplyForNftFaq } from "../../../../assets/svg/decorations";
//Data
import { FaqNftData } from "./FaqNftData";
//Hooks
import { useLoginWithRedirect } from "../../../../utils/hooks/useLoginWithRedirect";

const QuestionAnswer = ({ question, answer, i }) => {

  return (
    <QuestionAnswerBlock>
      <LineBlock>
        <QuestionSvg />
        <QuestionText>{question}</QuestionText>
      </LineBlock>
      <LineBlock>
        <AnswerSvg />
        <AnswerText index={i}>{answer}</AnswerText>
      </LineBlock>
      <DividerDashed />
    </QuestionAnswerBlock>
  );
};
export const FaqNft = () => {
  const navigate = useNavigate()
  const {search} = useLocation()
  const {onLogin} = useLoginWithRedirect();

  return (
    <FaqNftContainer>
      <BackgroundRound />
      <ApplyButtonBox>
        <ApplyForNftFaq/>
        <FAQButton
          startIcon={<RabbitLogo />}
          // onClick={() => navigate('/signup' + search)}
          onClick={onLogin}
        >
          {"CREATE MY FIRST AD"}
          {/*{'Apply for early access'}*/}
        </FAQButton>
      </ApplyButtonBox>
      <EarthBox>
        <img src={Earth} alt={"Earth"} />
      </EarthBox>
      <EarthLeftBox>
        <img src={Earth} alt={'Earth'}/>
      </EarthLeftBox>
      <ExtraEarth>
        <img src={Earth} alt={'Earth'}/>
      </ExtraEarth>
      <RabbitBox>
        <img src={SpaceRabbit} alt={'Rabbit'}/>
      </RabbitBox>
      <FaqNftContent>
        <MainTitleFAQ variant={'h2'}>{"Frequently asked questions"}</MainTitleFAQ>
        {FaqNftData.map(({ question, answer }, i) => (
          <QuestionAnswer key={question} question={question} answer={answer} i={i}/>
        ))}
        <LastQuestion>{'Still have questions?'}</LastQuestion>
        <ContactUs>{'Contact us - '}<a href={'mailto:omer@web3m.io'}>{'omer@web3m.io'}</a></ContactUs>
      </FaqNftContent>
    </FaqNftContainer>
  );
};