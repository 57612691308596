import { styled, Button, Link } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { mobileScreen, smallMobileScreen } from "../../utils/themeBreakpoints";
import { FlexBox } from "./Layout";

export const MainButton = styled(LoadingButton)`
  background-color: #9bec00;
  color: #ffffff;
  border-radius: 1.7rem;
  padding: 2.2rem 4.8rem;
  font-size: 2.2rem;
  letter-spacing: 0.29px;
  text-transform: unset;
  //font-weight: 600;
  svg:not(.MuiCircularProgress-svg) {
    height: 4.5rem;
  }
  &:hover {
    background-color: #ffffff;
    color: #0f3885;
  }

  &:disabled {
    background-color: #ccc;
  }

  &.light {
    color: #e7526a;
    background-color: #fff;
    border: 1px solid #e7526a;
  }

  ${mobileScreen} {
    margin: 0;
    transform: translateY(0) !important; // Todo : Check why it's needed for a button to be placed in his correct place.
    width: 100%;
  }
`;

export const NFTButton = styled(MainButton)`
  background-color: #195afe;
  border-radius: 3.6rem;

  &:hover {
    background-color: #195afe;
  }
`;

export const LinkDiscord = styled(Link)`
  font-size: 1.8rem;
  font-weight: 500;
  text-decoration: underline;
  letter-spacing: 0.22px;
  color: #195afe;
  cursor: pointer;
  z-index: 2;
`;

export const SecondaryButton = styled(Button)`
  padding: 1.5rem 1.8rem;
  border-radius: 6px;
  border: solid 1px #5680ad;
  background-color: #fff;

  font-size: 1.8rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-transform: none;
  letter-spacing: 0.2px;
  color: #5680ad;
  text-align: left;
  transition: 0.3s;

  &.dark {
    background-color: #5680ad;
    color: #fff;

    &:hover {
      background-color: #5680ad;
    }
  }

  &:hover {
    background-color: #fff;
    opacity: 0.8;
  }

  ${mobileScreen} {
    display: flex;
    width: 80%;
    max-width: 60rem;
    min-width: 32rem;
    margin: 0 auto;
    padding: 2rem;
  }

  ${smallMobileScreen} {
    width: 50%;
  }
`;

export const FloatButtons = styled(FlexBox)`
  position: absolute;
  //top: 98%;
  //transform: translateY(-100%);
  width: 100%;
  row-gap: 5rem;
  column-gap: 3rem;

  > a {
    width: fit-content;
  }
`;
