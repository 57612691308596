import { FlexBox, ImgBox, SectionContainer } from "../../../../../styles/components/Layout";
import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import incomeImg from "./img/income.webp";
import { PixelBanner, StepsContainer, StepsDescription, StepsImageBox } from "./Steps.styles";
import { CodeIcon } from "../../../../../assets/svg/icons";
import stepsImg from "./img/steps.webp";
import { SecondaryTitle, SectionParagraph } from "../../../../../styles/components/typography";

const Steps = () => {
  return (
    <SectionContainer p={"12rem 0"}>
      <Grid2 container spacing={"6rem"}>
        <Grid2 xs={12} md={6}>
          <SecondaryTitle mb={{xs: '6rem', br480: '3rem'}}>
            Monetize your website traffic
          </SecondaryTitle>
          <SectionParagraph>
            Sell your anonymous web3 data to the Web3m ad network. Get paid every time your data is used in an ad campaign.
          </SectionParagraph>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <StepsImageBox className={"desktopOnly"}>
            <img src={incomeImg} alt={'steps'} />
          </StepsImageBox>
        </Grid2>
      </Grid2>

      <PixelBanner>
        <FlexBox>
          <CodeIcon />
          <Typography variant={"h2"}>
            Install the pixel. <br className={"mobileOnly"} /> Start earning today
          </Typography>
        </FlexBox>
        <a className={"desktopOnly"}>
          https://web3m.io/pixel.js
        </a>
      </PixelBanner>
      <a  className={"mobileOnly"}>
        <Typography variant={"subtitle1"} color={"#0e67e0"} sx={{ fontWeight: 500, textDecoration: "underline", textAlign: "center" }}>
          https://web3m.io/pixel.js
        </Typography>
      </a>

      <StepsContainer>
        <ImgBox>
          <img src={stepsImg} alt={"steps description"} />
        </ImgBox>
        <StepsDescription>
          <Typography>Fill the form to get the instructions</Typography>
          <Typography>Paste the pixel into your website code</Typography>
          <Typography>Start collecting data and monetize</Typography>
        </StepsDescription>
      </StepsContainer>
    </SectionContainer>
  );
};

export default Steps;
