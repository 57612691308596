import FAQContent from "./content/FAQContent";
import FAQTitle from "./title/FAQTtitle";
import React from "react";
import { PageTitle } from "../../../styles/components/Layout";

const FAQ = () => {
  return (
    <>
      <PageTitle>FAQ</PageTitle>
      <FAQTitle />
      <FAQContent />
    </>
  );
};

export default FAQ;
