import { RelativeBox, SectionBackground, SectionContainer } from "../../../../../styles/components/Layout";
import AdvantagesGrid from "../../../../templates/advantages/AdvantagesGrid";
import { advantagesList } from "./Advantages.data";

const Advantages = () => {

  return (
    <RelativeBox>
      <SectionBackground backgroundColor={'#fafafa'}/>
      <SectionContainer p={'15rem 0'}>
       <AdvantagesGrid data={advantagesList}/>
      </SectionContainer>
    </RelativeBox>
  );
};

export default Advantages;
