import { Box, Stack, styled, Typography } from "@mui/material";
import { FlexBox, ImgBox } from "../../../styles/components/Layout";
import ConnectionBg from "./img/ConnectrionBg.webp"

export const ConnectionContainer = styled(Stack)`
  background-image: url(${ConnectionBg});
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  justify-content: center;
  align-items: center;
`

export const LogoBox = styled(FlexBox)`
  position: absolute;
  height: 2rem;
  width: 9.5rem;
  top: 3.1rem;
  left: 3.5rem;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: #000000;
    }
    opacity: 0.19;
  }
`

export const ConnectionContent = styled(Stack)`
  max-width: 64rem;
  max-height: 78rem;
  width: 90%;
  height: 90%;
  border-radius: 17px;
  box-shadow: 0 27px 42px 0 rgba(0, 0, 0, 0.05);
  background: #FFFFFF;
  padding: 13rem;
  //justify-content: center;
  //align-items: center;
`
export const ConnectionTitle = styled(Typography)`
  font-family: 'F37 Judge', sans-serif;
  font-size: 3.12rem;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: #385261;
`
export const ConnectionDotsFlashing = styled(Box)`
  display: inline-block;
  margin-left: 1.7rem;
  margin-bottom: 1.4rem;
  &.dot-flashing, &.dot-flashing::before, &.dot-flashing::after {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 5px;
    background-color: #000000;
    color: #000000;
  }

  &.dot-flashing {
    position: relative;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }

  &.dot-flashing::before, &.dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    animation: dot-flashing 1s infinite linear alternate;
  }

  &.dot-flashing::before {
    left: -1.2rem;
    animation-delay: 0s;
  }

  &.dot-flashing::after {
    left: 1.2rem;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: #000000;
    }
    50%, 100% {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
`

export const ConnectionDescription = styled(Typography)`
   font-size: 1.4rem;
  line-height: 1.31;
  letter-spacing: 0.16px;
  color: #385261;
  max-width: 31rem;
  margin-top: 1.6rem;
  &.link {
    color: #4f6bb1;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 0.5rem;
  }
`

export const ConnectionImage = styled(ImgBox)`
  width: 100%;
  height: 100%;
  margin: 7.1rem 0 7rem;
`
