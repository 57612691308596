import Steps from "./steps/Steps";
import WhatIs from "./whatIs/WhatIs";
import UseCases from "./useCases/UseCases";
import { PageTitle } from "../../../../styles/components/Layout";

const Pixel = () => {
  return (
    <>
      <PageTitle>Web3m <b>pixel</b></PageTitle>
      <Steps />
      <WhatIs />
      <UseCases />
    </>
  );
};

export default Pixel;
