import Rabbit1 from "./img/Rab1.webp";
import Rabbit2 from "./img/Rab2.webp";
import Rabbit3 from "./img/Rab3.webp";
import Rabbit4 from "./img/Rab4.webp";
import Rabbit5 from "./img/Rab5.webp";

export const nftHolderGain = [
  "Early access to Web3m’s advertising platform",
  "A kickback from 2% of total ad spend on Web3m",
  "Exclusive access to the Web3m token presale",
];

export const rabImages = [
  <img src={Rabbit1} alt="rabbit nft" className={"desktopOnly"} />,
  <img src={Rabbit2} alt="rabbit nft" className={"desktopOnly"} />,
  <img src={Rabbit3} alt="rabbit nft" className={"desktopOnly"} />,
  <img src={Rabbit4} alt="rabbit nft" className={"desktopOnly"} />,
  <img src={Rabbit5} alt="rabbit nft" className={"desktopOnly"} />,
];
