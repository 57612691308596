import { FlexBox, ImgBox, RelativeBox, SectionBackground, SectionContainer } from "../../../../../styles/components/Layout";
import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useCasesList } from "./UseCases.data";
import { UseCasesCard } from "./UseCases.styles";
import { textAlignResponsive } from "../../../../../utils/cssHelpers";

const UseCases = () => {
  return (
    <RelativeBox>
      <SectionBackground sx={{ backgroundColor: "#191a24" }} />
      <SectionContainer p={"17rem 0"}>
        <Typography variant={"h2"} color={"#fff"} mb={"7.5rem"} textAlign={textAlignResponsive}>
          Use cases
        </Typography>

        <Grid2 container spacing={{xs: "7.5rem", xl: '12rem' }}>
          {useCasesList?.map((el, i) => (
            <Grid2 xs={12} md={4} key={el.title}>
              <UseCasesCard>
                <FlexBox>
                  {el.icon}
                  <Typography variant={"h3"} color={"#fff"} mb={"4rem"}>
                    {el.title}
                  </Typography>
                </FlexBox>
                <Typography color={"#fff"}>{el.desc}</Typography>
                <ImgBox mb={"3rem"} mt={"auto"}>
                  <img src={el.img} alt={'Use cases'} />
                </ImgBox>
              </UseCasesCard>
            </Grid2>
          ))}
        </Grid2>
      </SectionContainer>
    </RelativeBox>
  );
};

export default UseCases;
