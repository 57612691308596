//Core
import React from "react";
//Styles
import { FormInputName } from "../../../../../mainContent/404/support/Forms.styles";
import {RequestSentList} from "./RequestSent.styles";
import {ListItem, ListItemIcon} from "@mui/material";
//Data
import {RequestSentListItems} from "../ForgotPassword.data";
//Icons
import CircleIcon from "@mui/icons-material/Circle";

export const RequestSent = ({email}) => {
    return (
        <>
            <FormInputName className={'bold'}>
                {"Not received e-mail?"}
            </FormInputName>
            <RequestSentList>
                {RequestSentListItems(email).map((item, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            <CircleIcon/>
                        </ListItemIcon>
                        <FormInputName>
                            {item}
                        </FormInputName>
                    </ListItem>
                ))}
            </RequestSentList>
        </>
    )
}