import img1 from "./img/advantages1.webp";
import img2 from "./img/advantages2.webp";
import img3 from "./img/advantages3.webp";
import img4 from "./img/advantages4.webp";
import img5 from "./img/advantages5.webp";

export const advantagesList = [
  {
    title: "Launch a multi-channel \n  campaign in 5 minutes",
    desc: "Design and publish on-brand campaigns across thousands of websites, exchanges, and popular blogs. Web3m combines audience data with AI-assisted creative to help you go live faster.",
    img: img1,
  },
  {
    title: "Use smart targeting to reach \n  your ideal customers",
    desc: "Improve traffic and conversions with AI-assisted audience segmentation and targeting. Web3m makes it easy to get the right message in front of the right people.",
    img: img2,
  },
  {
    title: "Monitor campaign \n results  in real-time",
    desc: "Compare campaign results across channels. The Web3m dashboard gives you real-time insights so that you can double down on what’s working.",
    img: img3,
  },
  {
    title: "Scale your content and \n ad copy",
    desc: "Use Web3m’s built-in AI writing assistant to write copy and content that resonates with your target customers.",
    img: img4,
  },
  {
    title: "Success you can measure, \n costs you can control",
    desc: "Set budgets that fit your goals. Track campaign performance so you can double down on what’s working.",
    img: img5,
  },
];
