export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const getMonthlyData = (source) => {
  if (!source) return null;
  const res = [];
  const arr = Object.values(source);
  arr.forEach((year) => {
    Object.entries(year)
      .sort(([aTitle], [bTitle]) => months.indexOf(aTitle) - months.indexOf(bTitle))
      .forEach(([month, value]) => {
        res.push({ month, eng: value });
      });
  });

  return res;
};
