import tasksImg from "../../../../assets/img/cards/tasks.webp";
import deliveryImg from "../../../../assets/img/cards/delivery.webp";
import configImg from "../../../../assets/img/cards/config.webp";
import budgetImg from "../../../../assets/img/cards/budget.webp";
import loudspeakerImg from "../../../../assets/img/cards/loudspeaker.webp";
import securityImg from "../../../../assets/img/cards/security.webp";

export const featuresList = [
  {
    title: "Data Enrichment",
    desc: "Enhance your data with comprehensive information from blockchain activities, social media interactions, and community engagements",
    img: tasksImg,
  },

  {
    title: "Predictive Analytics",
    desc: "Use advanced algorithms to transform data into insights and predict future trends",
    img: deliveryImg,
  },
  {
    title: "Personalized Marketing",
    desc: "Automate and tailor your campaigns to reach the right audience effectively",
    img: configImg,
  },

  {
    title: "Community Engagement",
    desc: "Join a network of over 1,100 Web3 companies to collaborate and grow together",
    img: budgetImg,
  },

  {
    title: "Real-Time Monitoring",
    desc: "Keep track of blockchain, social media, and community activities to refine your strategies",
    img: loudspeakerImg,
  },

  {
    title: "Scalable User Acquisition",
    desc: "Improve your ability to acquire new users through targeted audience identification",
    img: securityImg,
  },
];
