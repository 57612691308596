import { FlexBox, FlexResponsive, RelativeBox, SectionContainer } from "../../../../styles/components/Layout";
import { Typography } from "@mui/material";
import { RoadmapBackground, RoadmapButton, RoadmapButtonsContainer, RoadmapGraphContainer } from "./Roadmap.styles";
import { RoadmapGraph, RoadmapGraphBackground, RoadmapMobile } from "./img/Roadmap.graph";
import { BulbIcon } from "../../../../assets/svg/icons";
import { Link } from "react-router-dom";
import { textAlignResponsive } from "../../../../utils/cssHelpers";
import { SecondaryButton } from "../../../../styles/components/buttons";
import { SecondaryTitle, SectionParagraph } from "../../../../styles/components/typography";
import { pathWithUtm } from "../../../../utils/pathWithUtm";

const Roadmap = () => {
  return (
    <>
      <SectionContainer>
        <Typography variant={"h1"} color={"#0f3987"} mb={"4.6rem"} textAlign={textAlignResponsive}>
          Our vision
        </Typography>
        <SecondaryTitle mb={"4.6rem"}>Reimagine web3 growth data</SecondaryTitle>
        <SectionParagraph maxWidth={"77rem"} m={{ xs: "0 auto 6.5rem", md: "0 0 6.5rem" }}>
          To empower Web3 companies by leveraging internal and external data into innovative AI agents, building a decentralized and
          transparent ecosystem that unlocks new capabilities and workflows in marketing, product enhancement, community interaction, and
          social expansion, delivering unparalleled value in the digital age.
        </SectionParagraph>
      </SectionContainer>
      <RelativeBox mb={"9rem"}>
        <RoadmapGraphContainer>
          <RoadmapGraph className={"graph desktopOnly"} />
          <RoadmapGraphBackground className={"bg desktopOnly"} />
          <RoadmapMobile />
        </RoadmapGraphContainer>
      </RelativeBox>
      <SectionContainer pb={"12rem"}>
        <RoadmapButtonsContainer>
          <Link to={pathWithUtm("/ecosystem")}>
            <RoadmapButton startIcon={<BulbIcon />}>LEARN MORE ABOUT DATA ECOSYSTEM</RoadmapButton>
          </Link>
          <Link to={pathWithUtm("/roadmap")}>{/* <SecondaryButton>SEE DETAILED ROADMAP</SecondaryButton> */}</Link>
        </RoadmapButtonsContainer>
      </SectionContainer>
    </>
  );
};

export default Roadmap;
