import { CoinsSvg } from "../../../../../assets/svg/buttonIcons";
import image1 from "./img/usecases1.webp";
import image2 from "./img/usecases2.webp";
import image3 from "./img/usecases3.webp";
import { LoudspeakerIcon, StoreIcon } from "../../../../../assets/svg/icons";

export const useCasesList = [
  {
    icon: CoinsSvg(),
    title: "Earn money for \n browsing the web",
    desc: "It literally pays to share your data. For web3 users, you can browse web3 as usual. In the background, your data is anonymously tracked. Every time an advertiser uses your data in their campaign, you get paid.",
    img: image1,
  },
  {
    icon: StoreIcon(),
    title: `Sell your \n analytics data`,
    desc: "After you install the Web3m pixel on your website, you can begin selling that anonymous data to the Web3m data network. Every time that data is used in a targeted ad, you get paid.",
    img: image2,
  },
  {
    icon: LoudspeakerIcon(),
    title: `Create targeted \n ads`,
    desc: "Analytics is the core to understanding your website visitors. The Web3m pixel allows you to see their online activity, empowering you to create marketing material and products based on real consumer trends.",
    img: image3,
  },
];
