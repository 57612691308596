import { SectionContainer } from "../../../../../styles/components/Layout";
import { SectionMainTitle } from "../../../../../styles/components/typography";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ecosystemList } from "./Ecosystem.data";
import { EcosystemCard } from "./Ecosystem.styles";
import { Typography } from "@mui/material";

const Ecosystem = () => {
  return (
    <SectionContainer p={"10rem 0 17rem"}>
      <SectionMainTitle variant={"h1"} color={"#0f3987"} mb={"9rem"}>
        A data ecosystem,{" "}
        <b>
          fueled by <br /> profit distribution
        </b>
      </SectionMainTitle>
      <Grid2 container spacing={{ xs: "8rem", md: "5rem", lg: "11rem" }}>
        {ecosystemList.map((el) => (
          <Grid2 xs={12} md={4} key={el.text}>
            <EcosystemCard>
              {el.icon}
              <Typography>{el.text}</Typography>
            </EcosystemCard>
          </Grid2>
        ))}
      </Grid2>
    </SectionContainer>
  );
};

export default Ecosystem;
