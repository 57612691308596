import { Stack, styled } from "@mui/material";
import { mobileScreen, smallMobileScreen, tabletOnlyScreen } from "../../../../../utils/themeBreakpoints";

export const UseCasesCard = styled(Stack)`
  height: 100%;
  
  ${mobileScreen}{
    max-width: 60rem;
    margin: 0 auto;
  }

  svg {
    height: 5.6rem;
    margin-right: 1rem;
  }

  h3 {
    font-size: 2.9rem;
    line-height: 1.07;
    letter-spacing: 1.01px;
    white-space: pre-line;
    margin-bottom: 2.5rem;
  }

  > div:first-of-type {
    align-items: flex-start;
    
    ${mobileScreen}{
      width: 80%;
      margin: 0 auto;
      justify-content: center;
      svg{
        margin-right: .5rem;
      }
    }
    
    ${smallMobileScreen}{
      justify-content: center;
    }
  }

  > div:last-of-type {
    
    ${mobileScreen}{
      //width: 80%;
      margin-bottom: 10rem;
    }
    
    ${smallMobileScreen}{
      max-width: 40rem;
      margin: 0 auto 5rem;
    }
    img {
      border-radius: 1rem;
      filter: grayscale(100%);
      height: 31rem;
      object-fit: cover;

      ${tabletOnlyScreen} {
        width: 80%;
        height: fit-content;
        display: block;
        margin: 0 auto;
      }

      // ${smallMobileScreen} {
      //   display: block;
      //   //width: 80%;
      //   //margin: 0 auto;
      // }
    }
  }

  p {
    width: 95%;
    margin-bottom: 7rem;
    opacity: 0.63;
    
  }

  a {
    margin-top: auto;
    color: #fff;
    opacity: 0.68;
    width: fit-content;
    transition: 0.3s;
    text-decoration: underline;

    > svg {
      margin-left: 1rem;
    }

    &:hover {
      opacity: 0.9;
    }
  }
  
  ${mobileScreen}{
    p {
      width: 80%;
      text-align: center;
      margin: 0 auto 10rem;
    }
  }

  ${smallMobileScreen} {
    p {
      text-align: center;
    }


  }
`;
