import { SectionContainer } from "../../../../../styles/components/Layout";
import { SecondaryTitle, SectionMainTitle } from "../../../../../styles/components/typography";
import CardsGrid from "../../../../templates/cardsGrid/CardsGrid";
import { whatForList } from "./WhatFor.data";

const WhatFor = () => {
  return (
    <SectionContainer p={'17rem 0 5rem'}>
      <SectionMainTitle mb={"8rem"}>
        The only ad network built for the <br /> <b>metaverse and web3 social media</b>
      </SectionMainTitle>
      <SecondaryTitle mb={"9rem"}>
        What will you accomplish <br /> with better data?
      </SecondaryTitle>
      <CardsGrid data={whatForList} />
    </SectionContainer>
  );
};

export default WhatFor;
