import { CircularProgress, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { CloseSvg } from "../../../assets/svg/buttonIcons";
import { setActivePopup, setPopupData } from "../../../redux/app/appSlice";
import { CloseDialog, LoaderContainer, StyledDialog, StyledDialogContent } from "./Popup.style";

const Popup = ({ open = false, loading = false, children, close}) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    close && close();
    dispatch(setActivePopup(""));
    dispatch(setPopupData({}))
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: "#000", opacity: 0.82 } }}
      PaperProps={{ style: {backgroundColor:"#fff"} }}
      sx={{ zIndex: 1300 }}
    >
      <StyledDialogContent>
        <CloseDialog onClick={handleClose}>
          <CloseSvg />
        </CloseDialog>

        <Box position="relative" width={'100%'} heignt={'77rem'}>
          {children}
          {loading && (
            <LoaderContainer>
              <CircularProgress />
            </LoaderContainer>
          )}
        </Box>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default Popup;
