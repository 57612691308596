export function CalcIcon(props) {
  return (
    <svg
      viewBox="0 0 62 84"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 9c0-4.972 4.028-9 9-9h43.2c4.972 0 9 4.028 9 9v64.8c0 4.972-4.028 9-9 9H9c-4.972 0-9-4.028-9-9V9zm9-5.4A5.4 5.4 0 003.6 9v12.6h10.8v-1.8a1.8 1.8 0 013.6 0v1.8h10.8v-1.8a1.8 1.8 0 013.6 0v1.8h25.2V9a5.4 5.4 0 00-5.4-5.4H9zm48.6 21.6H32.4V27a1.8 1.8 0 01-3.6 0v-1.8H18V27a1.8 1.8 0 01-3.6 0v-1.8H3.6v14.4h39.6v-1.8a1.8 1.8 0 013.6 0v1.8h10.8V25.2zm0 18H46.8V45a1.8 1.8 0 01-3.6 0v-1.8H3.6v14.4h10.8v-1.8a1.8 1.8 0 013.6 0v1.8h10.8v-1.8a1.8 1.8 0 013.6 0v1.8h25.2V43.2zm0 18H32.4V63a1.8 1.8 0 01-3.6 0v-1.8H18V63a1.8 1.8 0 01-3.6 0v-1.8H3.6v12.6A5.4 5.4 0 009 79.2h43.2a5.4 5.4 0 005.4-5.4V61.2z"
        transform="translate(-942 -3239) translate(942.669 3239.624)"
        fill="#5680AD"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}

export function QualityIcon(props) {
  return (
    <svg
      viewBox="0 0 62 83"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.6 3.6c-14.915 0-27 12.089-27 27 0 7.124 2.758 13.6 7.265 18.425C15.797 54.302 22.81 57.6 30.6 57.6c7.79 0 14.803-3.298 19.735-8.575A26.895 26.895 0 0057.6 30.6c0-14.911-12.085-27-27-27M0 30.6C0 13.702 13.702 0 30.6 0c16.898 0 30.6 13.702 30.6 30.6a30.5 30.5 0 01-7.578 20.16l5.854 24.595c1.036 4.335-3.33 7.985-7.416 6.2l-20.74-9.076a1.82 1.82 0 00-1.44 0L9.14 81.554C5.054 83.34.688 79.69 1.724 75.355L7.578 50.76A30.5 30.5 0 010 30.6m10.573 23.134L5.223 76.19c-.345 1.444 1.113 2.66 2.474 2.067l20.736-9.076a5.434 5.434 0 014.334 0l20.736 9.076c1.361.594 2.819-.623 2.473-2.067l-5.35-22.456A30.466 30.466 0 0130.6 61.2a30.466 30.466 0 01-20.027-7.466m35.701-31.608a1.806 1.806 0 010 2.548l-14.4 14.4a1.806 1.806 0 01-2.548 0l-7.2-7.2a1.806 1.806 0 010-2.548 1.806 1.806 0 012.548 0l5.926 5.929 13.126-13.13a1.806 1.806 0 012.548 0"
        transform="translate(-465 -3495) translate(465.5 3495.891)"
        fill="#5680AD"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}