import { Box, List, styled } from "@mui/material";
import { FlexBox } from "../../../../styles/components/Layout";

export const MobileMenuBody = styled(List)`
  width: 95%;
  margin: 5vh auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  a {
    text-decoration: none;
    color: #000;
    width: 100%;
    button {
      width: 100%;
    }
  }
  li {
    font-size: 3rem;
    font-weight: 500;

    p {
      font-size: 3rem;
    }
    margin-bottom: 3vh;
    padding-left: 0;
    &.access {
      width: 101%;
      margin-top: auto;
    }
    button {
      width: 100%;
      background-color: #195afe;
      height: 7.5rem;
      &:hover {
        background-color: #195afe;
      }
      a {
        color: #fff;
        font-size: 2rem;
      }
    }
  }
  button {
    display: flex !important;
  }
`;

export const MobileMenuContainer = styled(Box)`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const MobileMenuHead = styled(FlexBox)`
  width: 95%;
  margin: 2.5rem auto;
  justify-content: space-between;
  > svg {
    width: 13rem;
    path {
      fill: #00213a;
    }
  }
  > button > svg {
    font-size: 4rem;
    transform: translateX(20px);
    path {
      fill: #00213a;
    }
  }
`;
