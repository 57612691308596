import prImage from "../../../../../assets/img/cards/security.webp";
import customersImage from "../../../../../assets/img/cards/budget.webp";
import performanceImage from "../../../../../assets/img/cards/config.webp";

export const howItWorksList = [
  {
    title: "Websites install the Web3m pixel",
    desc: "Site owners are incentivized to install a pixel on their website. They will be paid from the data network every time their analytics are used in an advertising campaign.These websites build the foundation of the ad network.",
    img: prImage,
  },
  {
    title: "Marketers pay for ads—and keep the ad network fueled",
    desc: "As advertisers launch campaigns, a portion of every ad dollar is distributed back into the data network, compensating websites for their analytics and site visitors for their data.",
    img: customersImage,
  },
  {
    title: "Site visitors control their data",
    desc: "Every web3 user has the ability to delete their data from the network at any time. However, if they choose to maintain their data in the network for use by advertisers, they are paid every time their (anonymous) data is used.",
    img: performanceImage,
  },
];
