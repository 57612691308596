//Core
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
//Styles
import { Typography } from "@mui/material";
import { LoginImgBox, LoginImgStack } from "./Login.styles";
import { FlexSpaceBetween, ImgBox } from "../../../../styles/components/Layout";
//Hooks
import { useBreakpoints } from "../../../../utils/hooks/useBreakpoints";
import { useLogin } from "./useLogin";
//Components
import { SignInSignUp } from "./signInSignUp/SignInSignUp";
import Popup from "../Popup";
//Data
import { changePageButtonText, changePageText, loginImage, loginImageMobile } from "./Login.data";
import { ForgotPassword } from "./forgotPassword/ForgotPassword";

const loginPopup = ["signIn", "signUp"];
const password = ["forgotPassword", "requestSent"];
const LoginPopup = () => {
  const { popup } = useSelector((state) => state.app);
  const { screen768, laptopScreen } = useBreakpoints();
  const { isLoading, isAuthenticated } = useAuth0();
  const { webAuth, activePage, setActivePage, handleActivePage } = useLogin();

  return (
    <Popup open={popup === "login"}>
      {!isAuthenticated && !isLoading &&
        <>
          {screen768 &&
            <ImgBox>
              <img src={loginImageMobile[activePage]} alt={activePage || 'image'} width={500} height={'100%'} />
            </ImgBox>
          }
          <FlexSpaceBetween sx={{ maxHeight: { xs: "auto", br768: '70rem', md: "71rem" } }}>
            {loginPopup.includes(activePage) &&
              <SignInSignUp webAuth={webAuth}
                            activePage={activePage}
                            changePage={setActivePage}
              />
            }
            {password.includes(activePage) &&
              <ForgotPassword webAuth={webAuth}
                              activePage={activePage}
                              changePage={setActivePage}
              />
            }
            {!screen768 &&
              <LoginImgBox>
                <img src={loginImage[activePage]} alt={activePage || 'image'} width={264} height={laptopScreen ? 580 : 771} />
                <LoginImgStack>
                  <Typography>{changePageText[activePage]}</Typography>
                  <Typography onClick={handleActivePage}>{changePageButtonText[activePage]}</Typography>
                </LoginImgStack>
              </LoginImgBox>
            }
          </FlexSpaceBetween>
          {screen768 &&
            <LoginImgStack>
              <Typography>{changePageText[activePage]}</Typography>
              <Typography onClick={handleActivePage}>{changePageButtonText[activePage]}</Typography>
            </LoginImgStack>
          }
        </>}
    </Popup>
  );
};

export default LoginPopup;
