import influencerImg from "../../../../../assets/img/cards/loudspeaker.webp";
import customersImg from "../../../../../assets/img/cards/budget.webp";
import analysisImg from "../../../../../assets/img/cards/tasks.webp";

export const useCasesList = [
  {
    title: "Send targeted web3 ads",
    desc: "Data is the foundation for targeted advertising. Without it, marketing is only as good as your best guess. Web3m gives advertisers access to the same powerful data they’ve gotten used to in web2.",
    img: influencerImg,
  },  {
    title: "Give websites better analytics",
    desc: "Web3m enables websites to learn more about their site visitors, while remaining fully GDPR compliant. It takes less than two minutes to install the Web3m pixel. Get started today.",
    img: customersImg,
  },  {
    title: "Create a more transparent internet",
    desc: "Online privacy has become an important issue in recent years. When we designed Web3m, we made sure to buid privacy best practices into the foundation of our product.",
    img: analysisImg,
  },
];
