import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import theme from "../../../../../styles/theme";
import { FlexBox } from "../../../../../styles/components/Layout";

export const PotentialUsersBasedOn = styled(Typography)`
  text-align: center;
  margin-top: 7.5rem;
  font-size: 1.8rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #4a5767;
  ${theme.breakpoints.down("md")} {
    margin-top: 7rem;
  }

  span {
    font-size: 1.32rem;
    font-weight: bold;
    letter-spacing: 0.15px;
    color: #1a418e;
    margin: 0 0.5rem;
    text-transform: uppercase;

    svg {
      height: 2rem;
      width: 2rem;
      vertical-align: text-bottom;

      path {
        fill: #1a418e;
      }
    }
  }

  ${theme.breakpoints.down("br480")} {
    color: #9da9b8;

    span {
      color: #b2bbda;
      margin: 0 0.3rem;

      svg path {
        fill: #b2bbda;
      }
    }
  }
`;

export const PotentialUsersDataCards = styled(FlexBox)`
  justify-content: center;
  margin-top: 4rem;
  column-gap: 10%;

  ${theme.breakpoints.down("md")} {
    column-gap: 5%;
  }
`;

export const DataCard = styled(Box)`
  background-color: #f4f5f7;
  border-radius: 1.1rem;
  padding: 3.8rem 1.5rem;
  width: 20rem;
  min-height: 16rem;
  /* margin: 0 auto; */
  text-align: center;
  /* width: 90%; */
  /* &:last-of-type{
    margin-left: 8.3rem;
  } */

  ${theme.breakpoints.down("br480")} {
    min-height: auto;
    padding: 3rem 1rem;
  }

  h4 {
    font-size: 1.8rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #4a5767;
    white-space: nowrap;
    margin-bottom: 0.5rem;

    ${theme.breakpoints.down("br480")} {
      font-size: 1.4rem;
      color: #78899d;
    }
  }

  h2 {
    font-family: "Graphik", sans-serif;
    font-size: 5.2rem;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0.58px;
    text-align: center;
    color: #3e3c3c;

    ${theme.breakpoints.down("br480")} {
      font-size: 4.16rem;
      color: #364166;
    }
  }
`;
