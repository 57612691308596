import { Box, Grid, styled, Typography } from "@mui/material";
import { FlexBox, NFTSectionContainer } from "../../../../../styles/components/Layout";
import theme from "../../../../../styles/theme";

export const BackgroundRound = styled(Box)`
  background-color: #00213a;
  border-top-left-radius: 31rem;
  border-top-right-radius: 31rem;
  width: 100%;
  height: 45%;
  position: absolute;
  bottom: 0;
  left: 0;
  @media screen and (max-width: 2450px) {
    border-top-right-radius: 0;
  }
  ${theme.breakpoints.down('br768')} {
    border-top-left-radius: 20rem;
  }
`

export const BenefitsContainer = styled(Box)`
  position: relative;
  top: -28.5rem;
  bottom: 0;
  width: 100%;
  height: 100%;
  //margin-bottom: -28.7rem;
  z-index: 1;
  //background-color: rgba(244, 237, 228, 0);
  padding-top: 16rem;

  ${theme.breakpoints.down('br768')} {
    padding-top: 12rem;
  }
`

export const BenefitsContent = styled(NFTSectionContainer)`
  position: relative;
  z-index: 10;
  padding-bottom: 14rem;
  ${theme.breakpoints.down('xl')} {
    max-width: 80%;
    padding-left: 2%;
  }
  ${theme.breakpoints.down('br1024')} {
    max-width: 90%;
    padding-left: 7%;
  }
  ${theme.breakpoints.down('br768')} {
    max-width: 80%;
    padding-left: 5%;
  }
`
export const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
`

const imgWidth = ['18.4rem', '15rem', '12rem', '15.2rem', '13.8rem', '16rem']
export const ImageBox = styled(FlexBox)`
  align-items: flex-end;
  height: 16.6rem;
  margin-bottom: 3.7rem;
  img {
    width: ${props => imgWidth[props.i]};
  }
`

export const GridTitle = styled(Typography)`
  font-family: 'F37 Judge', sans-serif;
  font-size: 2.9rem;
  letter-spacing: 1.01px;
  line-height: normal;
  color: #fff;
  width: 72%;
  margin-bottom: 2rem;
`

export const GridDesc = styled(Typography)`
  font-size: 2rem;
  line-height: 1.25;
  letter-spacing: -0.21px;
  color: rgba(255, 255, 255, 0.66);
  width: 72%;
`
