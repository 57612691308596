import * as React from "react"

export function TooltipUserSvg(props) {
  return (
    <svg
      width="9.6rem"
      height="13.6rem"
      viewBox="0 0 96 136"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path id="a" d="M0 0L55.1070127 0 55.1070127 135 0 135z" />
        <path
          id="c"
          d="M0 0L37.5043389 0 37.5043389 24.8568008 0 24.8568008z"
        />
      </defs>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M74.344 116.902l1.164 5.987a9.155 9.155 0 01-17.974 3.494l-1.144-5.887-10.44-53.715-2.921-15.033-2.374-12.214c-8.327-.242-15.675-6.196-17.331-14.716-1.638-8.424 2.83-16.603 10.307-20.017l3.194 16.433 10.288 3.543 8.213-7.139-3.194-16.432c8.212.364 15.417 6.274 17.054 14.699 1.656 8.52-2.927 16.793-10.557 20.135l1.763 9.072 13.952 71.79z"
          transform="translate(-1378 -141) translate(1378.144 141.699)"
          stroke="#400040"
          strokeWidth={2.5}
        />
        <g transform="translate(-1378 -141) translate(1378.144 141.699) translate(21.776)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            d="M44.723 135c-2.043 0-4.04-.606-5.772-1.774a10.293 10.293 0 01-4.377-6.613l-16.7-85.927a19.524 19.524 0 01-10.937-4.481A19.429 19.429 0 01.364 25.048c-.848-4.364-.208-8.747 1.851-12.677a19.534 19.534 0 019.14-8.667 1.206 1.206 0 011.684.867l3.06 15.75 8.973 3.09 7.161-6.226-3.06-15.75A1.206 1.206 0 0130.408 0c4.28.191 8.442 1.83 11.72 4.614 3.381 2.872 5.617 6.697 6.465 11.06.846 4.353.165 8.901-1.916 12.807a19.528 19.528 0 01-8.463 8.252l1.584 8.149a1.206 1.206 0 01-2.367.46l-1.763-9.072a1.206 1.206 0 01.7-1.334c7.331-3.212 11.385-10.943 9.858-18.801A16.93 16.93 0 0031.845 2.548l2.888 14.86c.082.423-.067.858-.392 1.14l-8.213 7.14c-.325.282-.776.37-1.183.23l-10.289-3.544a1.206 1.206 0 01-.79-.91l-2.89-14.86a16.93 16.93 0 00-8.245 17.984c1.528 7.858 8.182 13.509 16.183 13.74a1.205 1.205 0 011.148.976l16.88 86.849a7.896 7.896 0 003.358 5.073 7.894 7.894 0 005.961 1.213c4.303-.836 7.124-5.017 6.287-9.32l-1.164-5.987a1.206 1.206 0 012.368-.46l1.164 5.987c1.09 5.609-2.586 11.058-8.194 12.148a10.49 10.49 0 01-1.999.193"
            fill="#400040"
            mask="url(#b)"
          />
        </g>
        <path
          d="M17.914 130.114l4.714-1.883a5.38 5.38 0 003.079-6.959l-13.245-34.27a5.38 5.38 0 00-6.959-3.08l-4.297 1.662 16.708 44.53z"
          transform="translate(-1378 -141) translate(1378.144 141.699)"
        />
        <path
          d="M17.914 48.969a1.206 1.206 0 01-.448-2.326l4.715-1.884a4.156 4.156 0 002.325-2.21 4.148 4.148 0 00.076-3.194L11.337 5.085a4.148 4.148 0 00-2.205-2.313 4.147 4.147 0 00-3.194-.076L1.64 4.356a1.205 1.205 0 11-.87-2.249L5.068.447a6.543 6.543 0 015.04.12 6.544 6.544 0 013.478 3.648l13.246 34.271a6.544 6.544 0 01-.12 5.04 6.544 6.544 0 01-3.65 3.478l-4.701 1.878a1.204 1.204 0 01-.447.087"
          transform="translate(-1378 -141) translate(1378.144 141.699) translate(0 82.352)"
          fill="#400040"
        />
        <path
          d="M67.573 89.3l21.732-8.306a7.304 7.304 0 10-5.216-13.645l-21.731 8.306A7.304 7.304 0 1067.573 89.3"
          fill="#FFF"
          transform="translate(-1378 -141) translate(1378.144 141.699)"
        />
        <path
          d="M86.7 68.07c-.736 0-1.473.135-2.18.405l-21.732 8.306a6.058 6.058 0 00-3.39 3.208 6.058 6.058 0 00-.129 4.666 6.06 6.06 0 003.208 3.39 6.058 6.058 0 004.666.13l21.731-8.307a6.105 6.105 0 003.52-7.874 6.058 6.058 0 00-3.209-3.39 6.08 6.08 0 00-2.486-.534M64.975 90.988c-3.427 0-6.664-2.085-7.958-5.472-1.676-4.383.527-9.312 4.91-10.987l21.732-8.306a8.454 8.454 0 016.51.18 8.454 8.454 0 014.477 4.73c1.675 4.383-.528 9.312-4.91 10.988l-21.732 8.306-.43-1.127.43 1.127c-.996.38-2.02.561-3.029.561"
          fill="#400040"
          transform="translate(-1378 -141) translate(1378.144 141.699)"
        />
        <path
          d="M64.978 74.654l21.731-8.306a7.304 7.304 0 00-5.216-13.645l-21.731 8.306a7.304 7.304 0 005.216 13.645"
          fill="#FFF"
          transform="translate(-1378 -141) translate(1378.144 141.699)"
        />
        <path
          d="M84.104 53.424c-.736 0-1.473.135-2.18.405l-21.731 8.306a6.058 6.058 0 00-3.39 3.208 6.058 6.058 0 00-.13 4.666 6.059 6.059 0 003.208 3.39 6.058 6.058 0 004.666.129l21.732-8.306a6.058 6.058 0 003.39-3.208 6.058 6.058 0 00.129-4.666 6.059 6.059 0 00-3.208-3.39 6.081 6.081 0 00-2.486-.534zM62.367 76.346a8.489 8.489 0 01-3.47-.745 8.455 8.455 0 01-4.476-4.731 8.454 8.454 0 01.18-6.511 8.454 8.454 0 014.73-4.476l21.732-8.307c4.383-1.675 9.312.528 10.987 4.91a8.454 8.454 0 01-.18 6.512 8.454 8.454 0 01-4.73 4.476l-21.732 8.307a8.505 8.505 0 01-3.041.565z"
          fill="#400040"
          transform="translate(-1378 -141) translate(1378.144 141.699)"
        />
        <path
          d="M69.375 104.24l20.48-7.828a7.304 7.304 0 10-5.215-13.645l-20.48 7.828a7.304 7.304 0 105.215 13.645"
          fill="#FFF"
          transform="translate(-1378 -141) translate(1378.144 141.699)"
        />
        <g transform="translate(-1378 -141) translate(1378.144 141.699) translate(58.253 81.075)">
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path
            d="M10.692 22.039l.43 1.126-.43-1.126 20.48-7.828a6.059 6.059 0 003.39-3.208 6.058 6.058 0 00.129-4.666 6.059 6.059 0 00-3.208-3.39 6.059 6.059 0 00-4.666-.129l-20.48 7.828a6.059 6.059 0 00-3.39 3.208 6.058 6.058 0 00-.129 4.666 6.058 6.058 0 003.208 3.39 6.058 6.058 0 004.666.129m-2.18 2.818a8.488 8.488 0 01-3.47-.745A8.454 8.454 0 01.565 19.38a8.455 8.455 0 01.18-6.511 8.454 8.454 0 014.731-4.476L25.956.566a8.452 8.452 0 016.51.18 8.454 8.454 0 014.477 4.73c1.675 4.383-.527 9.312-4.91 10.988l-20.48 7.827a8.505 8.505 0 01-3.042.566"
            fill="#400040"
            mask="url(#d)"
          />
        </g>
        <path
          d="M40.642 94.67a30.81 30.81 0 005.885-24.187l-.66-3.685L59.7 61.014l13.595-5.196a7.304 7.304 0 10-5.216-13.646L35.138 54.764a25.517 25.517 0 00-14.505 14.17A25.554 25.554 0 0019.43 84.48l-6.916 2.643 12.963 33.572 8.574-3.278a25.534 25.534 0 003.342 1.925 25.515 25.515 0 0019.008 1.151l-9.582-47.97-6.177 22.146z"
          fill="#FFF"
          transform="translate(-1378 -141) translate(1378.144 141.699)"
        />
        <path
          d="M34.051 116.212c.238 0 .473.07.675.206a24.114 24.114 0 003.184 1.834 24.187 24.187 0 0017.095 1.411l-7.451-37.308a32.07 32.07 0 01-5.955 13.048 1.206 1.206 0 01-1.914-1.467 29.69 29.69 0 005.948-21.196 1.188 1.188 0 01-.019-.173 29.459 29.459 0 00-.273-1.871l-.661-3.685c-.1-.557.2-1.107.722-1.326l13.834-5.783.034-.014 13.596-5.196a6.105 6.105 0 003.519-7.874 6.058 6.058 0 00-3.208-3.39 6.06 6.06 0 00-4.666-.13L35.568 55.89a24.398 24.398 0 00-13.82 13.5c-1.924 4.703-2.32 9.826-1.144 14.813a1.206 1.206 0 01-.743 1.403l-5.786 2.211 12.094 31.322 7.452-2.848c.14-.053.285-.08.43-.08m14.262 6.808c-3.914 0-7.81-.868-11.437-2.588a25.884 25.884 0 01-2.973-1.666l-7.996 3.056a1.206 1.206 0 01-1.555-.692L11.39 87.557a1.206 1.206 0 01.694-1.56l5.95-2.274c-1.007-5.151-.5-10.4 1.483-15.246a26.819 26.819 0 0115.19-14.84l32.943-12.59a8.453 8.453 0 016.51.18 8.454 8.454 0 014.477 4.73c1.676 4.383-.527 9.312-4.91 10.987l-13.578 5.19-12.925 5.403.49 2.733c.126.7.227 1.401.305 2.103l9.564 47.884a1.206 1.206 0 01-.801 1.38 26.745 26.745 0 01-8.469 1.382"
          fill="#400040"
          transform="translate(-1378 -141) translate(1378.144 141.699)"
        />
        <path
          d="M72.153 117.757l17.922-6.85A6.827 6.827 0 1085.2 98.152l-17.922 6.85a6.827 6.827 0 104.875 12.754"
          fill="#FFF"
          transform="translate(-1378 -141) translate(1378.144 141.699)"
        />
        <path
          d="M87.631 98.908c-.666 0-1.342.12-2 .371l-17.922 6.85a5.627 5.627 0 00-3.244 7.258 5.627 5.627 0 007.258 3.243l17.922-6.85a5.628 5.628 0 003.243-7.257 5.63 5.63 0 00-5.257-3.615m-17.918 20.509a8.009 8.009 0 01-3.275-.704 7.98 7.98 0 01-4.226-4.465 7.98 7.98 0 01.17-6.146 7.98 7.98 0 014.466-4.225l17.922-6.85c4.137-1.582 8.79.498 10.37 4.635a7.98 7.98 0 01-.169 6.145 7.98 7.98 0 01-4.465 4.226l-17.923 6.85a8.032 8.032 0 01-2.87.534"
          fill="#400040"
          transform="translate(-1378 -141) translate(1378.144 141.699)"
        />
      </g>
    </svg>
  )
}

export function TooltipWhySvg(props) {
  return (
    <svg
      width="5.8rem"
      height="4rem"
      viewBox="0 0 58 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M41.79.5c4.004 0 7.63 1.623 10.253 4.247A14.455 14.455 0 0156.29 15v3.075c0 4.004-1.623 7.63-4.247 10.253a14.455 14.455 0 01-10.253 4.247h-2.427v5.488l-5.37-5.488H15c-4.004 0-7.63-1.623-10.253-4.247A14.455 14.455 0 01.5 18.075V15c0-4.004 1.623-7.63 4.247-10.253A14.455 14.455 0 0115 .5z"
          stroke="#3B3B3B"
          fill="#FFF"
          transform="translate(-1502 -204) translate(1502.865 204.536)"
        />
        <text
          fontFamily="Hellix-Bold, Hellix"
          fontSize={13}
          fontWeight="bold"
          letterSpacing={0.40625}
          fill="#151515"
          transform="translate(-1502 -204) translate(1502.865 204.536)"
        >
          <tspan x={11.5289812} y={21.1114241}>
            {"Why?"}
          </tspan>
        </text>
      </g>
    </svg>
  )
}