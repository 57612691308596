import { PageTitle } from "../../../../styles/components/Layout";
import About from "./about/About";
import HowItWorks from "./howitworks/HowItWorks";
import Targeting from "./targeting/Targeting";
import UseCases from "./useCases/UseCases";

const DataNetwork = () => {
  return (
    <>
      <PageTitle>
        Data <b> network</b>
      </PageTitle>
      <About />
      <HowItWorks />
      <Targeting />
      <UseCases />
    </>
  );
};

export default DataNetwork;
