import React from "react"

function ThankYou() {
    return (
        <svg
            width="100px"
            height="100px"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                transform="translate(-814 -501) translate(814.5 501.55)"
                fill="#4C525A"
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            >
                <path d="M15.469 0v38.554L0 47.036V99h99V47.031l-15.469-8.358V13.23L70.802.597l-.249-.246L70.199 0h-54.73zm3.094 54.995V3.093h49.5v12.375h12.374v39.526L49.502 70.963l-30.94-15.968zM71.156 5.309l7.122 7.066h-7.122V5.309zM4.84 47.91l10.629-5.828v11.315L4.84 47.911zm78.691-5.72l10.609 5.731-10.609 5.476V42.191zM52.874 72.703l43.032-22.211v44.421l-43.032-22.21zM3.094 50.492l43.034 22.211-43.034 22.21V50.492zm46.408 23.951l41.581 21.463H7.917l41.585-21.463z" />
                <path d="M59.5019391 27.0104484L45.0618609 41.4489797 39.9680016 36.3566672 37.7807203 38.5439484 45.0618609 45.8250891 61.6892203 29.1977297z" />
                <path d="M32.461 35.871c0-9.526 7.748-17.274 17.274-17.274 9.524 0 17.273 7.748 17.273 17.274s-7.749 17.274-17.273 17.274c-9.526 0-17.274-7.748-17.274-17.274m37.64 0c0-11.23-9.136-20.368-20.366-20.368-11.232 0-20.368 9.138-20.368 20.368 0 11.23 9.136 20.368 20.368 20.368 11.23 0 20.366-9.138 20.366-20.368" />
            </g>
        </svg>
    )
}

export default ThankYou