//Core
import React, { useState } from "react";
//Styles
import { Stack } from "@mui/material";
import {
  BrandAnalysisTooltipTitle,
  BrandAnalysisTooltipInput,
  BrandAnalysisTooltipPlayButton
} from "./otherSectionsTooltip.styles";
import { FlexResponsive } from "../../../../../styles/components/Layout";
//Images
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export const OtherSectionsTooltip = ({handleButton}) => {
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);

  const sendData = async () => {
    setLoading(true);
    await handleButton(input);
    setLoading(false);
  }

  return (
    <Stack>
      <BrandAnalysisTooltipTitle>
        <span>{"We are glad that you help to improve our product."}</span>
      </BrandAnalysisTooltipTitle>
      <BrandAnalysisTooltipTitle>
        {"Web3m team will gladly consider your proposal and will do everything possible to bring it to life as soon as possible."}
      </BrandAnalysisTooltipTitle>
      <FlexResponsive h={"fit-content"}>
        <BrandAnalysisTooltipInput
          variant="outlined"
          value={input}
          onChange={e => setInput(e.target.value)}
          placeholder={"Add parameter you would like to see here..."}
          onKeyDown={async (e) => e.key === "Enter" && await sendData()}
        />
        <BrandAnalysisTooltipPlayButton
          loading={loading}
          disabled={input.trim() === ""}
          onClick={sendData}
        >
          <PlayArrowIcon id={'icon'}/>
        </BrandAnalysisTooltipPlayButton>
      </FlexResponsive>
    </Stack>
  );
}