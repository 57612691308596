import { Box, Stack, styled } from "@mui/material";
import { mobileScreen, smallMobileScreen } from "../../../../../utils/themeBreakpoints";

export const EcosystemCard = styled(Stack)`
  border-radius: 2.3rem;
  box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.08);
  padding: 6.4rem 6.8rem;
  align-items: center;
  height: 100%;

    ${smallMobileScreen}{
  max-width: 40rem;
        margin: 0 auto;
    }

  svg {
    //display: block;
    margin-bottom: 5rem;
  }

  p {
    font-size: 2.9rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -0.31px;
    text-align: center;
    color: #3d5f78;
    
    ${mobileScreen}{
      max-width: 30rem;
    }
  }
`;
