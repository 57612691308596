export const fetchItems = {
  brand_profile: "brand_profile",
  general_info: "general_info",
  keywords_searches: "keywords_searches",
  competitors: "competitors",
  social_scraper: "social_scraper",
  general_twitter_info: "general_twitter_info",
  feedback: "feedback",
  feedback_lap: "feedback_lap",
  no_content: "no_content",
}

export const fetchSectionsItems = {
  brand_description: fetchItems.brand_profile,
  general_info: fetchItems.general_info,
  google_searches: fetchItems.keywords_searches,
  competitors: fetchItems.competitors,
  organic_growth: fetchItems.general_twitter_info,
  potential_users: fetchItems.social_scraper,
  community_sentiment: fetchItems.general_twitter_info,
}