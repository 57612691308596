import { Stack, styled } from "@mui/material";
import { FlexBox, SectionBackground } from "../../../../styles/components/Layout";
import { mobileScreen } from "../../../../utils/themeBreakpoints";


export const AdvantagesBackground = styled(SectionBackground)`
  background-color: #fafafa;
`

export const AdvantagesList = styled(Stack)`

  row-gap: 15rem;
`

export const AdvantagesListItem = styled(FlexBox)`
  column-gap: 9rem;

  h6, p{
    color: #1E1E1E;
  }
  
  ${mobileScreen}{
    flex-direction: column !important;
    
    h6, p{
      text-align: center;
      width: 90%;
      margin: 0 auto 5rem;
    }
    
  }
  
  &:nth-of-type(even){
    flex-direction: row-reverse;
  }
  
  .image{
    flex: 0 0 60%;
  }
  
`