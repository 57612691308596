import { CommunityNftBg, CommunityNftContent, CommunityNftOuterContainer } from "./Community.styles";
import { Box, Typography } from "@mui/material";
import RabbitCards from "./img/Community-cards.webp";
import RabbitCardsMob from "./img/Community-mob.webp";
import { CTAButtonNFT } from "../hero/HeroNft.styles";
import { RabbitLogo } from "../../../../assets/svg/logo";
import { useLocation, useNavigate } from "react-router-dom";

const CommunityNft = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <CommunityNftOuterContainer>
      <CommunityNftContent>
        <CTAButtonNFT startIcon={<RabbitLogo />} className={"mobileOnly"} onClick={() => navigate("/signup" + search)}>
          Apply for early access
        </CTAButtonNFT>

        <Typography variant={"h2"}>Join the network of marketers at the bleeding edge of Web3</Typography>
        <Typography>
          Web3m is launching a limited collection of NFT memberships for marketers. NFT members will receive full access to the Web3m
          advertising tool suite and membership to our community for Web3 marketers and entrepreneurs.
        </Typography>
        <Box mb={{ xs: "8rem", md: "5rem" }}>
          <img src={RabbitCards} alt={"rabbits"} className={"desktopOnly"} />
          <img src={RabbitCardsMob} alt={"rabbits"} className={"mobileOnly"} />
        </Box>
        <Typography variant={"h2"}>Combining premier ad tech with a thriving Web3 community</Typography>
        <Typography>
          Web3m is launching a limited NFT collection for marketers. NFT owners will receive early access to the powerful Web3m advertising
          tool suite and membership to our exclusive community for Web3 marketers and entrepreneurs.
        </Typography>
      </CommunityNftContent>
      <CommunityNftBg />
    </CommunityNftOuterContainer>
  );
};

export default CommunityNft;
