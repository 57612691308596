import { useEffect, useRef } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useCalendlyEventListener } from "react-calendly";
import { sendFormData } from "../../../../services/web3Service";
import { pathWithUtm } from "../../../../utils/pathWithUtm";

const defaultInput = {
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  hearAboutUs: "",
};

export const useSignUpForm = () => {
  const [input, setInput] = useState(defaultInput);
  const [errors, setErrors] = useState([]);
  const [step, setStep] = useState("form");
  const [isValid, setIsValid] = useState(false);
  const [mouseFlowId, setMouseFlowId] = useState("");
  const [sendingForm, setSendingForm] = useState(false);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const { search } = useLocation();

  const navigate = useNavigate();

  useCalendlyEventListener({
    onEventScheduled: () => {
      navigate(pathWithUtm("/signup/done"), { state: "sent" });
    },
  });

  const schema = yup.object().shape({
    firstName: yup.string().required("firstName"),
    lastName: yup.string().required("lastName"),
    email: yup.string().email().required("email"),
    company: yup.string().required("company"),
    hearAboutUs: yup.string().required("hearAboutUs"),
  });

  const autocompleteRef = useRef();

  const handleType = async (e) => {
    if (errors.includes(e.target.name)) {
      setErrors(errors.filter((el) => el !== e.target.name));
    }
    console.log(e);
    setInput((input) => ({ ...input, [e.target.name]: e.target.value }));
  };

  const handleSendForm = async () => {
    const hearAboutUs = autocompleteRef.current?.value;
    try {
      setSendingForm(true);
      await schema.validate({ ...input, hearAboutUs }, { abortEarly: false });
      setErrors([]);
      const formData = { ...input, session_id: mouseFlowId };
      if (search) formData.utm_source = search.split("=")[1];
      await sendFormData({ ...formData, hearAboutUs });
      setInput(defaultInput);
      setStep("calendly");
      // navigate("/signup/done", { state: "sent" });
      // setSent(true);
    } catch (err) {
      setErrors(err.errors);
    } finally {
      setSendingForm(false);
    }
  };

  useEffect(() => {
    const validateInput = async () => {
      try {
        await schema.validate(input);
        if (!isValid) setIsValid(true);
      } catch (err) {
        if (isValid) setIsValid(false);
      }
    };

    void validateInput();
  }, [input]);

  useEffect(() => {
    if (!window.mouseflow) return;
    setMouseFlowId(window.mouseflow.getSessionId());
  }, [window.mouseflow]);

  return { input, handleType, errors, setErrors, handleSendForm, isValid, sendingForm, step, autocompleteRef, autocompleteOpen, setAutocompleteOpen };
};
