import { gatewayRequestHandler } from "./gatewayServiceHandler";
import { fetchItems } from "../utils/fetchItems";

export const sendFeedback = async (data, path=fetchItems.feedback) => {
  try {
    const response = await gatewayRequestHandler(path, data);
    return response?.data ?? null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const sendNoContent = async (data) => {
  const args = {
    field: data
  }
  try {
    const response = await gatewayRequestHandler(fetchItems.no_content, args);
    return response?.data ?? null;
  } catch (error) {
    console.log(error);
    return null;
  }
}
