import { Box, styled } from "@mui/material";
import { FlexBox, SectionBackground } from "../../../../styles/components/Layout";
import { mobileScreen, smallMobileScreen } from "../../../../utils/themeBreakpoints";

export const PromoteBackground = styled(SectionBackground)`
  background-image: linear-gradient(269deg, #fcfcfc, #f7f7f7);
`;

export const PromoteList = styled(FlexBox)`
  height: 47rem;
  width: 102.5%;
  margin-left: -2.5%;

  ${mobileScreen} {
    display: none;
    height: 105rem;
    row-gap: 7rem;
    flex-wrap: wrap;
  }
`;

export const PromoteListItem = styled(FlexBox)`
  flex: 1 1 20%;
  height: 100%;
  flex-direction: column;
  align-self: flex-start;
  row-gap: 2.5rem;

  ${mobileScreen} {
    height: 50rem;
    flex: 1 1 22%;
  }
  
  ${smallMobileScreen}{
    height: 55rem;
  }
  

  &:nth-of-type(even) {
    align-self: flex-end;
    flex-direction: column-reverse;
  }

  p {
    width: 140%;
    white-space: pre-line;

    font-size: 3.3rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.35px;
    text-align: center;
    color: #414243;
  }
  svg {
    width: 13rem;
    height: fit-content;
  }
`;
