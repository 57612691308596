import About from "./about/About";
import Advantages from "./advantages/Advantages";
import WhatFor from "./whatFor/WhatFor";
import Ecosystem from "./ecosystem/Ecosystem";
import { PageTitle } from "../../../../styles/components/Layout";

const AdNetwork = () => {
  return (
    <div>
      <PageTitle>Ad <b> network</b></PageTitle>
      <About />
      <Ecosystem />
      <Advantages />
      <WhatFor />
    </div>
  );
};

export default AdNetwork;
