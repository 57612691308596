import { styled } from "@mui/material";
import { FlexBox } from "../../../../styles/components/Layout";
import { smallMobileScreen } from "../../../../utils/themeBreakpoints";
import theme from "../../../../styles/theme";

export const FAQTitleContainer = styled(FlexBox)`
  //margin: 7.7rem 10rem 7.7rem 0;
  
  .img-box{
    width: 33%;
    transform-origin: left;
    transform: scale(3) translateY(-2rem);
  }
  
  h2{
    width: 80%;
    
    ${smallMobileScreen}{
      width: 100%;
    }
  }

  ${theme.breakpoints.down("md")} {
    
    > div{
      width: 100%;
    }
   
    h2{
      max-width: 65rem;
      margin: 0 auto 10rem;
    }
  }

  ${theme.breakpoints.down("sm")} {
    margin-top: 2rem;
    p {
      font-size: 2.3rem;
    }
    h1 {
      font-size: 3.3rem;
    }

    svg{
      max-width: 10rem !important;
      transform: translateY(140%);

    }
  }

  // ${smallMobileScreen}{
  //   margin-bottom: 7rem;
  // }
`;