import { Box, styled } from "@mui/system";
import theme from "../../../../../styles/theme";
import { Stack, Typography } from "@mui/material";
import { FlexBox } from "../../../../../styles/components/Layout";
import { mobileScreen } from "../../../../../utils/themeBreakpoints";

export const SectionContainer = styled(Box)`
  position: relative;
  width: 90%;
  margin: 0 auto;
  max-width: 163.5rem;

  ${theme.breakpoints.down("xl")} {
    width: 95%;
  }
`;
export const SectionWrapper = styled(SectionContainer)`
  position: relative;
  margin: 0 auto 0;
  border-radius: 1rem;
  background-color: #fff;
  padding: 8.72rem 5.4rem 7rem 5.9rem;
  /* margin-bottom: 10rem; */

  ${theme.breakpoints.down("xl")} {
    padding: 6rem 3rem 6rem 5rem;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    padding: 0 0 5rem 0;
    border-radius: 0;
    background-color: transparent;
    margin-bottom: 0;
  }

  ${theme.breakpoints.down("br480")} {
    display: flex;
    flex-direction: column;
    //min-height: calc(100vh - 2rem);
  }
`;

export const SectionTitle = styled(Box)`
  margin-bottom: 12.5rem;

  h2 {
    font-family: "Hellix", sans-serif;
    font-size: 2.8rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.31px;
    color: #455871;
    margin-bottom: 1rem;
  }

  h6 {
    //font-family: Hellix;
    font-size: 2.2rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.24px;
    color: #4a5767;
    max-width: 90rem;
    width: 90%;
  }
 ${mobileScreen} {
   margin-bottom: 10rem;
 }
  ${theme.breakpoints.down("sm")} {
    background-color: #fff;
    padding: 4rem 3rem;
    margin-bottom: 0;
  }
`;

export const SectionBlock = styled(Stack)`
  position: relative;
  border-top: 1px solid #c1c4c9;
  border-radius: 0 0 0.9rem 0.9rem;
  padding: 3.5rem 3rem 5rem;
  margin-bottom: 9rem;
  height: fit-content;
  box-shadow: 0 2rem 1.2rem 0 rgba(100, 100, 100, 0.03);

  &.loading {
    //background-color: rgba(221, 221, 221, 0.2);
    box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.03);
  }
  &.skeleton {
    background-image: linear-gradient(163deg, #fff 3%, #f4f3f3 31%);
    box-shadow: unset;
  }

  ${theme.breakpoints.down("sm")} {
    background-color: #fff;
    padding-top: 10rem;
    padding-bottom: 3rem;
    border-top: none;
    margin-bottom: 2rem;
    border-radius: 2.5rem;
  }

  ${theme.breakpoints.down("br480")} {
    // width: 90%;
    // margin: 0 auto;

    border-radius: 2.5rem;
    //min-height: 55vh;
    &.mobilePopup {
      min-height: 0;
      box-shadow: unset;
      padding: 0;
      border-radius: 0;
      background-color: unset;
    }
    &.channel {
      background-color: #677194;
    }
    &.accordion {
      padding: 8rem 0 5rem;
      // min-height: auto
    }
    &.accordion-details {
      padding: 0 1.5rem 0;
      background-color: unset;
      min-height: unset;
      margin-bottom: 0;
    }
  }
`;

export const SectionBlockTitle = styled(Typography)`
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.22px;
  text-transform: uppercase;
  color: #a5aab5;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: -0.2rem;
  padding-right: 2rem;
  transform: translate(0, -50%);
  cursor: pointer;

  ${theme.breakpoints.down("sm")} {
    transform: none;
    top: 4rem;
    left: 4rem;
    width: 100%;
    background-color: unset;
  }
  ${theme.breakpoints.down("br480")} {
    display: inline-flex;
    align-items: flex-start;
    flex-shrink: 0;
    font-size: 1.8rem;
    top: 3rem;
    left: 3rem;
    max-width: 70%;
    background-color: transparent;
    //text-transform: capitalize;
    svg {
      width: 2.2rem;
      height: 2.2rem;
      margin-right: 0.7rem;
    }
    span {
      font-weight: 600;
      font-size: 2.16rem;
      color: #ffffff;
      line-height: normal;
      letter-spacing: 0.24px;
      text-transform: uppercase;
      margin-right: 0.7rem;
    }
  }
`;

export const SectionTitleBackground = styled(Box)`
  ${theme.breakpoints.down("sm")} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10rem;
    opacity: 0.26;
    background-image: linear-gradient(213deg, #ECEDF2 0%, #D5D7E0 100%);
    border-top-left-radius: 0.9rem;
    border-top-right-radius: 0.9rem;
  }
`;

export const VerticalDivider = () => {
  const StyledDivider = styled("span")`
    margin: 0 1rem;
    font-weight: normal !important;
  `;

  return <StyledDivider> | </StyledDivider>;
};

export const SectionDescription = ({ children, ...otherProps }) => {
  const StyledText = styled(Typography)`
    font-size: 1.4rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.16px;
    color: #091b32;
    margin-bottom: 2rem;
    margin-top: 1rem;
  `;

  return (
    <StyledText
      variant="body2"
      marginTop={{ xs: "3rem", sm: "1rem" }}
      width={{ xs: "90%", md: "75%" }}
      whiteSpace={"pre-line"}
      {...otherProps}
    >
      {children}
    </StyledText>
  );
};

export const ArrowExpanded = styled(FlexBox)`
  position: absolute;
  right: 3rem;
  top: 4rem;
  cursor: pointer;
  //transform: translateY(-50%);
  
  svg {
    width: 3.4rem;
    height: 3.4rem;
  }
  
  ${theme.breakpoints.down("sm")} {
    opacity: 0.7;
    top: 3rem;
  }
`
