import {useCallback} from "react";
import { useDispatch } from "react-redux";
import { addErrorSnack, addInfoSnack, addSuccessfulSnack } from "../../redux/snack/snackSlice";


export const useSnack = () => {
    const dispatch = useDispatch();
    const snack = {
        info: (message) => dispatch(addInfoSnack(message)),
        error: (message) => dispatch(addErrorSnack(message)),
        success: (message) => dispatch(addSuccessfulSnack(message)),
    }
    return useCallback((message, variant = 'info') => snack[variant](message)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);
}
