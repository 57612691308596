import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import snackSlice from "./snack/snackSlice";
import appSlice from "./app/appSlice";

export const rootReducer = combineReducers({
  app: appSlice,
  snack: snackSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});
