import { styled } from "@mui/system";
import { FlexBox } from "../../../styles/components/Layout";
import theme from "../../../styles/theme";

export const SignUpBodyContainer = styled(FlexBox)`
  align-items: flex-start;
  ${theme.breakpoints.down("md")} {
    flex-direction: column;
    /* align-items: center; */
  }
`;


