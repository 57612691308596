import React, { Fragment } from "react";
import { Autocomplete, capitalize, FormControl, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import { NFTButton } from "../../../../styles/components/buttons";
import { FormAutocomplete, FormAutocompleteOption, FormError, SignUpFormContainer, SignupFormControl } from "./SignUpForm.styles";
import { useSignUpForm } from "./useSignUpForm";
import { InlineWidget } from "react-calendly";
import { formFields, sourceOptions } from "./SignUp.data";
import { FlexBox } from "../../../../styles/components/Layout";
import { AlertSvg } from "../../../../assets/svg/buttonIcons";
import { CloseIcon, OpenIcon } from "./img/SignUp.icons";
import { logDOM } from "@testing-library/react";
// import { CloseIcon, OpenIcon } from "./img/SignUp.icons";

const SignUpForm = () => {
  const {
    input,
    errors,
    setErrors,
    isValid,
    handleType,
    handleSendForm,
    sendingForm,
    step,
    autocompleteRef,
    autocompleteOpen,
    setAutocompleteOpen,
  } = useSignUpForm();
  return (
    <SignUpFormContainer>
      {/*<Widget*/}
      {/*  id={"NDMwcqGv"}*/}
      {/*  hideHeaders*/}
      {/*  autoResize*/}
      {/*  disableScroll*/}
      {/*  style={{ width: "100%", height: "500px" }}*/}
      {/*></Widget>*/}
      {step === "form" && (
        <form>
          {formFields.map((field) => (
            <SignupFormControl key={field.id} className={errors.includes(field.fieldName) ? "error" : ""}>
              <FlexBox justifyContent={"space-between"}>
                <InputLabel shrink htmlFor={field.id}>
                  {field.title}
                  {field.mandatory && "*"}
                </InputLabel>
                <FormError className={"required"}>
                  <AlertSvg /> <Typography>This field is required </Typography>{" "}
                </FormError>
              </FlexBox>
              <OutlinedInput
                id={field.id}
                name={field.fieldName}
                value={input[field.fieldName]}
                onChange={handleType}
                disabled={sendingForm}
                placeholder={"Type here..."}
              />
            </SignupFormControl>
          ))}
          <SignupFormControl className={errors.includes("hearAboutUs") ? "error" : ""}>
            <FlexBox justifyContent={"space-between"}>
              <InputLabel shrink htmlFor={"source"}>
                HOW DID YOU HEAR ABOUT US?*
              </InputLabel>
              <FormError className={"required"}>
                <AlertSvg /> <Typography>This field is required </Typography>{" "}
              </FormError>
            </FlexBox>
            <FormAutocomplete
              id={"source"}
              disablePortal
              freeSolo
              open={autocompleteOpen}
              onOpen={() => setAutocompleteOpen(true)}
              onClose={() => setAutocompleteOpen(false)}
              onChange={(e) => setErrors(errors.filter((err) => err !== "hearAboutUs"))}
              clearIcon={<Typography className={"reset"}>Reset</Typography>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={autocompleteRef}
                  placeholder={"Select from the list or type for other"}
                  onChange={(e) => e.target.value && setErrors(errors.filter((err) => err !== "hearAboutUs"))}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: autocompleteOpen ? (
                      <CloseIcon onClick={() => setAutocompleteOpen(!autocompleteOpen)} />
                    ) : (
                      <OpenIcon onClick={() => setAutocompleteOpen(!autocompleteOpen)} />
                    ),
                  }}
                />
              )}
              renderOption={(params, option) => <FormAutocompleteOption {...params}>{option}</FormAutocompleteOption>}
              ListboxProps={{ sx: { backgroundColor: "#97a0c3" } }}
              options={sourceOptions}
            />
          </SignupFormControl>

          {/*{errors.map((el, i) => (*/}
          {/*  <FormError key={el + i}>*{capitalize(el)}</FormError>*/}
          {/*))}*/}
          <NFTButton onClick={handleSendForm} loading={sendingForm}>
            Get early access
          </NFTButton>
          <Typography>Sign up and our team will be contacting you in no time!</Typography>
          <Typography className={"alert"}>* Required field</Typography>
        </form>
      )}
      {/*<PopupButton url={"https://calendly.com/erichiteweb3m/platform"} text={"calendly"} rootElement={document.getElementById("root")} />*/}
      {step === "calendly" && <InlineWidget url={"https://calendly.com/web3m/platform"} />}
    </SignUpFormContainer>
  );
};

export default SignUpForm;
