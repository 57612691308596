import { FlexBox, SectionContainer } from "../../../../../styles/components/Layout";
import { SectionMainTitle } from "../../../../../styles/components/typography";
import Grid2 from "@mui/material/Unstable_Grid2";
import { targetingList } from "./Targeting.data";
import { Dot, ExtraFeature, TargetingCard, TargetingCardHead, TargetingCardList } from "./Targeting.styles";
import { ListItem, Typography, ListItemIcon, ListItemText } from "@mui/material";

const Targeting = () => {
  return (
    <SectionContainer p={"13rem 0"}>
      <SectionMainTitle mb={"9rem"}>
        <b> Web3 marketers </b> <br /> deserve better data
      </SectionMainTitle>
      <Grid2 container spacing={"10rem"}>
        {targetingList.map((el) => (
          <Grid2 xs={12} md={6} key={el.title}>
            <TargetingCard>
              <TargetingCardHead>
                {el.icon}
                <Typography>{el.title}</Typography>
              </TargetingCardHead>
              <TargetingCardList>
                {el.features.map((feature) => (
                  <ListItem key={feature}>
                    <ListItemIcon>
                      <Dot />
                    </ListItemIcon>
                    <ListItemText>{feature}</ListItemText>
                  </ListItem>
                ))}
              </TargetingCardList>
              {el.extraFeatures && <ExtraFeature> <Dot className={'white'}/>+ More than 100 of additional features</ExtraFeature>}
            </TargetingCard>
          </Grid2>
        ))}
      </Grid2>
    </SectionContainer>
  );
};

export default Targeting;
