import { useContext, useEffect, useState } from "react";
import { socialMediaIcons } from "../../shared/icons/socialMedia";
import { BrandInfoContext } from "../../../../../context/BrandInfoContext";

export const useGeneralInfo = () => {
  const { fetchList, brandProfile, generalInfo, socialMedia } = useContext(BrandInfoContext);

  const [existingFields, setExistingFields] = useState([]);
  const [missingFields, setMissingFields] = useState([]);

  const getCommonEngagementRate = () => {
    if (!socialMedia) {
      return null;
    }

    let counter = 0;
    const sum =
      socialMedia &&
      Object.values(socialMedia).reduce((acc: number, next: any) => {
        if (next?.engagement_rate) {
          counter++;
          return acc + next.engagement_rate;
        } else {
          return acc;
        }
      }, 0);

    if (!counter) {
      return null;
    }
    return Math.round((sum / counter) * 100) / 100 + "%";
  };

  const fieldTitleList = {
    foundation_year: {
      title: "Company founded in",
      data: generalInfo?.foundation_year,
    },
    type: { title: "Brand Type", data: brandProfile?.type },
    social_media: {
      title: "Social Media",
      data: brandProfile?.social_media?.filter((el: TSocialMedia) => el.platform_name !== "discord"),
    },
    white_paper: {
      title: "White Paper",
      data: "Whitepaper",
      link: brandProfile?.white_paper,
      icon: socialMediaIcons.whitepaper,
    },
    discord: {
      title: "Discord Community",
      data: "Discord",
      link: brandProfile?.social_media?.find((el: TSocialMedia) => el.platform_name === "discord")?.link,
      icon: socialMediaIcons.discord,
    },
    budget: { title: "Monthly ad budget", data: null },
    engagement: {
      title: "Common Engagement rate",
      data: getCommonEngagementRate(),
    },
    founders: { title: "Founders", data: generalInfo?.founders },
    investments: {
      title: "Fundraising",
      data: generalInfo?.investments,
    },
    mentions_on_web: {
      title: "Web Mentions",
      data: generalInfo?.mentions_on_web,
    },
    publications: { title: "Publications", data: generalInfo?.publications },
  };

  const sortData = () => {
    const exist = [];
    const missing = [];

    // Brand Profile
    if (brandProfile?.type) {
      exist.push(fieldTitleList.type);
    } else {
      missing.push("type");
    }

    if (brandProfile?.social_media?.length) {
      exist.push(fieldTitleList.social_media);
    } else {
      missing.push("social_media");
    }

    if (brandProfile?.social_media?.find((el) => el.platform_name === "discord")) {
      exist.push(fieldTitleList.discord);
    } else {
      missing.push("discord");
    }
    if (brandProfile?.white_paper) {
      exist.push(fieldTitleList.white_paper);
    } else {
      missing.push("white_paper");
    }

    if (getCommonEngagementRate()) {
      exist.push(fieldTitleList.engagement);
    } else {
      missing.push("engagement");
    }

    // General Info
    const generalInfoKeys = ["foundation_year", "founders", "investments", "mentions_on_web", "publications"];
    for (const key of generalInfoKeys) {
      if (generalInfo && generalInfo[key]?.length) {
        exist.push(fieldTitleList[key]);
      } else {
        missing.push(key);
      }
    }
    setExistingFields(exist);
    setMissingFields(missing);
  };

  useEffect(() => {
    if (!generalInfo || !brandProfile) return;
    sortData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalInfo, brandProfile]);

  return { existingFields, missingFields, fieldTitleList };
};
