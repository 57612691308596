//Core
import React, { Fragment, useContext } from "react";
//Styles
import {
  ArrowExpanded,
  SectionBlock,
  SectionBlockTitle,
  SectionDescription, SectionTitleBackground,
  VerticalDivider
} from "../../shared/styles/layout";
import SectionBlockWithLoader from "../../shared/components/loaders/SectionBlockWithLoader";
import { DataCard, PotentialUsersBasedOn, PotentialUsersDataCards } from "./PotentialUsers.styles";
import { Typography } from "@mui/material";
//Images
import { socialMediaIcons } from "../../shared/icons/socialMedia";
import RemoveIcon from "@mui/icons-material/RemoveOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";
//Utils
import { getFollowers, getPotentialUsers } from "./PotentialUsers.data";
import { getStringNumber } from "../../shared/data/getStringNumber";
import { fetchItems } from "../../../../../utils/fetchItems";
import { sectionOpenItems } from "../../../../shared/brandAnalysis/help/sectionLabel";
//Context
import { BrandInfoContext } from "../../../../../context/BrandInfoContext";

const PotentialUsers = () => {
  const {
    fetchList,
    socialMedia,
    sectionOpen,
    handleSectionOpen,
    brandSectionsList,
  } = useContext(BrandInfoContext);

  const isLoading = fetchList.includes(fetchItems.brand_profile) || fetchList.includes(fetchItems.social_scraper);

  const description =
    "Based on your current social media followers, this is the number of estimated potential users and customers who are already in your network. We create this estimate based on your most engaged followers.";

  if ((!isLoading && !socialMedia) || !brandSectionsList.includes(sectionOpenItems.potential_users)) return null;



  return (
    <SectionBlock className={isLoading ? "loading" : ""}>
      <SectionTitleBackground/>
      <SectionBlockTitle onClick={() => !isLoading && handleSectionOpen(sectionOpenItems.potential_users)}>
        {"Potential users"}
    </SectionBlockTitle>
      <SectionDescription>{description}</SectionDescription>
      <SectionBlockWithLoader loading={isLoading}>
        {(sectionOpen === sectionOpenItems.potential_users) &&
          <>
            <PotentialUsersDataCards>
              <DataCard>
                <Typography variant="h4">{"Potential Users:"}</Typography>
                <Typography variant="h2">{getStringNumber(getPotentialUsers(socialMedia))}</Typography>
              </DataCard>

              <DataCard>
                <Typography variant="h4">{"Followers:"}</Typography>
                <Typography variant="h2">{getStringNumber(getFollowers(socialMedia)?.num || 0)}</Typography>
              </DataCard>
            </PotentialUsersDataCards>

            <PotentialUsersBasedOn variant="h4">
              {"Based on:"}
              {getFollowers(socialMedia)?.mediaList?.map((el, i, arr) => (
                <Fragment key={el + i}>
                  <span>{socialMediaIcons[el]} {el}</span>
                  {i !== arr.length - 1 && <VerticalDivider />}
                </Fragment>
              ))}
              {"analysis"}
            </PotentialUsersBasedOn>
          </>
        }
          <ArrowExpanded onClick={() => !isLoading && handleSectionOpen(sectionOpenItems.potential_users)}>
            {(sectionOpen === sectionOpenItems.potential_users)
              ? <RemoveIcon />
              : <ExpandMoreIcon />
            }
          </ArrowExpanded>
      </SectionBlockWithLoader>
    </SectionBlock>
  );
};

export default PotentialUsers;
