import { AppBar, Box, Button, ListItem, Tooltip, styled, tooltipClasses, Menu } from "@mui/material";
import { FlexBox } from "../../../styles/components/Layout";
import theme from "../../../styles/theme";
import React from "react";
import { mobileScreen } from "../../../utils/themeBreakpoints";

export const HeaderContainer = styled(AppBar)`
  position: fixed;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  transition: 0.3s;
  z-index: 1100;
  // ${theme.breakpoints.down("sm")} {
  //   padding-top: 0.5rem;
  // }
  > div > div {
    ${theme.breakpoints.down("sm")} {
      max-width: 100%;
      width: 100%;
    }
  }
`;

export const HeaderNavBox = styled(FlexBox)`
  width: 100%;
  justify-content: space-between;
  margin: 0 5.2rem;

  ${theme.breakpoints.between("md", "lg")} {
    margin: 0 1rem;
  }

  ${theme.breakpoints.down("sm")} {
    margin: 0 1.5rem;
  }
  > a {
    display: flex;
  }
  > a > svg {
    width: 13rem;
    ${theme.breakpoints.down("sm")} {
      //width: 9rem;
      margin-left: 0.5rem;
    }
  }
  ul {
    ${theme.breakpoints.down("md")} {
      display: none;
    }
  }

  &.white-theme {
    svg {
      path {
        fill: #00213a;
      }
    }
    a,
    p {
      color: #00213a;
    }
    button {
      border: 1px solid #00213a;
    }
  }

  &.white-blue {
    svg {
      path {
        fill: #0e61d5;
      }
    }
    a,
    p {
      color: #0f3987;
      font-weight: normal;
    }
    button {
      border: 1px solid #0f3987;
    }
  }

  &.brand-info {
    svg {
      path {
        fill: #0e61d5;
      }
    }
    ul {
      display: none;
    }
    
    .create-campaign{
      display: block;
    }
  }
`;

export const HeaderNavItem = styled(ListItem)`
  white-space: nowrap;
  padding: 1rem 2.8rem;
  /* margin-left: 3.2rem; */

  ${theme.breakpoints.down("xl")} {
    padding: 1rem 2rem;
  }

  @media only screen and (max-width: 1350px) {
    padding: 1rem 1.8rem;
    p,
    button {
      font-size: 1.8rem;
    }
  }

  ${theme.breakpoints.down("lg")} {
    p,
    button {
      font-size: 2rem;
    }
  }

  @media only screen and (max-width: 1050px) {
    padding: 1rem 1.6rem;
    p,
    button {
      font-size: 1.7rem;
    }
  }

  a {
    text-decoration: none;
    color: #fff;
  }
  &.active {
    //opacity: 0.5;
    color: #53baff !important;
    p {
      color: #53baff !important;
      text-decoration: underline;
    }
    ,
    & button {
      border-color: #53baff !important;
      p {
        text-decoration: none;
      }
    }
  }
  p {
    cursor: pointer;
  }
`;

export const BurgerButton = styled(Box)`
  display: none;
  height: 19px;
  width: 19px;
  position: relative;
  cursor: pointer;
  
  &.brand-info{
    display: none;
  }
  
  ${theme.breakpoints.down("md")} {
    display: block;
  }
  b {
    position: absolute;
    width: 25px;
    height: 2px;
    background-color: #fff;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 50%;
    }
    &:nth-of-type(3) {
      top: 100%;
    }
  }
  ${theme.breakpoints.down("sm")} {
    width: 15px;
    height: 15px;
    margin-right: 1rem;
    b {
      width: 20px;
    }
  }
  &.dark,
  &.float {
    b {
      background-color: #00213a;
    }
  }
  &.float {
    margin-right: 5rem;
  }

  &.white-blue {
    b {
      background-color: #0f1c4f;
    }
  }
`;

//Tooltip User
export const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  paddingTop: "1.7rem !important",
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    maxWidth: 540,
    width: "fit-content",
    fontSize: "1.4rem",
    borderRadius: "1.4rem",
    padding: "4rem 7rem 4rem 3rem",
    boxShadow: "0 5px 17px 0 rgb(0 0 0 / 17%)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFFFF",
  },
}));

export const RequestDemoButton = styled(Button)`
  padding: 0.9rem 2.1rem;
  border-radius: 7px;
  border: solid 1px #fff;
  text-transform: capitalize;
  color: #fff;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: -0.21px;
`;

export const LoginButton = styled(RequestDemoButton)`
  background-color: #fff;
  border: 1px solid #fff;
  color: #1b418e;
  margin: 0 -2rem;

  &:hover {
    background-color: #fff;
    color: #1b418e;
  }

  &.white-blue {
    background-color: #0f143f;
    border-color: #0f143f;
    color: #fff;
  }

  ${mobileScreen} {
    margin: -2rem 0;
    color: #000;
    font-size: 3rem;
    background-color: #fff !important;
    border: 1px solid #000;
  }
`;

export const ButtonDropMenu = styled(Button)`
  color: #ffffff;
  text-transform: capitalize;
`;

export const StyledMenu = styled((props) => (
  <Menu
    autoFocus={false}
    // elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 100,
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      width: "100%",
      padding: ".3rem",
      "& a": {
        color: "#00213a",
        width: "100%",
        padding: "4px 10px",
      },
      "&:active a": {
        backgroundColor: "rgba(0,33,58,0.1)",
      },
    },
  },
}));

export const CreateCampaignButton = styled(Button)`
  display: none;
  position: relative;
  font-size: 2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-transform: none;
  line-height: 1.25;
  letter-spacing: -0.21px;
  color: #fff;
  white-space: nowrap;
  min-width: auto;

  border-radius: 0.7rem;
  background-color: #0080ff;
  padding: 1rem 2.5rem;

  &:hover {
    background-color: #0080ff;
  }

  i {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(25%, -50%);
    border-radius: 3px;
    border: solid 1px rgba(0, 128, 255, 0.21);
    background-color: #fff;
    padding: 0.2rem 1rem;

    font-size: 1.3rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    //line-height: 1.92;
    letter-spacing: -0.14px;
    color: #0e70da;
  }
`;
