import { SocialMediaIcons } from "../../../assets/svg/socialMedia";
import { MediaButton, SocialMediaContainer } from "./SocialMedia.styles";
import { socialMediaList } from "./SocialMedia.data";

export const SocialMedia = () => {
  return (
    <SocialMediaContainer>
      {Object.entries(socialMediaList).map(([name, link]) => (
        <MediaButton key={name} startIcon={SocialMediaIcons[name]} onClick={() => window.open(link)}>
          {name}
        </MediaButton>
      ))}
    </SocialMediaContainer>
  );
};
