// import { FAQMobSvg, FAQSvg } from "../../../assets/svg/faq";
import { ImgBox, SectionContainer } from "../../../../styles/components/Layout";
import { FAQTitleContainer } from "./FAQTItle.styles";
import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import faqTitleImg from "./img/faq.webp";
import { SecondaryTitle } from "../../../../styles/components/typography";

const FAQTitle = () => {
  return (
    <SectionContainer pt={"10rem"}>
      <FAQTitleContainer>
        <Grid2 container>
          <Grid2 xs={12} md={7}>
            <SecondaryTitle mb={"8rem"}>Your attention is important to us and we try to answer any question</SecondaryTitle>
          </Grid2>{" "}
          <Grid2 md={5}>
            <ImgBox className={"img-box desktopOnly"}>
              <img src={faqTitleImg} alt={"FAQ image"} />
            </ImgBox>
          </Grid2>
        </Grid2>
      </FAQTitleContainer>
    </SectionContainer>
  );
};

export default FAQTitle;
