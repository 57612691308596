import { Box, Stack, styled } from "@mui/material";
import { mobileScreen, smallMobileScreen, tabletOnlyScreen } from "../../../../utils/themeBreakpoints";

export const HeroBackground = styled(Box)`
  //background-image: linear-gradient(282deg, #fff 20%, #0f143f, #5393e3);
  //background-color: #1264da;
  //background-image: linear-gradient(to top, #1264da, #106eed);
  //background-image: linear-gradient(270deg, #0F143F 17%, #0E75FC 100%);
  position: absolute;
  height: 100%;
  width: 100%;
  //border-bottom-right-radius: 60%;

  > svg {
    position: absolute;
    height: 100%;
    right: 0;
    ${mobileScreen} {
      display: none;
    }
  }

  ${mobileScreen} {
    background-image: linear-gradient(320deg, #0f143f, #0e75fc);
  }
`;

export const HeroBackgroundExtension = styled(Box)`
  position: absolute;
  left: 0;
  width: 70vw;
  height: 100%;
  background-image: linear-gradient(to top, #1264da, #106eed);

  @media only screen and (max-width: 2100px) {
    width: 10vw;
  }
  @media only screen and (max-width: 1536px) {
    display: none;
  }
`;

export const HeroContent = styled(Stack)`
  max-width: 65rem;
  //width: 60%;
  flex: 1 1 50%;
  align-self: flex-start;

  h1 {
    margin-bottom: 4.4rem;
    padding-top: 10rem;
    color: #0f3987;

    ${mobileScreen} {
      text-align: center;
    }

    ${smallMobileScreen} {
      letter-spacing: 1.1px;
    }
    b {
      color: #53baff;
    }
  }

  .MuiListItemIcon-root {
    svg {
      path {
        fill: #0f3987;
      }
    }
  }

  p,
  .MuiListItemText-root > span {
    color: #0f4297;
    line-height: 1.25;
    //text-shadow: 0 2px 4px rgba(15, 22, 67, 0.65);
  }

  p {
    ${mobileScreen} {
      width: 95%;
      text-align: center;
    }
  }

  .MuiList-root {
    ${mobileScreen} {
      width: fit-content;
      margin: 0 auto 16rem;
    }
  }

  .MuiListItem-root {
    padding-left: 0;

    ${smallMobileScreen} {
      padding-right: 0;
    }
  }

  .MuiListItemIcon-root {
    min-width: auto;
    margin-right: 1rem;
  }

  ${mobileScreen} {
    margin: 0 auto;
    width: 90%;
  }

  ${smallMobileScreen} {
    width: 100%;
    h1 {
      font-size: 4.6rem;
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
`;

export const HeroImageContainer = styled(Box)`
  //width: 50%;
  flex: 1 1 50%;
  margin-left: 7rem;
  //transform: translateY(25rem) scale(1.5);
  transform: translate(10rem, -25rem) scale(1.22);
  transform-origin: top right;

  > img {
    width: 100%;
  }

  ${mobileScreen} {
    display: none;
    //margin-top: -25%;
    //width: 100%;
    //transform-origin: center;
    //transform: translate(5.5%, 33%) scale(1.22);
  }

  // ${tabletOnlyScreen} {
  //   margin-top: -10rem;
  //   //width: 70%;
  //   max-width: 80rem;
  // }
`;
