import { Box, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { MainButton } from "../../../../styles/components/buttons";
import theme from "../../../../styles/theme";
import { TitleContainer } from "../../../../styles/components/Layout";

export const ThankYouTitleContainer = styled(TitleContainer)`
  padding-top: 23rem;
  padding-bottom: 24.5rem;
  margin-bottom: 0;
  overflow: hidden;
  z-index: 0;

  ${theme.breakpoints.down("br768")} {
    margin-bottom: 0;

    > div {
      > p {
        font-size: 2.7rem;
        width: 50%;
      }
    }
  }
  ${theme.breakpoints.down("sm")} {
    > div {
      > p {
        font-size: 2rem;
        width: 75%;
      }
    }
  }
`;

export const ThankYouLowerMobileBackground = styled(Box)`
  margin-top: 20rem;
  background-color: #fff;
  height: 50rem;
  width: 100vw;
  z-index: -1;

  ${theme.breakpoints.up("br768")} {
    display: none;
  }

  ${theme.breakpoints.down("sm")} {
    margin-top: 27.5%;
    height: 50vw;
  }
`;

export const JoinDiscordButton = styled(MainButton)`
  margin-bottom: 3rem;

  a {
    color: #fff;
    text-decoration: none;
  }

  svg {
    position: relative;
    width: 5rem;
    translate: -1.5rem 0;
    path {
      fill: #fff;
    }
  }

  ${theme.breakpoints.down("br768")} {
    /* display: none; */
    display: flex;
    margin: 5rem auto;
  }

  ${theme.breakpoints.down("sm")} {
    margin-bottom: 4rem;
  }

  ${theme.breakpoints.down("br480")} {
    padding: 1.5rem 3rem;
    font-size: 2rem;

    svg {
      translate: 0;
    }
  }
`;

export const ThankYouSocialMedia = styled(Stack)`
  width: 100%;
  max-width: 45rem;
  > p {
    font-size: 1.4rem;
    line-height: 1.79;
    letter-spacing: -0.15px;
    color: #9da1aa !important;
    align-self: center;
    margin-bottom: 1.5rem;
  }

  > div {
    > button:first-of-type {
      display: none;
    }
  }

  ${theme.breakpoints.down("br768")} {
    /* display: none; */
    max-width: 100%;

    > div {
      justify-content: center;
    }
  }

  ${theme.breakpoints.down("br480")} {
    > div {
      margin-top: 0;
      width: 100%;
    }
  }
`;

export const ThankYouImageBox = styled(Box)`
  position: absolute;
  left: 38.5%;
  bottom: -58rem;
  width: 109rem;
  /* max-width: 70vw; */
  z-index: -1;
  > img {
    width: 100%;
    display: block;
    margin-left: auto;
    /* max-width: 50vw; */
  }

  ${theme.breakpoints.down("xl")} {
    left: 45%;
  }

  ${theme.breakpoints.down("br768")} {
    left: 60%;
    bottom: -125rem;
    transform: translate(-50%, 0);

    z-index: 0;
  }

  ${theme.breakpoints.down("sm")} {
    width: 150%;
    bottom: unset;
    top: 105%;
  }
`;
