import { FlexBox, NFTSectionContainer } from "../../../../styles/components/Layout";
import { MembershipCTA, MembershipNftBg, MembershipNftCard, PlusIcon } from "./MembershipNft.styles";
import { Typography } from "@mui/material";
import { PlatformSvg } from "./img/platform";
import MembershipImg from './img/Membership.webp'
import PlatformImg from './img/PlatformMembershipMob.webp'
import { PlusSvg } from "../../../../assets/svg/icons";
import { CTAButtonNFT } from "../hero/HeroNft.styles";
import { RabbitLogo } from "../../../../assets/svg/logo";
import { ApplyForNFTMask, ApplyForNFTMaskMobile, MembershipNftCardTop } from "../../../../assets/svg/decorations";
// import { MembershipBg } from "../../../assets/svg/MembershipBg";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoginWithRedirect } from "../../../../utils/hooks/useLoginWithRedirect";

const MembershipNft = () => {
  const navigate = useNavigate()
  const {search} = useLocation()
  const {onLogin} = useLoginWithRedirect();

  return (
    <MembershipNftBg>
      <NFTSectionContainer>
        <FlexBox>
          <MembershipNftCard>
            {/*<MembershipSvgBox className={'mobileOnly'}>*/}
            {/*  /!*<MembershipBg/>*!/*/}
            {/*  <img src={PlatformBgWebm} alt={'bg'}/>*/}
            {/*</MembershipSvgBox>*/}
            <Typography variant="h3">Web3 Advertising platform</Typography>
            <Typography>
              With Web3m, marketers enjoy smart targeting, audience analytics,
              and a media planning dashboard for all your campaigns. <br />{" "}
              <br /> Advertise your project across thousands of popular
              exchanges, Web3 blogs, and news outlets.
            </Typography>
            <PlatformSvg className={'desktopOnly'}/>
            {/*<PlatformMobileSvg className={'mobileOnly'}/>*/}
            <img src={PlatformImg} alt={'Platform'} className={'mobileOnly'}/>
          </MembershipNftCard>
          <PlusIcon><PlusSvg/></PlusIcon>
          <MembershipNftCard>
            <MembershipNftCardTop className='mobCardTop mobileOnly'/>
            {/*<MembershipSvgBox className={'mobileOnly'}>*/}
            {/*  /!*<MembershipBg/>*!/*/}
            {/*  <img src={MembershipBgWebm} alt={'bg'}/>*/}
            {/*</MembershipSvgBox>*/}
            <Typography variant="h3">Membership <br/> NFT</Typography>
            <Typography>
              Web3m users are also owners in the platform through an NFT. Enjoy
              access to a Web3 marketing community. Plus, receive kickbacks from
              the total ad revenue on Web3m. <br /> <br /> As the platform
              succeeds, so do you.
            </Typography>
            <img src={MembershipImg}  alt='ZAI nft'/>
          </MembershipNftCard>
        </FlexBox>
        <MembershipCTA>
          <ApplyForNFTMask className={'desktopOnly'}/>
          <ApplyForNFTMaskMobile className={'mobileOnly'}/>
        <CTAButtonNFT
          startIcon={<RabbitLogo/>}
          // onClick={() => navigate('/signup' + search)}
          onClick={onLogin}
        >
          {"CREATE MY FIRST AD"}
          {/*Apply for early access*/}
        </CTAButtonNFT>
        </MembershipCTA>
      </NFTSectionContainer>

    </MembershipNftBg>
  );
};

export default MembershipNft;
