import budgetImg from "../../../../../assets/img/cards/budget.webp";
import tasksImg from "../../../../../assets/img/cards/tasks.webp";
import configImg from "../../../../../assets/img/cards/config.webp";

export const whatIsList = [
  {
    title: "Get paid for \n browsing the web",
    desc: "Advertisers pay to access website data. We make sure websites and their visitors collect a piece of that advertising spend. That means, every time your data is used in a campaign, you’re paid for it.",
    img: budgetImg,
  },
  {
    title: "Own your \n data",
    desc: "In Web3m, anyone can monitor and delete their own data from the larger database. It’s never been easier to take ownership of your online privacy.",
    img: tasksImg,
  },
  {
    title: "Maximize your marketing with powerful analytics",
    desc: "By adding the Web3m pixel to your website, you collect anonymous data about your site visitors. That includes wallet behavior, demographic and geographic profiles, and more. Plus, Web3m is fully GDPR compliant.",
    img: configImg,
  },
];
