import { CoinsSvg } from "../../../../assets/svg/buttonIcons";
import { CalcIcon, QualityIcon } from "./img/Network.icons";

export const networkList = [
  {
    icon: CoinsSvg(),
    text: "Websites are paid to share anonymous analytics with the Web3m network.",
  },  {
    icon: CalcIcon(),
    text: "Website visitors are paid for passively using the internet (and can delete their data any time).",
  },  {
    icon: QualityIcon(),
    text: "Advertisers access better web3 data, with full GDPR compliance.",
  },
];
