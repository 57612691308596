import { styled, Typography } from "@mui/material";
import theme from "../../../styles/theme";

export const BrandInfoTitle = styled(Typography)`
  font-family: "Hellix-SemiBold", "Hellix", sans-serif;
  font-size: 3.2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 0.36px;
  color: #082765;

  ${theme.breakpoints.down("sm")} {
    color: #677194;
    font-weight: normal;
    text-align: center;
    margin: 3rem 0 2rem;
  }
`;
