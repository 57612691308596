export function DialogTip(props) {
  return (
    <svg width="35px" height="19px" viewBox="0 0 35 19" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M596.027 460h34.306c-5.39 1.114-9.475 3.636-12.253 7.564-2.779 3.929-4.412 7.574-4.9 10.936-.373-3.362-1.956-7.007-4.75-10.936-2.792-3.928-6.927-6.45-12.403-7.564z"
        transform="translate(-596 -460)"
        fill="#00213A"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function DialogTipLeft(props) {
  return (
    <svg viewBox="0 0 44 188" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(-868 -547) translate(868.063 547)"
        fill="#FFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      >
        <path
          d="M3.62 120.073c10.307 1.976 17.983 3.053 23.03 3.235 4.616.166 9.103-.34 13.458-1.518a4.49 4.49 0 002.402-1.607l-.073.088v17.523a9.518 9.518 0 00-.829-1.36l-.241-.317a9.484 9.484 0 00-5.311-3.34c-4.195-.96-8.039-2.082-11.53-3.367-4.782-1.76-11.752-4.873-20.906-9.337z"
          stroke="#010A10"
        />
        <path d="M41.6617334 119.25999H44.6617334V138.25999000000002H41.6617334z" />
      </g>
    </svg>
  )
}

export function ApplyForNFTMask(props) {
  return (
    <svg viewBox="0 0 487 101" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M239.868 2924.498c-6.926 0-13.476-1.837-19.297-5.107-10.684-5.283-17.708-14.554-22.95-28.518l-.28-.758-.023-.073c-3.247-10.62-12.38-17.88-22.78-18.159l-.422-.007c-4.432-.039-8.983-.058-13.544-.064h-7.652c-16.257.024-31.857.173-41.781.173-12.294-10.427 9.467-43.771 20.537-43.771l94.084-1.062a38.82 38.82 0 0114.108-2.654l110.616 1.248 110.618-1.248c4.936 0 9.681.934 14.108 2.654l94.083 1.062c11.071 0 32.831 33.344 20.538 43.77l-5.522-.011c-9.94-.036-23.357-.146-37.18-.162l-4.776-.001-1.955.002c-4.561.006-9.112.025-13.545.064l-.422.007c-10.258.276-19.285 7.344-22.644 17.73l-.158.502-.024.068c-5.27 14.35-12.349 23.84-23.21 29.209-5.82 3.27-12.369 5.106-19.293 5.106H239.868z"
        transform="translate(-107 -2824)"
        fill="#F3F2F2"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}

export function ApplyForNFTMaskMobile(props) {
  return (
    <svg viewBox="0 0 570 114" xmlns="http://www.w3.org/2000/svg"{...props}>
      <path
        d="M331.521 4144.44c-8.108 0-15.777-2.075-22.592-5.771-12.509-5.97-20.732-16.446-26.87-32.225l-.328-.857-.027-.082c-3.801-12-14.495-20.204-26.67-20.52l-.494-.008c-5.189-.043-10.517-.065-15.857-.072h-8.958c-19.035.027-37.298.195-48.918.195-14.392-11.782 11.084-49.461 24.045-49.461l110.151-1.2a46.897 46.897 0 0116.518-2.998l129.508 1.41 129.509-1.41c5.779 0 11.335 1.054 16.518 2.998l110.15 1.2c12.962 0 38.438 37.68 24.046 49.46l-6.466-.012c-11.637-.041-27.346-.165-43.53-.183l-5.591-.002-2.29.003c-5.339.007-10.667.029-15.856.072l-.495.008c-12.01.312-22.579 8.3-26.512 20.036l-.184.566-.029.077c-6.17 16.216-14.457 26.94-27.174 33.006-6.813 3.696-14.48 5.77-22.587 5.77H331.52z"
        transform="translate(-176 -4031) matrix(1 0 0 -1 0 8175.881)"
        fill="#FFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}

export function ApplyForNftFaq(props) {
  return (
    <svg viewBox="0 0 486 101" xmlns="http://www.w3.org/2000/svg"{...props}>
      <path
        d="M237.383 6939.25c-6.926 0-13.476-1.837-19.297-5.108-10.684-5.283-17.708-14.554-22.95-28.517l-.28-.759-.023-.072c-3.247-10.62-12.38-17.88-22.78-18.16l-.422-.007c-4.432-.038-8.983-.057-13.544-.064l-7.651.001c-16.258.023-31.858.172-41.782.172-12.293-10.426 9.467-43.77 20.538-43.77l94.083-1.062a38.82 38.82 0 0114.108-2.654L348 6840.497l110.617-1.247c4.936 0 9.681.933 14.108 2.654l94.083 1.061c11.071 0 32.831 33.345 20.538 43.771l-5.522-.011c-9.94-.037-23.357-.146-37.18-.162l-4.776-.002-1.955.002c-4.561.007-9.112.026-13.544.064l-.423.008c-10.258.276-19.285 7.344-22.644 17.73l-.158.501-.024.069c-5.27 14.35-12.349 23.84-23.21 29.209-5.82 3.27-12.369 5.106-19.293 5.106H237.383z"
        transform="translate(-105 -6839)"
        fill="#00213A"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}

export const MembershipNftCardTop = (props) => (
  <svg
    viewBox="0 0 656 628"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <linearGradient x1="76.615%" y1="100%" x2="76.615%" y2="0%" id="mnftct-b">
        <stop stopColor="#F4EDE4" offset="0%" />
        <stop stopColor="#C5C5C5" offset="100%" />
      </linearGradient>
      <path
        d="M547.272 0C606.77 0 655 48.231 655 107.728v411.544C655 578.77 606.769 627 547.272 627H107.728C48.23 627 0 578.769 0 519.272V107.728C0 48.23 48.231 0 107.728 0h129.414c16.063 28.884 46.893 48.427 82.287 48.427 35.394 0 66.224-19.543 82.288-48.426L547.272 0Z"
        id="mnftct-a"
      />
      <mask
        id="mnftct-c"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x={0}
        y={0}
        width={655}
        height={627}
        fill="#fff"
      >
        <use xlinkHref="#mnftct-a" />
      </mask>
    </defs>
    <use
      mask="url(#mnftct-c)"
      xlinkHref="#mnftct-a"
      transform="translate(.53 .758)"
      fill="#F4EDE4"
      stroke="url(#mnftct-b)"
      strokeWidth={4.897}
      fillRule="evenodd"
      strokeDasharray={11.018}
      strokeLinecap="round"
    />
  </svg>
)
