import React from "react";
import { SectionContainer } from "../../../styles/components/Layout";
import ForWhoList from "./forWho/ForWhoList";
import { SignUpBodyContainer } from "./SignUp.styles";
import SignUpForm from "./form/SignUpForm";
import SignUpTitle from "./title/SignUpTitle";
// import {useAuth0} from "@auth0/auth0-react";
// import {useNavigate} from "react-router-dom";

const SignUp = ({allChecked}) => {
    // const navigate = useNavigate()

    // useEffect(() => {
    //     console.log(allChecked)
    //     allChecked && navigate(pathWithUtm('/'))
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[allChecked])

  return (
    <>
      <SignUpTitle />
      <SectionContainer>
        <SignUpBodyContainer>
          <SignUpForm />
          <ForWhoList />
        </SignUpBodyContainer>
      </SectionContainer>
    </>
  );
};

export default SignUp;
