import { SectionContainer } from "../../../../../styles/components/Layout";
import Grid2 from "@mui/material/Unstable_Grid2";
import phoneImg from "./img/phone.webp";
import TextImageCard from "../../../../templates/textImage/TextImageCard";
import aboutImg from "./img/about.webp";
import { ScaledImage } from "./About.styles";
import {
  SecondaryTitle,
  SectionMainTitle,
  SectionParagraph
} from "../../../../../styles/components/typography";

const About = () => {
  return (
    <SectionContainer p={"12rem 0"}>
      <Grid2 container columnSpacing={"10rem"}>
        <Grid2 xs={12} md={6}>
          <SecondaryTitle m={{xs: '0 auto 5rem', md: '0 0 3.2rem'}}>
            Rethink everything you know about online data
          </SecondaryTitle>
          <SectionParagraph width={'90%'} m={{xs: '0 auto 5rem', md: '0 0 5rem'}}>
            The Web3m data network gives brands access to powerful advertising data, without invading the privacy of consumers.
          </SectionParagraph>
        </Grid2>
        <Grid2 xs={12} md={6} className={"desktopOnly"}>
          <ScaledImage>
            <img src={phoneImg} alt="Phone-Img"/>
          </ScaledImage>
        </Grid2>
      </Grid2>
      <SectionMainTitle mb={"5.6rem"} >
        Privacy. Ownership. <br /> <b>Compensation.</b>
      </SectionMainTitle>
      <TextImageCard image={aboutImg} textWidth={6} sx={{ maxWidth: { xs: "40rem", br480: "60rem" }, m: "0 auto", transform: { xs: "none", md: "scale(1.1)" } }}>
        <SectionParagraph>
          At Web3m, we believe website visitors should have greater ownership over their personal data. We also believe advertisers deliver
          the best campaigns with access to better data.
          <br />
          <br />
          Web3m is a data network built on a payment model that incentivizes web3 users. Every time a marketer uses the data from a specific
          user in a campaign, that person is paid.
        </SectionParagraph>
      </TextImageCard>
    </SectionContainer>
  );
};

export default About;
