import { AllRights, FooterContainer, LogoFooterBox, SubContainer } from "./Footer.styles";
import { Logo } from "../../../assets/svg/logo";
import { SocialMedia } from "../../shared/socialMedia/SocialMedia";
import { useBreakpoints } from "../../../utils/hooks/useBreakpoints";

export const Footer = () => {
  const { screen1024 } = useBreakpoints();

  return (
    <FooterContainer>
      {screen1024 && <SocialMedia />}
      <SubContainer>
        <LogoFooterBox>
          <Logo />
        </LogoFooterBox>
        {!screen1024 && <SocialMedia />}
        <AllRights>{`All rights reserved © ${new Date().getFullYear()} Web3m`}</AllRights>
      </SubContainer>
    </FooterContainer>
  );
};

export default Footer;
