import { Avatar, TextField } from "@mui/material";
import { Box, styled } from "@mui/system";
import theme from "../../../../../styles/theme";

export const DescriptionLogo = styled(Box)`
  display: flex;
  /* margin-bottom: 4.7rem; */
  align-items: center;

  h3 {
    font-size: 2.86rem;
    font-weight: 600;
    color: #383b40;
    line-height: normal;
    text-transform: capitalize;
    letter-spacing: 0.32px;
    margin-bottom: 0.5rem;
  }
  h4 {
    font-family: 'Hellix', sans-serif;
    font-size: 1.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: 0.2px;
    color: #383b40;
  }

  > div:first-of-type {
    margin-right: 1.5rem;
  }

  ${theme.breakpoints.down("br480")} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //height: 90%;
    flex: 1;

    h3 {
      text-align: center;
    }

    > div:first-of-type {
      margin-right: 0;
    }
  }
`;

export const LogoContainer = styled(Avatar)`
  width: 14.5rem;
  height: 14.5rem;
  font-size: 6.3rem;
  font-weight: 900;
  text-transform: uppercase;
  background-color: #fff;
  color: #383b40;
  box-shadow: inset 0 0 25px 0 rgba(26, 65, 142, 0.17);

  ${theme.breakpoints.down("br480")} {
    margin-bottom: 2rem;
    width: 16.8rem;
    height: 16.8rem;
  }
`;

export const DescriptionBlockWrapper = styled(Box)`
  width: 75%;
  padding: 2rem;
  ${theme.breakpoints.down("lg")} {
    width: 90%;
  }
  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }
  ${theme.breakpoints.down("br480")} {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  > h3 {
    margin-bottom: 1.4rem;
  }

  p {
    font-size: 2rem;
    line-height: 1.27;
    letter-spacing: 0.22px;
    margin-top: 3.3rem;
    margin-left: 1rem;

    ${theme.breakpoints.down("br480")} {
      font-size: 1.8rem;
      margin-left: 0;
      margin-top: 0;
    }
  }
`;
