//Core
import { useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
//Styles
import {
  CTAButtonNFT,
  HeroContent,
  HeroNftImage,
  HeroNftImageBg,
  HeroNFTList,
  HeroNFTOuterContainer,
} from "./HeroNft.styles";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
//Images
import { EnergySvg } from "../../../../assets/svg/icons";
import { RabbitLogo } from "../../../../assets/svg/logo";
import RabbitMobile from "./img/Hero-rabbit-mobile.webp";
import { NftHeroBgDesktop } from "./img/backgrounds";
//Data
import { nftHolderGain, rabImages } from "./HeroNft.data";
//Hooks
import { useLoginWithRedirect } from "../../../../utils/hooks/useLoginWithRedirect";

const HeroNft = () => {
  const navigate = useNavigate();
  const {search} = useLocation();
  const {onLogin} = useLoginWithRedirect();

  return (
    <HeroNFTOuterContainer>
      <HeroContent>
        <Typography variant="h1">Become a Web3m insider</Typography>
        <Typography variant="h3">As an NFT holder, you’ll gain:</Typography>
        <HeroNFTList>
          {nftHolderGain.map((text) => (
            <ListItem key={text}>
              <ListItemAvatar>
                <EnergySvg />
              </ListItemAvatar>
              <ListItemText>{text}</ListItemText>
            </ListItem>
          ))}
        </HeroNFTList>
        <CTAButtonNFT
          startIcon={<RabbitLogo />}
          className={"desktopOnly"}
          // onClick={() => navigate(pathWithUtm("/signup"))}
          onClick={onLogin}
        >
          {"CREATE MY FIRST AD"}
          {/*{'Apply for early access'}*/}
        </CTAButtonNFT>
        <HeroNftImage>
          <Swiper
            modules={[Autoplay, EffectFade]}
            speed={1500}
            noSwiping
            noSwipingClass={'swiper-slide'}
            autoplay={{ delay: "3000" }}
            effect="fade"
            fadeEffect={{ crossFade: true }}
            className="mySwiper desktopOnly"
            slidesPerView={1}
            loop
            // preventInteractionOnTransition
          >
            {rabImages.map((el, i) => (
              <SwiperSlide key={i}>{el}</SwiperSlide>
            ))}
          </Swiper>

          <img
            src={RabbitMobile}
            alt="rabbit nft image"
            className={"mobileOnly"}
          />

          <HeroNftImageBg>
            <NftHeroBgDesktop className={"desktopOnly"} />
            {/*<SvgIcon component={NftHeroBgMobile} inheritViewBox className={"mobileOnly"} />*/}
            {/*<NftHeroBgMobile className={'mobileOnly'}/>*/}
          </HeroNftImageBg>
        </HeroNftImage>
      </HeroContent>
    </HeroNFTOuterContainer>
  );
};

export default HeroNft;
