import { styled } from "@mui/system";
import theme from "../../../../styles/theme";
import { TitleContainer } from "../../../../styles/components/Layout";

export const SignUpTitleContainer = styled(TitleContainer)`
  padding-top: 20rem;
  padding-bottom: 47rem;
  h1 {
    max-width: 95rem;
  }
  img {
    position: absolute;
    right: -2.5rem;
    top: -1rem;
    max-width: 67.5rem;
    z-index: 2;

    ${theme.breakpoints.down("br1024")} {
      max-width: 49%;
      top: 7rem;
    }
    ${theme.breakpoints.down("md")} {
      max-width: 49%;
      top: 9rem;
    }
    ${theme.breakpoints.down("br768")} {
      position: relative;
      max-width: 108%;
      right: unset;
      left: -1.5rem;
      top: unset;
      margin: 5rem -4% -60%;
    }
  }
  ${theme.breakpoints.down("md")} {
    padding-bottom: 17rem;
    margin-bottom: 1rem;

    h1 {
      text-align: center;
    }
    p {
      max-width: 90%;
      margin: 0 auto;
    }
    img {
      display: none;
    }
  }
  ${theme.breakpoints.down("sm")} {
    padding-top: 16rem;
    
  }
`;
