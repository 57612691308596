export function OpenIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41.5" height="41.5" viewBox="0 0 41.5 41.5" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1096.624 -1237.148)">
          <g transform="translate(555.55 1197.398)">
            <g>
              <g transform="translate(541.074 39.75)">
                <rect width="41.5" height="41.5" x="0" y="0" fill="#EBF1F5" rx="9"></rect>
                <path
                  stroke="#97A0C3"
                  strokeLinecap="round"
                  strokeWidth="3"
                  d="M12.8757079 17.2141829L20.5400147 24.7141829 28.2043214 17.2141829"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function CloseIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41.5" height="41.5" viewBox="0 0 41.5 41.5" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1096.624 -1237.35)">
          <g transform="translate(555 1197.6)">
            <rect width="41.5" height="41.5" x="541.624" y="39.75" fill="#EBF1F5" rx="9"></rect>
            <path stroke="#97A0C3" strokeLinecap="round" strokeWidth="3" d="M554.5 59.964L569.829 59.964"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
