import { SectionTitle, SectionWrapper } from "../shared/styles/layout";
import { Grid, Typography } from "@mui/material";
import Description from "./description/Description";
import GeneralInfo from "./general/GeneralInfo";
import GoogleSearches from "./googleSearches/GoogleSearches";
import Competitors from "./competitors/Competitors";
import { useContext, useEffect, useRef } from "react";
import { BrandInfoContext } from "../../../../context/BrandInfoContext";
import { fetchItems } from "../../../../utils/fetchItems";
import OrganicGrowth from "../promotionalStatus/organicGrowth/OrganicGrowth";
import PotentialUsers from "../promotionalStatus/potentialUsers/PotentialUsers";
import CommunitySentiment from "../promotionalStatus/communitySentiment/CommunitySentiment";

//TODO: refactor this component (accordion) and each section
const YourBrand = () => {
  const { fetchList, brandProfile } = useContext(BrandInfoContext);
  const loading = fetchList.includes(fetchItems.brand_profile);

  const sectionRef = useRef();

  useEffect(() => {
    if (brandProfile) {
      sectionRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [brandProfile]);

  if (!loading && !brandProfile) return null;

  return (
    <SectionWrapper sx={{ pt: "12rem", pb: 0 }} ref={sectionRef}>
      {!!brandProfile && (
        <SectionTitle>
          <Typography variant="subtitle1">
            {'We’ve pulled some information about your brand from the web.'}
          </Typography>
        </SectionTitle>
      )}
      <Description />
      <GeneralInfo />
      {/*<Grid container spacing={"4rem"}>*/}
      {/*  <Grid item xs={12} br1024={6}>*/}
          <GoogleSearches />
        {/*</Grid>*/}
        {/*<Grid item xs={12} br1024={6}>*/}
          <Competitors />
        {/*</Grid>*/}
      {/*</Grid>*/}
      <OrganicGrowth />
      <PotentialUsers />
      <CommunitySentiment />
    </SectionWrapper>
  );
};

export default YourBrand;
