import { styled } from "@mui/material";
import { ImgBox } from "../../../../../styles/components/Layout";

export const ScaledImage = styled(ImgBox)`
  img {
    display: block;
    margin: 0 auto;
    width: 50%;
    transform: translateY(-30%) scale(2);
    //transform-origin: left;
  }
`;
