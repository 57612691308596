import { Alert, Box, FormControl, FormLabel, styled, Typography } from "@mui/material";
import { SectionContainer } from "../../mainContent/brandInfo/shared/styles/layout";
import { LoadingButton } from "@mui/lab";
import { FlexBox } from "../../../styles/components/Layout";
import { mobileScreen } from "../../../utils/themeBreakpoints";
import { isMobile } from 'react-device-detect';

export const BrandAnalysisBackground = styled(Box)`
  background-image: linear-gradient(234deg, #144F9E 0%, #082765 100%);
  width: 100%;
  border-radius: 3.3rem;
  margin-bottom: 2rem;

  > div:nth-of-type(2) {
    padding: 5rem 6rem;
  }
  
`;

export const BrandAnalysisTitleBackground = styled(Box)`
  background-image: linear-gradient(-78deg, #A0A2FF 6%, #082765 100%);
  border-top-left-radius: 3.3rem;
  border-top-right-radius: 3.3rem;
  `

export const BrandAnalysisTitleContainer = styled(SectionContainer)`
  padding: 0 6rem;
  display: flex;
  align-items: center;
  
  #free {
    position: relative;
    top: -2rem;
    margin-bottom: 4rem;
  }
  
  h2 {
    font-family: "F37 Judge", sans-serif;
    font-size: 4.6rem;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: 1.61px;
    color: #fff;

    margin-top: 2rem;
  }
  ${mobileScreen} {
    flex-direction: column;
    padding-bottom: 5rem;
    #free {
      width: 40vw;
    }
  }
`

export const BrandAnalysisForm = styled(FormControl)`
  width: 100%;

  > div {
    ${mobileScreen} {
      flex-direction: column;
    }
  }

  .MuiInputBase-root {
    flex: 1;
    margin: 2.4rem 2.2rem 2rem 0;
    background-color: #fff;
    border-radius: 0.8rem;
    padding: 0 1rem;

    ${mobileScreen} {
      margin-right: 0;
      width: 100%;

      input {
        padding: 1.2rem;
      }
    }
  }
`;

export const BrandAnalysisFormLabel = styled(FormLabel)`
  font-size: 2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: -0.21px;
  color: #92dbef;
  transition: 0.3s;

  &.Mui-focused {
    color: #92dbef;
    opacity: 0.7;
  }
  
  &.other {
    color: #629be1;
    text-decoration: underline;
    cursor: pointer;
  }
  
  ${mobileScreen} {
    align-self: center;
  }
`

export const BrandAnalysisRequiredText = styled(Typography)`
  font-size: 1.7rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.65;
  letter-spacing: -0.18px;
  color: #458395;
  margin-left: 1rem;
`

export const BrandAnalysisFlexWrap = styled(FlexBox)`
  flex-wrap: wrap;
  padding: 3rem 0 7rem 1rem;
  gap: 4rem;
  &::before, &::after {
    content: "";
    width: 100%;
    order: 1;
  }
  & .section {
    padding-left: 2rem;
    border-radius: 1rem;
    box-shadow: none;
    border: 1px dashed #A2ADC5;
  }
  & .checked {
    box-shadow: 0 10px 22px 0 rgba(3, 19, 53, 0.59);
    border: 1px solid #FFFFFF;
  }
  ${mobileScreen} {
    flex-direction: row !important;
    justify-content: center;
    gap: 2.5rem;
    padding: 3rem 0 0 ;
  }
`

export const BrandAnalysisSectionInfoMobile = styled(Box)`
  
  position: absolute;
  top: -1px;
  right: -1px;
  width: ${isMobile ? '3rem' : '1.5rem'};
  height: ${isMobile ? '3rem' : '1.5rem'};
`

export const BrandAnalysisSectionInfo = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  background: #A2ADC5;
  cursor: pointer;
  &.selected {
    background: #FFFFFF;
  }
  padding: 0 0.8rem;
  border-radius: 0 1rem 0 1rem;
  p {
    font-size: 1.2rem;
  }
`

export const BrandAnalysisButton = styled(LoadingButton)`
    height: 100%;
    border-radius: 0.8rem;
    background: #e7526a;
    padding: 2rem 3.5rem;

    font-size: 2.16rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.24px;
    color: #fff;

    &.Mui-disabled {
      background-image: none;
      background-color: rgba(255, 255, 255, 0.12);
      color: rgba(255, 255, 255, 0.15);
    }

    &.MuiLoadingButton-loading {
      color: transparent;
    }

    ${mobileScreen} {
      width: 85%;
      margin: 0 auto;
      &.Mui-disabled {
        width: auto;
        margin: 0;
      }
    }
`

export const ErrorMessage = styled(Alert)`
  position: fixed;
  bottom: 5rem;
  right: 5rem;
  z-index: 3;
  transform: translateX(180%);
  transition: .3s;
  
  &.display{
    transform: none;
  }
`;

export const BrandAnalysisMobileBox = styled(Box)`
  padding: 0 6rem 5rem;
  &.checkboxes, &.radio {
    padding: 0 6rem;
  }

  &.run {
    border-bottom-left-radius: 3.3rem;
    border-bottom-right-radius: 3.3rem;
  }

  ${mobileScreen} {
    padding: 5rem 6rem;
    width: 100vw;
    border-top: 1px solid #fff;
    background-image: linear-gradient(234deg, #144F9E 0%, #082765 100%);

    &.checkboxes, &.radio {
      padding: 5rem 6rem;
      //padding-bottom: 7rem;
    }
  }
`;

export const BrandAnalysisMobileOpenInfo = styled(Typography)`
  font-size: 1.7rem;
  color: #A0A2FF;
  letter-spacing: 0.59px;
  text-decoration: underline;
  width: fit-content;
  cursor: pointer;
`

export const BrandAnalysisMobileCloseInfo = styled(Typography)`
  opacity: 0.49;
  font-size: 1.3rem;
  color: #FFFFFF;
  letter-spacing: -0.14px;
  text-align: center;
  margin-top : 3rem;
  cursor: pointer;
`