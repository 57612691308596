import { RelativeBox, SectionBackground, SectionContainer } from "../../../../../styles/components/Layout";
import CardsGrid from "../../../../templates/cardsGrid/CardsGrid";
import { howItWorksList } from "./HowItWorks.data";
import { SecondaryTitle } from "../../../../../styles/components/typography";

const HowItWorks = () => {
  return (
    <RelativeBox>
      <SectionBackground sx={{ backgroundColor: "#fafafa" }} />
      <SectionContainer p={"12rem 0"}>
        <SecondaryTitle mb={"8.5rem"}>How it works?</SecondaryTitle>
        <CardsGrid data={howItWorksList} titleWidth={"100%"} />
      </SectionContainer>
    </RelativeBox>
  );
};

export default HowItWorks;
