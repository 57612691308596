//Core
import {Formik} from "formik";
import React from "react";
//Styles
import {Divider, InputAdornment, Typography} from "@mui/material";
import {
    LoginButton,
    LoginDescription, LoginFormStack, LoginShowPasswordButton,
    LoginTitle,
    LoginWithGoogleButton,
    OrBox,
} from "../Login.styles";
import { FormInput, FormInputName } from "../../../../mainContent/404/support/Forms.styles";
//Hooks
import {useSignInSignUp, validationSignInSchema, validationSignUpSchema} from "./useSignInSignUp";
//Data
import {loginDescriptions, titles} from "../Login.data";
//Images
import {EmailSvg, PasswordSvg, GoogleSvg} from "../img/logos";
import {HideSvg, ShowSvg} from "../../../../../assets/svg/buttonIcons";

export const SignInSignUp = ({webAuth, activePage, changePage}) => {
    const {
        formData,
        sendForm,
        loginWithGoogle,
        errorResponse,
        passwordHidden,
        handlePasswordHidden
    } = useSignInSignUp(webAuth, activePage);

    return (
        <LoginFormStack>
            <LoginTitle>{titles[activePage]}</LoginTitle>
            <LoginDescription>{loginDescriptions[activePage]()}</LoginDescription>
            <Formik
                initialValues={formData}
                validationSchema={activePage === 'signIn' ? validationSignInSchema : validationSignUpSchema}
                onSubmit={async (values) => {
                    await sendForm(values);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                        <FormInput
                            placeholder={"Email"}
                            name={"username"}
                            type={"email"}
                            autoComplete={"email"}
                            className={'login'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            error={touched.username && Boolean(errors.username)}
                            helperText={errors.username && touched.username && errors.username}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{<EmailSvg/>}</InputAdornment>,
                            }}
                        />
                        <LoginShowPasswordButton
                            endIcon={passwordHidden ? <HideSvg/> : <ShowSvg/>}
                            onClick={handlePasswordHidden}
                        >
                            {passwordHidden ? 'Show password' : 'Hide password'}
                        </LoginShowPasswordButton>
                        <FormInput
                            placeholder={"Password"}
                            name={"password"}
                            type={passwordHidden ? "password" : 'text'}
                            autoComplete={activePage === 'signIn' ? "current-password" : 'new-password'}
                            className={'login'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            error={touched.password && Boolean(errors.password)}
                            helperText={errors.password && touched.password && errors.password}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">
                                        <PasswordSvg/>
                                    </InputAdornment>,
                              className: 'login'
                            }}
                            sx={{marginBottom: '1rem'}}
                        />
                        {activePage === 'signIn' &&
                            <FormInputName>
                                {"Forgot password?"}
                                <span onClick={() => changePage('forgotPassword')}>{'click here'}</span>
                            </FormInputName>}
                        {activePage === 'signUp' &&
                            <FormInputName className={'signup'}>
                                * Password have to be 8 characters and include at least 1 number, 1 uppercase
                                letter and 1 special symbol
                            </FormInputName>
                        }
                        <LoginButton variant={"contained"}
                                     color={"secondary"}
                                     type={"submit"}
                                     loading={isSubmitting}
                                     disabled={isSubmitting || !!errors.username || !!errors.password}
                        >
                            {activePage === 'signIn' ? "Login" : "Sign up"}
                        </LoginButton>
                        {errorResponse && <FormInputName className={'error'}>{errorResponse}</FormInputName>}
                    </form>
                )}
            </Formik>
            <OrBox>
                <Divider/>
                <Typography>OR</Typography>
                <Divider/>
            </OrBox>
            <LoginWithGoogleButton
                variant={'outlined'}
                color={'secondary'}
                startIcon={<GoogleSvg/>}
                onClick={loginWithGoogle}
            >
                Continue with Google
                {/*<Image src={GoogleButton} alt={'Google'} width={225} height={38}/>*/}
            </LoginWithGoogleButton>
        </LoginFormStack>
    );
}