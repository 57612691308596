import { Box, OutlinedInput, Stack, styled } from "@mui/material";
import { mobileScreen, smallMobileScreen, tabletOnlyScreen, bigOnlyScreen, mediumOnlyScreen } from "../../../../utils/themeBreakpoints";
import { SectionParagraph } from "../../../../styles/components/typography";
import theme from "../../../../styles/theme";

export const HeroBackgoundExtension = styled(Box)`
  position: absolute;
  left: 0;
  width: 70vw;
  height: 100%;
  background-image: linear-gradient(to top, #1264da, #106eed);

  @media only screen and (max-width: 2100px) {
    width: 10vw;
  }
  @media only screen and (max-width: 1536px) {
    display: none;
  }
`;

export const HeroBackground = styled(Box)`
  width: 100%;
  height: 100%;

  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;

    ${bigOnlyScreen} {
      object-position: right top;
      height: 130%;
      width: 100vw;
    }

    @media only screen and (min-width: 1950px) and (max-width: 2400px) {
      height: 115%;
      width: 104vw;
    }
  }

  ${smallMobileScreen} {
    > img {
      height: 120%;
    }
    border-radius: 0 0 7% 7%;
  }
`;

export const HeroContent = styled(Stack)`
  max-width: 70rem;
  width: 60%;
  align-self: flex-start;

  h1 {
    margin-bottom: 5.3rem;
    padding-top: 10rem;
    b {
      color: #53baff;
    }
  }

  p {
    width: 80%;
    margin-bottom: 4.6rem;

    ${mobileScreen} {
      margin-bottom: 9rem;
    }
  }

  button {
    border-radius: 0.72rem;
    padding: 1.5rem 3.5rem;
  }

  p,
  .MuiListItemText-root > span {
    color: #fff;
    line-height: 1.25;
    text-shadow: 0 2px 4px rgba(15, 22, 67, 0.65);
  }

  .MuiList-root {
    margin-bottom: 14rem;
    ${mobileScreen} {
      width: fit-content;
      margin: 0 auto 20rem;
    }
    ${smallMobileScreen} {
      margin: 0 auto 14rem;
    }
  }

  .MuiListItem-root {
    padding-left: 0;

    ${smallMobileScreen} {
      padding-right: 0;
    }
  }

  .MuiListItemIcon-root {
    min-width: auto;
    margin-right: 1rem;
  }

  a {
    button {
      ${tabletOnlyScreen} {
        transform: translateY(-200%);
      }
    }
  }

  ${bigOnlyScreen} {
    margin-bottom: 5rem;
  }

  ${mobileScreen} {
    margin: 0 auto;
    width: 90%;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }

  ${smallMobileScreen} {
    h1 {
      font-size: 5rem;
    }
    p {
      text-align: center;
      width: 100%;
      margin: 0 auto 5rem;
    }
  }
`;

export const HeroArrow = styled(Box)`
  display: flex;
  font-family: "F37 Judge", sans-serif;
  background-color: #53baff;
  width: 73.15rem;
  height: 8.27rem;
  padding: 0.1rem 0;
  margin-bottom: 3rem;
  margin-top: 1rem;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0% 0%, 96% 0%, 100% 50%, 96% 100%, 0% 100%);

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 2.5rem;
    height: fit-content;
    font-size: 3.16rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    transform: scale(1, 1.1);
    letter-spacing: 1.1px;
    color: #0f143f;
  }

  ${bigOnlyScreen} {
    > span {
      margin-right: 5rem;
    }
  }

  ${mediumOnlyScreen} {
    width: 62rem;
    > span {
      font-size: 2.6rem;
    }
  }

  ${mobileScreen} {
    width: 100%;
    padding: 2rem;
    line-height: 1.5;

    > span {
      font-size: 2.5rem;
      letter-spacing: 0.5px;
    }
  }

  ${smallMobileScreen} {
    > span {
      height: 100%;
      font-size: 2.1rem;
      transform: scale(1, 1.2);
    }
  }
`;

export const HeroInput = styled(OutlinedInput)`
  width: 41.8rem;
  height: 5.5rem;
  margin: 0;
  padding: 1.41rem 0.5rem;
  border-radius: 0.65rem;
  border: none;
  background-color: #fff;

  ${mobileScreen} {
    width: 100%;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: #979797;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #979797;
    border-width: 1px;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #979797;
    border-width: 1px;
  }
`;

export const HeroInputContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: auto;

  ${mobileScreen} {
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    > a {
      width: 100%;
    }
  }
`;

export const HeroParagraph = styled(SectionParagraph)`
  line-height: 1.57 !important;
  width: auto;
  height: 57.6px;
  margin-bottom: 3rem !important;
  letter-spacing: -0.19px;
  font-size: 1.84rem;
`;
export const HeroImageContainer = styled(Box)`
  width: 50%;
  transform: translate(3rem, 22rem) scale(1.4);
  transform-origin: bottom left;

  > img {
    width: 100%;
  }

  ${mobileScreen} {
    margin-top: -25%;
    width: 100%;
    transform-origin: center;
    transform: translate(5.5%, 26%) scale(1.25);
  }

  ${smallMobileScreen} {
    margin: 0;
    transform: translate(5.5%, 24%) scale(1.25);
  }

  ${tabletOnlyScreen} {
    margin-top: -10rem;
    //width: 70%;
    max-width: 80rem;
  }
`;
