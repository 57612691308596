import { gatewayClient, gatewayRequestHandler } from "./gatewayServiceHandler";
import { fetchItems } from "../utils/fetchItems";

export const getBrandProfile = async (url) => {
  try {
    const response = await gatewayRequestHandler(fetchItems.brand_profile, { url });
    return response?.data ?? null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getGeneralInfo = async (url, linkedin_url) => {
  const generalInfoBody = {
    url: url || "",
    linkedin_url: linkedin_url || null,
  };
  try {
    const response = await gatewayRequestHandler(fetchItems.general_info, generalInfoBody);
    return response?.data ?? null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getGeneralTwitterInfo = async (twitter_link) => {
  try {
    const response = await gatewayRequestHandler(fetchItems.general_twitter_info, { link: twitter_link });
    if (response.data) {
      const filteredNegativeSentiment = response?.data?.community_sentiment?.negative?.map((el) => ({
        name: el.name,
        text: el.text,
      }));
      const filteredPositiveSentiment = response?.data?.community_sentiment?.positive?.map((el) => ({
        name: el.name,
        text: el.text,
      }));
      const filteredSentiment = { negative: filteredNegativeSentiment, positive: filteredPositiveSentiment };
      return { ...response?.data, community_sentiment: filteredSentiment }
    } else return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const socialScraper = async (data) => {
  try {
    const response = await gatewayRequestHandler(fetchItems.social_scraper, data );
    if (response?.data?.info?.includes('not found')) return null;
    else return {
      platform_name: data.platform_name,
      data: response?.data
    };
  } catch (error) {
    console.log(error);
    return null;
  }
}
export const getSocialScraper = async (url, social_media) => {
  const socialMedia = social_media?.map((media) => {
    const socialMediaBody = {
      url: url ?? "",
      platform_name: media.platform_name,
      link: media.link
    };
    return socialScraper(socialMediaBody);
  });
  const media = {};
  await Promise.all(socialMedia).then(async (responses) => {
    if (responses?.length) {
      responses.forEach((response) => {
        if (response) media[response?.platform_name] = response?.data
      })
    }
  });
  return media;
};

export const getKeywords = async (keywordsSearchesBody) => {
  try {
    const response = await gatewayRequestHandler(fetchItems.keywords_searches, keywordsSearchesBody);
    const { coinmarketcup_keywords, linkedin_keywords, ...requiredData } = response?.data;
    return requiredData;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getSessionToken = async () => {
  try {
    const res = await gatewayClient.get("/session");
    return res.data.session_token || null;
  } catch (error) {
    console.log(error);
    return null;
  }

};


