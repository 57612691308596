export const formFields = [
  {
    id: "name-input",
    fieldName: "firstName",
    title: "First name",
    mandatory: true,
  },
  {
    id: "last_name-input",
    fieldName: "lastName",
    title: "Last name",
    mandatory: true,
  },
  {
    id: "email-input",
    fieldName: "email",
    title: "Email",
    mandatory: true,
  },
  {
    id: "company-input",
    fieldName: "company",
    title: "Company Url",
    mandatory: true,
  },
];

export const sourceOptions = ["Tiktok", "Twitter", "Google", "Telegram", "Discord", "Youtube"];
