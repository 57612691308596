//Core
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
//Styles
import {Box404, ButtonReport, LinkBox} from "./Page404.styles";
//Images
import Image404 from './img/img404.webp'
//Components
import {SupportForm} from "./support/SupportForm";
//Utils
import { pathWithUtm } from "../../../utils/pathWithUtm";

export const Page404 = ({setIs404}) => {
    const [formStatus, setFormStatus] = useState(false)

    useEffect(() => {
        setIs404(true)
    }, [])

    return (
        <>{formStatus
            ? <SupportForm setIs404={setIs404}/>
            : <Box404>
                <img src={Image404} alt={'404'}/>
                <LinkBox>
                    <Link to={pathWithUtm('/')} onClick={() => setIs404(false)}>{'Back to homepage'}</Link>
                    <ButtonReport variant={'text'} onClick={() => setFormStatus(true)}>{'Report error'}</ButtonReport>
                </LinkBox>
            </Box404>
        }</>
    )
}

export default Page404;