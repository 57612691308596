import { FlexBox, ImgBox, SectionContainer } from "../../../../styles/components/Layout";
import forwhomImg from "./forwhom.webp";
import TextImageCard from "../../../templates/textImage/TextImageCard";
import { SecondaryTitle, SectionMainTitle, SectionParagraph } from "../../../../styles/components/typography";

const ForWhom = () => {
  return (
    <SectionContainer p={{ xs: "20rem 0 15rem", br480: "25rem 0 15rem", sm: "30rem 0 15rem", md: "14rem 0" }}>
      <TextImageCard image={forwhomImg} textWidth={7.5}>
        <SectionMainTitle mb={"7.2rem"}>
          Are you a <b> crypto </b> company owner?
        </SectionMainTitle>
        <SecondaryTitle mb={"4rem"}>This tool is created to help you to Increase online sales and brand awareness</SecondaryTitle>
        <SectionParagraph width={"85%"}>
          Web3M helps Web3 companies grow by turning data into actionable insights. Our platform supports your marketing, product
          development, and community engagement efforts.
        </SectionParagraph>
      </TextImageCard>
    </SectionContainer>
  );
};

export default ForWhom;
