import { AppBar, Box, Button, List, ListItem, Menu, styled, Tooltip, tooltipClasses, Typography } from "@mui/material";
import React from "react";

import { FlexBox } from "../../../../styles/components/Layout";

//Tooltip User
export const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  paddingTop: "1.7rem !important",
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    maxWidth: 540,
    width: "fit-content",
    fontSize: "1.4rem",
    borderRadius: "1.4rem",
    padding: "4rem 7rem 4rem 3rem",
    boxShadow: "0 5px 17px 0 rgb(0 0 0 / 17%)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFFFF",
  },
}));

export const TooltipMainTitle = styled(Typography)`
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: #4a5767;
  width: fit-content;
  text-transform: uppercase;
`;

export const OoopsText = styled(Typography)`
  font-size: 1.44rem;
  line-height: 1.25;
  letter-spacing: 0.16px;
  color: #000;
  font-weight: bold;
  margin-top: 1.36rem;
  span {
    font-size: 1.54rem;
    color: rgba(0, 0, 0, 0.4);
    line-height: 1.17;
    letter-spacing: 0.17px;
    text-transform: uppercase;
    margin-left: 0.5rem;
  }
`;

export const WhyBox = styled(FlexBox)`
  align-items: center;
  margin: 1.5rem 0;
  width: 77%;
  svg {
    margin-right: 1rem;
    flex-shrink: 0;
  }
`;

export const LearnMoreText = styled(Typography)`
  color: #000000;
  font-size: 1.44rem;
  line-height: 1.25;
  letter-spacing: 0.16px;
  span {
    cursor: pointer;
    text-decoration: underline;
    color: #4d86b0;
  }
`;
