import { getUserCampaignsExist } from "../../../services/statisticManagerService";
import { pathWithUtm } from "../../../utils/pathWithUtm";
import { coPilotLink, dashboardLink } from "../../../utils/helpers";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useAuth } from "../../layout/header/data/useAuth";

export const useConnectionPage = () => {
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, user, isLoading } = useAuth0();
  useAuth();

  const getUserCampaignsData = async (user_id) => {
    try {
      const data = await getUserCampaignsExist(user_id);
      if (data && !data.has_campaigns && !data.has_draft && !data.has_pr) {
        window.open(pathWithUtm(coPilotLink), '_self')
      } else window.location.replace(pathWithUtm(dashboardLink));
    } catch(error) {
      console.log(error);
      window.location.replace(pathWithUtm(dashboardLink));
    }
  }

  useEffect(() => {
    if (!isLoading && isAuthenticated && user) {
      void getUserCampaignsData(user.sub);
      setLoading(false);
    } else if (!loading && !isAuthenticated) {
      window.open(pathWithUtm('/'), '_self')
    }
  }, [isAuthenticated, user, loading]);

  return {};
}