//Core
import React from "react";
//Images
import { Close } from "@mui/icons-material";
import { Logo } from "../../../../assets/svg/logo";
//Styles
import { IconButton, ListItem } from "@mui/material";
import { SectionContainer } from "../../../../styles/components/Layout";
import { LoginButton } from "../Header.styles";
import { MobileMenuBody, MobileMenuContainer, MobileMenuHead } from "./MobileMenu.styles";
//Utils
import { pages } from "../data/navigations";
import { dashboardLink } from "../../../../utils/helpers";
//Components
import { ButtonHeader } from "../buttonHeader/buttonHeader";
//Hooks
import { useAuth0 } from "@auth0/auth0-react";
// import { useLoginWithRedirect } from "../../../../utils/hooks/useLoginWithRedirect";
import { pathWithUtm } from "../../../../utils/pathWithUtm";
import { useHeader } from "../data/useHeader";

const MobileMenu = ({ toggleMobileMenu }) => {
  const { isLoading, isAuthenticated, logout } = useAuth0();
  // const { onLogin } = useLoginWithRedirect();
  const {openLoginPopup} = useHeader();

  return (
    <MobileMenuContainer>
      <SectionContainer>
        <MobileMenuHead>
          <Logo />
          <IconButton onClick={toggleMobileMenu} disableRipple>
            <Close />
          </IconButton>
        </MobileMenuHead>
        <MobileMenuBody>
          {pages.map((page) => {
            // console.log(page)
            return (
              <ListItem
                // onClick={toggleMobileMenu}
                key={page.name}
              >
                {ButtonHeader(page, toggleMobileMenu)}
              </ListItem>
            );
          })}
          {!isAuthenticated && !isLoading && (
            <ListItem>
              <LoginButton onClick={() => openLoginPopup('signIn')}> Login</LoginButton>
            </ListItem>
          )}
          {isAuthenticated && (
            <>
            <a href={pathWithUtm(`${dashboardLink}settings`)}>
              <ListItem>
                <LoginButton>
                  {'User Account'}
                </LoginButton>
              </ListItem>
            </a>
            <ListItem>
              <LoginButton onClick={() => logout({ returnTo: window.location.origin })}>
                {'Logout'}
              </LoginButton>
            </ListItem>
            </>
          )}
        </MobileMenuBody>
      </SectionContainer>
    </MobileMenuContainer>
  );
};

export default MobileMenu;
