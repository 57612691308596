import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { TableContainer } from "./CustomTable.styles";

const CustomTable = ({ titles, data }: any) => {
  if (!data) return null;

  return (
    <TableContainer>
      <TableHead>
        <TableRow>
          <TableCell>{titles[0]}:</TableCell>
          <TableCell>{titles[1]}:</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((el: string, i: number) => (
          <TableRow key={i}>
            <TableCell>{el[0]}</TableCell>
            <TableCell>{el[1]}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  );
};

export default CustomTable;
