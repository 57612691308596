import * as React from "react"

export function EmailSvg(props) {
    return (
        <svg
            width="1.84rem"
            height="1.84rem"
            viewBox="0 0 18.4 18.4"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.81 23.2h4.793c.037-2.307.392-4.402.958-5.96.273-.75.601-1.393.981-1.876A8.404 8.404 0 0014.81 23.2zm8.39-8.8a9.2 9.2 0 100 18.4 9.2 9.2 0 000-18.4zm0 .8c-.281 0-.602.155-.942.552-.34.396-.665.991-.945 1.762-.528 1.452-.872 3.452-.91 5.686h5.594c-.037-2.234-.382-4.234-.91-5.686-.28-.77-.605-1.366-.945-1.762-.34-.397-.66-.552-.942-.552zm3.597 8c-.037-2.307-.392-4.402-.959-5.96-.273-.75-.6-1.393-.98-1.876a8.404 8.404 0 016.733 7.836h-4.794zm-.8.8h-5.594c.038 2.234.382 4.234.91 5.686.28.77.605 1.366.945 1.762.34.397.661.552.942.552.281 0 .601-.155.942-.552.34-.396.664-.991.945-1.762.528-1.452.873-3.452.91-5.686zm-1.139 7.836c.38-.483.707-1.126.98-1.877.567-1.557.922-3.652.959-5.959h4.794a8.404 8.404 0 01-6.733 7.836zm-3.316 0c-.38-.483-.708-1.126-.981-1.877-.566-1.557-.921-3.652-.958-5.959h-4.794a8.404 8.404 0 006.733 7.836z"
                transform="translate(-729.8 -591.5) translate(715.55 426.7) translate(.25 150.4)"
                fill="#385261"
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </svg>
    )
}

export function PasswordSvg(props) {
    return (
        <svg
            width="1.36rem"
            height="1.84rem"
            viewBox="0 0 13.6 18.4"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M23.2 15.2a3.6 3.6 0 00-3.6 3.6v3.6h7.2v-3.6a3.6 3.6 0 00-3.6-3.6zm4.4 7.2v-3.6a4.4 4.4 0 00-8.8 0v3.6h-.4a2 2 0 00-2 2v6.4a2 2 0 002 2H28a2 2 0 002-2v-6.4a2 2 0 00-2-2h-.4zm-9.2.8a1.2 1.2 0 00-1.2 1.2v6.4a1.2 1.2 0 001.2 1.2H28a1.2 1.2 0 001.2-1.2v-6.4a1.2 1.2 0 00-1.2-1.2h-9.6zm4.8 3.2c.22 0 .4.18.4.4v1.6a.4.4 0 01-.8 0v-1.6c0-.22.179-.4.4-.4z"
                transform="translate(-732.2 -665.9) translate(715.55 426.7) translate(.25 224.8)"
                fill="#385261"
                stroke="none"
                strokeWidth={1}
                fillRule="evenodd"
            />
        </svg>
    )
}

export function GoogleSvg(props) {
    return (
        <svg
            id="Capa_1"
            viewBox="0 0 150 150"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <style />
            <path
                d="M120 76.1c0-3.1-.3-6.3-.8-9.3H75.9v17.7h24.8c-1 5.7-4.3 10.7-9.2 13.9l14.8 11.5C115 101.8 120 90 120 76.1z"
                fill="#4280ef"
            />
            <path
                d="M75.9 120.9c12.4 0 22.8-4.1 30.4-11.1L91.5 98.4c-4.1 2.8-9.4 4.4-15.6 4.4-12 0-22.1-8.1-25.8-18.9L34.9 95.6c7.8 15.5 23.6 25.3 41 25.3z"
                fill="#34a353"
            />
            <path
                d="M50.1 83.8c-1.9-5.7-1.9-11.9 0-17.6L34.9 54.4c-6.5 13-6.5 28.3 0 41.2l15.2-11.8z"
                fill="#f6b704"
            />
            <path
                d="M75.9 47.3c6.5-.1 12.9 2.4 17.6 6.9L106.6 41c-8.3-7.8-19.3-12-30.7-11.9-17.4 0-33.2 9.8-41 25.3l15.2 11.8c3.7-10.9 13.8-18.9 25.8-18.9z"
                fill="#e54335"
            />
        </svg>
    )
}
