import { Table } from "@mui/material";
import { styled } from "@mui/system";
import theme from "../../../../../../styles/theme";

export const TableContainer = styled(Table)`
  margin-top: 4rem;

  ${theme.breakpoints.down("br480")} {
    margin: 3rem -3rem;
    width: auto;
  }
  thead {
    th {
      font-size: 2rem;
      font-weight: 500;
      letter-spacing: 0.22px;
      //color: #4a5767;
      padding-bottom: 4rem;
      line-height: 1.2;
      color: #a5aab5;

      &:first-of-type {
        padding-left: 5rem;
      }

      ${theme.breakpoints.down("br480")} {
        font-size: 1.5rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.04);
        &:first-of-type {
          padding-left: 3rem;
        }
      }
    }
  }

  th:last-of-type {
    text-align: center;
    width: 30%;
    /* white-space: nowrap; */
  }

  td:last-of-type {
    text-align: center;
  }

  tbody {
    td {
      font-size: 1.6rem;
      letter-spacing: 0.18px;
      color: #3e3c3c;
      line-height: normal;
      ${theme.breakpoints.down("br480")} {
        border-bottom: none;
      }
    }
    tr {
      background-color: rgba(247, 247, 247, 0.62);

      ${theme.breakpoints.down("br480")} {
        background-color: transparent;
      }

      td:first-of-type {
        padding-left: 4.5rem;

        ${theme.breakpoints.down("br480")} {
          padding-left: 3rem;
          color: #677194;
          font-weight: 500;
          font-size: 1.8rem;
        }
      }

      td:last-of-type {
        font-weight: bold;
        background-color: rgba(207, 208, 211, 0.5);
        ${theme.breakpoints.down("br480")} {
          background-color: transparent;
        }
      }
    }
    tr:nth-of-type(even) {
      background-color: rgba(247, 247, 247, 1);

      ${theme.breakpoints.down("br480")} {
        background-color: #f7fafd;
      }

      td:last-of-type {
        background-color: rgba(207, 208, 211, 0.2);
        ${theme.breakpoints.down("br480")} {
          background-color: transparent;
        }
      }
    }
  }
`;
