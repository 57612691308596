import { SectionContainer } from "../../../../styles/components/Layout";
import Grid2 from "@mui/material/Unstable_Grid2";
import { NumbersItem } from "./Numbers.styles";
import { Typography } from "@mui/material";
import { numbersList } from "./Numbers.data";

const Numbers = () => {
  return (
    <SectionContainer p={"15rem 0"}>
      <Grid2 container spacing={"10rem"}>
        {numbersList.map((el) => (
          <Grid2 xs={12} md={4} key={el.title}>
            <NumbersItem>
              {el.icon}
              <Typography variant={"h3"}>{el.title}</Typography>
              <Typography>{el.desc}</Typography>
            </NumbersItem>
          </Grid2>
        ))}
      </Grid2>
    </SectionContainer>
  );
};

export default Numbers;
