import { GeoLocationIcon, WalletIcon, WorldIcon } from "./img/Hero.icons";

export const heroListData = [
  {
    icon: WalletIcon(),
    text: "DATA-DRIVEN INSIGHTS",
  },
  {
    icon: WorldIcon(),
    text: "PERSONALIZED MARKETING",
  },
  {
    icon: GeoLocationIcon(),
    text: "AUTOMATED WORKFLOWS",
  },
];
