import { GeoLocationIcon, WalletIcon, WorldIcon } from "./img/Hero.icons";


export const heroListData = [
  {
    icon: WalletIcon(),
    text: "WALLET-BASED ACTIVITY (PURCHASE BEHAVIOR)"
  },
  {
    icon: WorldIcon(),
    text: "BROWSING DATA"
  },
  {
    icon: GeoLocationIcon(),
    text: "GEOGRAPHIC AND DEMOGRAPHIC PROFILES"
  },
]