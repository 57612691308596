import { FlexBox, ImgBox, SectionContainer } from "../../../../../styles/components/Layout";
import Grid2 from "@mui/material/Unstable_Grid2";
import { AboutImage, AboutItem, AboutItemTitle, AboutTitleBlock } from "./About.styles";
import { Divider, Typography } from "@mui/material";
import aboutImg from "./img/about.webp";
import { aboutList } from "./About.data";
import { SectionParagraph } from "../../../../../styles/components/typography";

const About = () => {
  return (
    <SectionContainer p={"12rem 0"} >
      <Grid2 container spacing={'10rem'}>
        <Grid2 xs={12} md={6} >
          <AboutTitleBlock>
            <Typography variant={"h2"}>An ad network built for web3 marketers</Typography>
            <Typography>
              Send targeted ads based on lookalike audiences. Launch data-driven campaigns across the top websites in crypto.
            </Typography>
            <Divider />
          </AboutTitleBlock>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <AboutImage>
            <img src={aboutImg} alt={"about"} />
          </AboutImage>
        </Grid2>
        {aboutList.map((el) => (
          <Grid2 xs={12} md={6} key={el.title}>
            <AboutItem>
              <AboutItemTitle>
                <ImgBox>
                  <img src={el.icon} alt={"icon"} />
                </ImgBox>
                <Typography variant={'h4'}>{el.title}</Typography>
              </AboutItemTitle>
              <SectionParagraph>{el.desc}</SectionParagraph>
            </AboutItem>
          </Grid2>
        ))}
      </Grid2>
    </SectionContainer>
  );
};

export default About;
