import { Stack, styled } from "@mui/material";
import { mobileScreen, smallMobileScreen } from "../../../../utils/themeBreakpoints";

export const NumbersItem = styled(Stack)`
  align-items: center;

  ${mobileScreen} {
    width: 80%;
    margin: 0 auto;
    min-width: 30rem;
  }
  
  ${smallMobileScreen}{
    width: 100%;
  }

  svg {
    height: 11rem;
    margin-bottom: 4.4rem;
    path {
      //fill: #53baff;
    }
  }

  h3 {
    font-family: "F37 Judge", sans-serif;
    font-size: 4.6rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: 1.61px;
    color: #010a10;
    margin-bottom: 2rem;
    ${mobileScreen} {
      width: 80%;
      margin: 0 auto 2rem;
      min-width: 30rem;
      text-align: center;
    }

    ${smallMobileScreen}{
      width: 100%;
    }
  }

  p {
    font-size: 2.9rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -0.31px;
    text-align: center;
    color: #3d5f78;

    ${smallMobileScreen}{
      width: 80%;
    }
  }
`;
