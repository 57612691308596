//Core
import { useContext } from "react";
//Graphs
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
//Styles
import {
  ArrowExpanded,
  SectionBlock,
  SectionBlockTitle,
  SectionDescription,
  SectionTitleBackground
} from "../../shared/styles/layout";
import SectionBlockWithLoader from "../../shared/components/loaders/SectionBlockWithLoader";
import { Box } from "@mui/material";
//Utils
import { getMonthlyData } from "../../shared/data/getMonthlyData";
import { fetchItems } from "../../../../../utils/fetchItems";
//Context
import { BrandInfoContext } from "../../../../../context/BrandInfoContext";
import { sectionOpenItems } from "../../../../shared/brandAnalysis/help/sectionLabel";
//Images
import RemoveIcon from "@mui/icons-material/RemoveOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";

export const axisStyle = {
  style: { fontSize: "1.2rem" },
  tickLine: { opacity: "0.5" },
};

const OrganicGrowth = () => {
  const {
    fetchList,
    generalTwitterInfo,
    sectionOpen,
    handleSectionOpen,
    brandSectionsList,
  } = useContext(BrandInfoContext);

  const isLoading = fetchList.includes(fetchItems.brand_profile) || fetchList.includes(fetchItems.general_twitter_info);

  const description =
    "Other than comparing yourself to the market, it’s important that you look at your own journey and learn from it. We indicated the most valuable peak events of your brand activity during the last year. Learn from it and recreate the success.";

  if ((!isLoading && !generalTwitterInfo) || !brandSectionsList.includes(sectionOpenItems.organic_growth)) return null;

  return (
    <SectionBlock className={isLoading ? "loading" : ""}>
      <SectionTitleBackground/>
      <SectionBlockTitle onClick={() => !isLoading && handleSectionOpen(sectionOpenItems.organic_growth)}>
                                {'ORGANIC GROWTH'}
      </SectionBlockTitle>
      <SectionDescription>{description}</SectionDescription>
      <SectionBlockWithLoader loading={isLoading}>
        {(sectionOpen === sectionOpenItems.organic_growth) &&
          <Box sx={{ height: "30rem" }}>
          <ResponsiveContainer width="100%" height="100%" maxHeight={300} minHeight={50}>
            <AreaChart
              data={getMonthlyData(generalTwitterInfo?.organic_growth)}
              height={300}
              width={700}
              margin={{
                top: 20,
                left: -20
              }}
            >
              <XAxis dataKey="month" {...axisStyle} />
              <YAxis tickCount={10} {...axisStyle} />
              <CartesianGrid opacity="0.5" />
              <Tooltip labelStyle={{ fontFamily: "'Hellix', sans-serif" }} />
              <Area dataKey="eng" strokeWidth={2} dot={{ strokeWidth: 1 }} fill="rgba(53, 162, 235, 0.9)"
                    stroke="rgba(53, 162, 235, 1)" />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
        }
          <ArrowExpanded onClick={() => !isLoading && handleSectionOpen(sectionOpenItems.organic_growth)}>
            {(sectionOpen === sectionOpenItems.organic_growth)
              ? <RemoveIcon />
              : <ExpandMoreIcon />
            }
          </ArrowExpanded>
      </SectionBlockWithLoader>
    </SectionBlock>
  );
};

export default OrganicGrowth;
