import axios from "axios";

const clientBuilder = (baseURL) => {
  const client = axios.create({
    baseURL,
  });

  client.defaults.timeout = 60000;

  return client;
};

export default clientBuilder;
