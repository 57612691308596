export function RoadmapGraph(props) {
  return (
    <svg viewBox="0 0 1925 250" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="10.5467377%" y1="0%" x2="92.8433789%" y2="0%" id="a">
          <stop stopColor="#FFF" stopOpacity={0} offset="0%" />
          <stop stopColor="#FFF" offset="25.5783317%" />
          <stop stopColor="#FFF" offset="73.4313438%" />
          <stop stopColor="#FFF" stopOpacity={0} offset="100%" />
        </linearGradient>
      </defs>
      <g
        transform="translate(-197 -4705) translate(0 2678.25) translate(198.063 2027.41)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <text
          transform="translate(203.795 186.082)"
          fill="#FFF"
          fontFamily="Hellix-Regular, Hellix"
          fontSize={20.625527}
          fontWeight="normal"
          letterSpacing={0.720256133}
        >
          <tspan x={0} y={17}>
            {"Multi-channel "}
          </tspan>
          <tspan x={0} y={38}>
            {"advertising "}
          </tspan>
          <tspan x={0} y={59}>
            {"platform"}
          </tspan>
        </text>
        <text fontFamily="F37Judge-Medium, F37 Judge" fontSize={41.8641532} fontWeight={400} letterSpacing={1.46192211} fill="#4AA9F0">
          <tspan x={51.0557149} y={142.003358}>
            {"2024"}
          </tspan>
        </text>
        <text fontFamily="F37Judge-Medium, F37 Judge" fontSize={41.8641532} fontWeight={400} letterSpacing={1.46192211} fill="#4AA9EF">
          <tspan x={1842.81779} y={142.003358}>
            {"2025"}
          </tspan>
        </text>
        <text
          transform="translate(430.275 22.836)"
          fill="#FFF"
          fontFamily="Hellix-Regular, Hellix"
          fontSize={20.625527}
          fontWeight="normal"
          letterSpacing={0.720256133}
        >
          <tspan x={0} y={17}>
            {"Community Airdrop "}
          </tspan>
          <tspan x={0} y={38}>
            {"announcement"}
          </tspan>
        </text>
        <text
          transform="translate(854.91)"
          fill="#FFF"
          fontFamily="Hellix-Regular, Hellix"
          fontSize={20.625527}
          fontWeight="normal"
          letterSpacing={0.720256133}
        >
          <tspan x={0} y={17}>
            {"Personalized targeting abilities "}
          </tspan>
          <tspan x={0} y={38}>
            {"- Retargeting, Wallet-based "}
          </tspan>
          <tspan x={0} y={59}>
            {"targeting, Lookalikes"}
          </tspan>
        </text>
        <text
          transform="translate(1383.325 22.97)"
          fill="#FFF"
          fontFamily="Hellix-Regular, Hellix"
          fontSize={20.625527}
          fontWeight="normal"
          letterSpacing={0.720256133}
        >
          <tspan x={0} y={17}>
            {"$WEBM Token "}
          </tspan>
          <tspan x={0} y={38}>
            {"Launch + listing"}
          </tspan>
        </text>
        <text
          transform="translate(633.683 185.127)"
          fill="#FFF"
          fontFamily="Hellix-Regular, Hellix"
          fontSize={20.625527}
          fontWeight="normal"
          letterSpacing={0.720256133}
        >
          <tspan x={0} y={17}>
            {"Advertising platform "}
          </tspan>
          <tspan x={0} y={38}>
            {"supports Influencer "}
          </tspan>
          <tspan x={0} y={59}>
            {"marketing & PR on demand"}
          </tspan>
        </text>
        <text
          transform="translate(1124.61 185.127)"
          fill="#FFF"
          fontFamily="Hellix-Regular, Hellix"
          fontSize={20.625527}
          fontWeight="normal"
          letterSpacing={0.720256133}
        >
          <tspan x={0} y={17}>
            {"$WEBM Token Pre-"}
          </tspan>
          <tspan x={0} y={38}>
            {"sale on Tokensoft"}
          </tspan>
        </text>
        <text
          transform="translate(1626.757 185.127)"
          fill="#FFF"
          fontFamily="Hellix-Regular, Hellix"
          fontSize={20.625527}
          fontWeight="normal"
          letterSpacing={0.720256133}
        >
          <tspan x={0} y={17}>
            {"Airdrop rewards "}
          </tspan>
          <tspan x={0} y={38}>
            {"distribution"}
          </tspan>
        </text>
        <circle cx={17.1938401} cy={17.1938401} r={17.1938401} transform="translate(1116.013 108.69)" fill="#FFF" />
        <circle cx={17.1938401} cy={17.1938401} r={17.1938401} transform="translate(1371.862 108.69)" fill="#FFF" />
        <circle cx={17.1938401} cy={17.1938401} r={17.1938401} transform="translate(1618.16 108.69)" fill="#FFF" />
        <path stroke="#FFF" strokeWidth={5.73128005} strokeLinecap="square" d="M152.059644 125.883691L1818.6251 125.883691" />
        <circle fill="#FFF" cx={212.392346} cy={125.883691} r={17.1938401} />
        <circle fill="#FFF" cx={437.050103} cy={125.883691} r={17.1938401} />
        <circle fill="#FFF" cx={863.983975} cy={125.883691} r={17.1938401} />
        <circle fill="#FFF" cx={642.279868} cy={125.883691} r={17.1938401} />
        <path stroke="#FFF" strokeWidth={1.91042668} strokeLinecap="square" d="M212.392346 142.808457L212.392346 172.003006" />
        <path stroke="#FFF" strokeWidth={1.91042668} strokeLinecap="square" d="M437.050103 79.4953026L437.050103 108.689851" />
        <path stroke="#FFF" strokeWidth={1.91042668} strokeLinecap="square" d="M863.983975 79.4953026L863.983975 108.689851" />
        <path stroke="#FFF" strokeWidth={1.91042668} strokeLinecap="square" d="M1389.05633 79.4953026L1389.05633 108.689851" />
        <path stroke="#FFF" strokeWidth={1.91042668} strokeLinecap="square" d="M642.279868 142.808457L642.279868 172.003006" />
        <path stroke="#FFF" strokeWidth={1.91042668} strokeLinecap="square" d="M1133.20664 142.808457L1133.20664 172.003006" />
        <path stroke="#FFF" strokeWidth={1.91042668} strokeLinecap="square" d="M1635.35388 142.808457L1635.35388 172.003006" />
        <path
          d="M0 36.5l101.817 86.49c63.939 54.315 157.472 55.468 222.73 2.746l5.04-4.07c61.9-50.01 150.97-47.601 210.078 5.68 59.016 53.198 148.037 55.32 209.52 4.995l5.093-4.168c66.358-54.316 160.613-58.28 231.298-9.73l17.904 12.298c77.858 53.478 180.875 52.451 257.651-2.568l3.106-2.225c74.355-53.284 174.627-52.384 248.013 2.225 73.162 54.442 173.334 54.586 246.652.355L1883.318 36.5h0"
          stroke="url(#a)"
          opacity={0.4}
          strokeDasharray={8}
        />
        <circle fill="#317AC3" cx={212.044636} cy={125.995163} r={10.4492097} />
        <circle fill="#317AC3" cx={436.944636} cy={125.995163} r={10.4492097} />
        <circle fill="#317AC3" cx={863.944636} cy={125.995163} r={10.4492097} />
        <circle fill="#317AC3" cx={1132.94464} cy={125.995163} r={10.4492097} />
        <circle fill="#317AC3" cx={1388.94464} cy={125.995163} r={10.4492097} />
        <circle fill="#317AC3" cx={1635.44464} cy={125.995163} r={10.4492097} />
        <circle fill="#317AC3" cx={642.144636} cy={125.995163} r={10.4492097} />
      </g>
    </svg>
  );
}

export function RoadmapGraphBackground(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2371 395" {...props}>
      <defs>
        <linearGradient id="linearGradient-hbkxn5wy8q-1" x1="73.538%" x2="52.747%" y1="43.02%" y2="50.44%">
          <stop offset="0%" stopColor="#515BA1"></stop>
          <stop offset="100%" stopColor="#0F3987"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="url(#linearGradient-hbkxn5wy8q-1)" transform="translate(0 -4202)">
          <g transform="translate(0 4202.639)">
            <path d="M0 1.075h2006.027l168.271-.496C2282.614.26 2370.68 87.81 2371 196.125l.001.578c0 108.636-88.067 196.702-196.703 196.702H0V1.075z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function RoadmapMobile(props) {
  return (
    <svg viewBox="0 0 751 2132" xmlns="http://www.w3.org/2000/svg" className={"bg mobileOnly"} {...props}>
      <defs>
        <linearGradient x1="73.5376674%" y1="18.5042302%" x2="52.7467884%" y2="51.9874729%" id="roadmap-graph-mob-a">
          <stop stopColor="#515BA1" offset="0%" />
          <stop stopColor="#0F3987" offset="100%" />
        </linearGradient>
        <linearGradient x1="10.5467377%" y1="0%" x2="92.8433789%" y2="0%" id="roadmap-graph-mob-b">
          <stop stopColor="#FFF" stopOpacity={0} offset="0%" />
          <stop stopColor="#FFF" offset="25.5783317%" />
          <stop stopColor="#FFF" offset="73.4313438%" />
          <stop stopColor="#FFF" stopOpacity={0} offset="100%" />
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M-690.083 9963.482h1755.26c207.384 0 375.5 168.117 375.5 375.5s-168.116 375.5-375.5 375.5h-1755.26v-751z"
          fill="url(#roadmap-graph-mob-a)"
          transform="translate(0 -9273) rotate(-90 375.297 10338.982)"
        />
        <text
          transform="translate(0 -9273) translate(35.148 9370.601) translate(82.112 426.45)"
          fill="#FFF"
          fontFamily="Hellix-Regular, Hellix"
          fontSize={20.625527}
          fontWeight="normal"
          letterSpacing={0.720256133}
        >
          <tspan x={56.0941244} y={17}>
            {"Multi-channel "}
          </tspan>
          <tspan x={82.0073079} y={38}>
            {"advertising "}
          </tspan>
          <tspan x={107.180444} y={59}>
            {"platform"}
          </tspan>
        </text>
        <text
          transform="translate(0 -9273) translate(35.148 9370.601) translate(393.27 201.45)"
          fill="#FFF"
          fontFamily="Hellix-Regular, Hellix"
          fontSize={20.625527}
          fontWeight="normal"
          letterSpacing={0.720256133}
        >
          <tspan x={0} y={17}>
            {"Multi-channel "}
          </tspan>
          <tspan x={0} y={38}>
            {"advertising "}
          </tspan>
          <tspan x={0} y={59}>
            {"platform"}
          </tspan>
        </text>
        <text
          fontFamily="F37Judge-Medium, F37 Judge"
          fontSize={41.8641532}
          fontWeight={400}
          letterSpacing={1.46192211}
          fill="#4AA9F0"
          transform="translate(0 -9273) translate(35.148 9370.601)"
        >
          <tspan x={294.07738} y={124.206828}>
            {"2023"}
          </tspan>
        </text>
        <text
          fontFamily="F37Judge-Medium, F37 Judge"
          fontSize={41.8641532}
          fontWeight={400}
          letterSpacing={1.46192211}
          fill="#4AA9EF"
          transform="translate(0 -9273) translate(35.148 9370.601)"
        >
          <tspan x={295.871501} y={1876.31779}>
            {"2024"}
          </tspan>
        </text>
        <text
          transform="translate(0 -9273) translate(35.148 9370.601) translate(0 851.405)"
          fill="#FFF"
          fontFamily="Hellix-Regular, Hellix"
          fontSize={20.625527}
          fontWeight="normal"
          letterSpacing={0.720256133}
        >
          <tspan x={49.5294312} y={17}>
            {"Personalized targeting "}
          </tspan>
          <tspan x={56.0651969} y={38}>
            {"abilities - Retargeting, "}
          </tspan>
          <tspan x={39.6243701} y={59}>
            {"Wallet-based targeting, "}
          </tspan>
          <tspan x={170.34395} y={80}>
            {"Lookalikes"}
          </tspan>
        </text>
        <text
          transform="translate(0 -9273) translate(35.148 9370.601) translate(102.112 1377.28)"
          fill="#FFF"
          fontFamily="Hellix-Regular, Hellix"
          fontSize={20.625527}
          fontWeight="normal"
          letterSpacing={0.720256133}
        >
          <tspan x={32.6556987} y={17}>
            {"$WEBM Token "}
          </tspan>
          <tspan x={16.6670166} y={38}>
            {"Launch + listing"}
          </tspan>
        </text>
        <text
          transform="translate(0 -9273) translate(35.148 9370.601) translate(392.27 629.495)"
          fill="#FFF"
          fontFamily="Hellix-Regular, Hellix"
          fontSize={20.625527}
          fontWeight="normal"
          letterSpacing={0.720256133}
        >
          <tspan x={0} y={17}>
            {"Advertising platform "}
          </tspan>
          <tspan x={0} y={38}>
            {"supports Influencer "}
          </tspan>
          <tspan x={0} y={59}>
            {"marketing &amp; PR on "}
          </tspan>
          <tspan x={0} y={80}>
            {"demand"}
          </tspan>
        </text>
        <text
          transform="translate(0 -9273) translate(35.148 9370.601) translate(389.27 1123.495)"
          fill="#FFF"
          fontFamily="Hellix-Regular, Hellix"
          fontSize={20.625527}
          fontWeight="normal"
          letterSpacing={0.720256133}
        >
          <tspan x={0} y={17}>
            {"$WEBM Token Pre-"}
          </tspan>
          <tspan x={0} y={38}>
            {"sale on Tokensoft"}
          </tspan>
        </text>
        <text
          transform="translate(0 -9273) translate(35.148 9370.601) translate(389.27 1625.208)"
          fill="#FFF"
          fontFamily="Hellix-Regular, Hellix"
          fontSize={20.625527}
          fontWeight="normal"
          letterSpacing={0.720256133}
        >
          <tspan x={0} y={17}>
            {"Airdrop rewards "}
          </tspan>
          <tspan x={0} y={38}>
            {"distribution"}
          </tspan>
        </text>
        <g transform="translate(0 -9273) translate(35.148 9370.601) matrix(-1 0 0 1 425.556 0)">
          <circle cx={17.1938401} cy={17.1938401} r={17.1938401} transform="rotate(90 -502.428 613.584)" fill="#FFF" />
          <circle cx={17.1938401} cy={17.1938401} r={17.1938401} transform="rotate(90 -630.353 741.51)" fill="#FFF" />
          <circle cx={17.1938401} cy={17.1938401} r={17.1938401} transform="rotate(90 -753.502 864.658)" fill="#FFF" />
          <path
            stroke="#FFF"
            strokeWidth={5.73128005}
            strokeLinecap="square"
            transform="rotate(90 93.962 985.201)"
            d="M-739.179552 985.201457L927.385902 985.201457"
          />
          <circle fill="#FFF" transform="rotate(90 93.962 212.392)" cx={93.9622616} cy={212.392346} r={17.1938401} />
          <circle fill="#FFF" transform="rotate(90 93.962 437.05)" cx={93.9622616} cy={437.050103} r={17.1938401} />
          <circle fill="#FFF" transform="rotate(90 93.962 863.984)" cx={93.9622616} cy={863.983975} r={17.1938401} />
          <circle fill="#FFF" transform="rotate(90 93.962 642.28)" cx={93.9622616} cy={642.279868} r={17.1938401} />
          <path
            stroke="#FFF"
            strokeWidth={1.91042668}
            strokeLinecap="square"
            transform="rotate(90 62.44 212.392)"
            d="M62.4402213 197.795072L62.4402213 226.98962"
          />
          <path
            stroke="#FFF"
            strokeWidth={1.91042668}
            strokeLinecap="square"
            transform="rotate(90 125.753 437.05)"
            d="M125.753376 422.452829L125.753376 451.647377"
          />
          <path
            stroke="#FFF"
            strokeWidth={1.91042668}
            strokeLinecap="square"
            transform="rotate(90 125.753 863.984)"
            d="M125.753376 849.386701L125.753376 878.581249"
          />
          <path
            stroke="#FFF"
            strokeWidth={1.91042668}
            strokeLinecap="square"
            transform="rotate(90 125.753 1389.056)"
            d="M125.753376 1374.45905L125.753376 1403.6536"
          />
          <path
            stroke="#FFF"
            strokeWidth={1.91042668}
            strokeLinecap="square"
            transform="rotate(90 62.44 642.28)"
            d="M62.4402213 627.682594L62.4402213 656.877142"
          />
          <path
            stroke="#FFF"
            strokeWidth={1.91042668}
            strokeLinecap="square"
            transform="rotate(90 62.44 1133.207)"
            d="M62.4402213 1118.60937L62.4402213 1147.80392"
          />
          <path
            stroke="#FFF"
            strokeWidth={1.91042668}
            strokeLinecap="square"
            transform="rotate(90 62.44 1635.354)"
            d="M62.4402213 1620.7566L62.4402213 1649.95115"
          />
          <path
            d="M-849.159 850.813l101.817 86.49c63.939 54.315 157.472 55.468 222.73 2.746l5.04-4.07c61.9-50.01 150.97-47.601 210.078 5.68 59.016 53.198 148.038 55.32 209.521 4.995l5.092-4.168c66.358-54.316 160.613-58.28 231.298-9.73l17.904 12.298c77.858 53.478 180.875 52.451 257.652-2.568l3.105-2.226c74.355-53.283 174.627-52.383 248.014 2.226 73.161 54.442 173.333 54.586 246.651.354l124.416-92.027h0"
            stroke="url(#roadmap-graph-mob-b)"
            opacity={0.40461513}
            strokeDasharray={8}
            transform="rotate(90 92.5 941.659)"
          />
        </g>
      </g>
    </svg>
  );
}
