import { Skeleton, styled, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { TableContainer } from "../customTable/CustomTable.styles";

const SkeletonStyled = (props: any) => {
  const StyledSkeleton = styled(Skeleton)`
    background-color: rgba(209, 207, 207, 0.66);
    height: 2.6rem;
    border-radius: 1.5rem;
  `;
  return (
    <StyledSkeleton animation={false} {...props}>
      {props.children}
    </StyledSkeleton>
  );
};

export const TableSkeleton = ({ data }) => {
  const arrWidth = ["47%", "73%", "65%", "54%", "60%", "35%", "53%"];
  return (
    <TableContainer>
      <TableHead>
        <TableRow>
          <TableCell>
            <SkeletonStyled variant="rectangular" width={"68%"} />
          </TableCell>
          <TableCell>
            <SkeletonStyled variant="rectangular" sx={{ m: "0 auto", width: { xs: "100%", br480: "77%" } }} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Array(data)
          .fill("")
          .map((el: string, i: number) => (
            <TableRow key={i}>
              <TableCell>
                <SkeletonStyled variant="rectangular" sx={{ backgroundColor: "rgba(222,220,220,0.62)" }} width={arrWidth[i]} />
              </TableCell>
              <TableCell>
                <SkeletonStyled variant="rectangular" sx={{ m: "0 auto", width: { xs: "90%", br480: "60%" } }} />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </TableContainer>
  );
};
