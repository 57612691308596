import { DEV_ONLY } from "../utils/helpers";
import clientBuilder from "./clientBuilder";

const baseURL = `https://mvp-statistics-manager-${DEV_ONLY ? "develop-" : ""}dot-winky-apis.ew.r.appspot.com/`
const statisticsManagerClient = clientBuilder(baseURL);

export const getUserCampaignsExist = async (user_id) => {
  try {
    const response = await statisticsManagerClient.get(`/dashboard/${user_id}`);
    if (response?.data) return response.data;
    else return null;
  } catch (err) {
    console.log(err);
    return null;
  }
}