//Core
import { useContext } from "react";
//Styles
import {
  ArrowExpanded,
  SectionBlock,
  SectionBlockTitle,
  SectionDescription,
  SectionTitleBackground
} from "../../shared/styles/layout";
import SectionBlockWithLoader from "../../shared/components/loaders/SectionBlockWithLoader";
//Components
import CustomTable from "../../shared/components/customTable/CustomTable";
import { TableSkeleton } from "../../shared/components/skeleton/TableSkeleton";
//Context
import { BrandInfoContext } from "../../../../../context/BrandInfoContext";
//Hooks
import { useCompetitors } from "./useCompetitors";
//Images
import RemoveIcon from "@mui/icons-material/RemoveOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";
//Utils
import { sectionOpenItems } from "../../../../shared/brandAnalysis/help/sectionLabel";
import { fetchItems } from "../../../../../utils/fetchItems";

const tableTitles = ["Competitor’s Name", "Niche Dominate"];

const Competitors = () => {
  const { fetchList, competitors, sectionOpen, handleSectionOpen, brandSectionsList } = useContext(BrandInfoContext);
  const { competitorsData } = useCompetitors();

  const displayLoader = fetchList.includes(fetchItems.brand_profile) || fetchList.includes(fetchItems.competitors);
  const competitorsFound = competitors && !!Object.keys(competitors)?.length;

  if((!displayLoader && !competitors) || !brandSectionsList.includes(sectionOpenItems.competitors)) return null;


  return (
    <SectionBlock className={displayLoader ? "loading" : competitorsFound ? "" : "skeleton"}>
      <SectionTitleBackground/>
      <SectionBlockTitle onClick={() => !displayLoader && handleSectionOpen(sectionOpenItems.competitors)}>
        {'Lookalike audiences'}
      </SectionBlockTitle>
      <SectionDescription>
        {'Find your audience overlap. You’re not the only company trying to target this demographic. Learn from ' +
          'companies who are already advertising to your target customers. This existing audience was generated by AI.'}
      </SectionDescription>
      <SectionBlockWithLoader loading={displayLoader}>
        {(sectionOpen === sectionOpenItems.competitors) &&
        <>
          {competitorsFound ? <CustomTable titles={tableTitles} data={competitorsData} /> : <TableSkeleton data={7} />}
        </>
        }
          <ArrowExpanded onClick={() => !displayLoader && handleSectionOpen(sectionOpenItems.competitors)}>
            {(sectionOpen === sectionOpenItems.competitors)
              ? <RemoveIcon />
              : <ExpandMoreIcon />
            }
          </ArrowExpanded>
      </SectionBlockWithLoader>
    </SectionBlock>
  );
};

export default Competitors;
