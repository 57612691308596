import { styled } from "@mui/system";
import { List, Typography } from "@mui/material";
import theme from "../../../../styles/theme";

export const ForWhoListContainer = styled(List)`
  margin-bottom: 6rem;

  ${theme.breakpoints.down("sm")} {
    margin-bottom: 3rem;
  }
  li {
    padding-left: 0;
    height: 6.6rem;
    margin-bottom: 1.5rem;

    ${theme.breakpoints.down("sm")} {
      height: 6rem;
    }

    > div {
      align-items: flex-start;
    }

    .MuiListItemText-root {
      max-width: 46rem;
      ${theme.breakpoints.down("sm")} {
        max-width: 75vw;
      }
    }

    svg {
      margin-right: 1.2rem;

      ${theme.breakpoints.down("sm")} {
        width: 3rem;
        position: relative;
        top: 4px;
        /* align-self: center; */
        /* transform: translateY(5px); */
      }
    }
  }
`;

export const SubText = styled(Typography)`
  opacity: 0.9;
  font-size: 2.46rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 1.42px;
  color: #233847;
`;
