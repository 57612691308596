import { RelativeBox, SectionBackground, SectionContainer } from "../../../../../styles/components/Layout";
import whatisImg from "./img/whatis.webp";
import { whatIsList } from "./WhatIs.data";
import CardsGrid from "../../../../templates/cardsGrid/CardsGrid";
import TextImageCard from "../../../../templates/textImage/TextImageCard";
import { SecondaryTitle, SectionParagraph } from "../../../../../styles/components/typography";

const WhatIs = () => {
  return (
    <RelativeBox>
      <SectionBackground sx={{ backgroundColor: "#fafafa" }} />
      <SectionContainer p={"10rem 0 5rem"}>
        <TextImageCard image={whatisImg} textWidth={7} sx={{ maxWidth: { xs: "40rem", br480: "60rem" }, m: "0 auto", transform: { xs: "none", md: "scale(1.1)" } }}>
          <SecondaryTitle>
            What is the Web3m <br /> pixel?
          </SecondaryTitle>
          <SectionParagraph mb={{ xs: '5rem', md: "20rem" }}>
            The Web3m pixel is a piece of code you can install on your website that tracks anonymous visitor data. That data is sent to
            Web3m, where marketers can use it for advertising purposes. Every time your specific data is used in a campaign, you (and the
            person whose data is being used) are compensated.
          </SectionParagraph>
        </TextImageCard>

        <SecondaryTitle m={{ xs: "10rem auto 7.7rem", md: "10rem 0 7.7rem" }} maxWidth={"60rem"}>
          Reimagine the future of <br /> data-based marketing
        </SecondaryTitle>
        <CardsGrid data={whatIsList} />
      </SectionContainer>
    </RelativeBox>
  );
};

export default WhatIs;
