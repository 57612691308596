import { Box, styled } from "@mui/material";
import { FlexResponsive, SectionBackground } from "../../../../styles/components/Layout";
import { SecondaryButton } from "../../../../styles/components/buttons";
import { mobileScreen } from "../../../../utils/themeBreakpoints";

export const RoadmapBackground = styled(SectionBackground)`
  background-image: linear-gradient(205deg, #4babf1, #0f3987);
  //background-image: linear-gradient(205deg, #4babf1 134%, #0f3987 48%);
  border-radius: 0 50% 50% 0;
`;

export const RoadmapGraphContainer = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;

  .bg {
    //position: absolute;
    //bottom: 0;
    //left: 0;
    //background-image: linear-gradient(205deg, #4babf1, #0f3987);
    width: 100%;
    //height: 100%;
  }

  .graph {
    position: absolute;
    width: 98%;
    max-width: 190rem;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    margin: 0 auto;
    z-index: 1;
  }
`;

export const RoadmapButton = styled(SecondaryButton)`
  background-color: #e7526a;
  color: #fff;
  border: none;

  &:hover {
    background-color: #e7526a;
    color: #fff;
  }

  svg {
    path {
      fill: #fff;
    }
  }
`;

export const RoadmapButtonsContainer = styled(FlexResponsive)`
  column-gap: 3rem;
  row-gap: 3rem;

  ${mobileScreen} {
    a {
      width: 100%;

      > button {
        width: 100%;
      }
    }
  }
`;
