import { useContextData } from "./useContextData";
import { BrandInfoContext } from "./BrandInfoContext";

const BrandInfoProvider = ({ children }) => {
  const value = useContextData()

  return (
    <BrandInfoContext.Provider value={value}>
      {children}
    </BrandInfoContext.Provider>
  );
};

export default BrandInfoProvider;