export function FreeSvg(props) {
  return (
    <svg
      width="23.6rem"
      height="20.3rem"
      viewBox="0 0 236.845199 203"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // color={'rgba(83,186,255,0.5)'}
      {...props}
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="13.2689869%"
          x2="66.9987112%"
          y2="56.9396774%"
          id="a"
        >
          <stop stopColor="rgba(83,186,255,1)" offset="0%" />
          <stop stopColor="rgba(157,159,255,0.85)" offset="22%" />
          <stop stopColor="#FFFFFF" stopOpacity={0} offset="100%" />
        </linearGradient>
        <radialGradient
          cx="11.5790506%"
          cy="12.3572223%"
          fx="11.5790506%"
          fy="12.3572223%"
          r="70.4260593%"
          gradientTransform="matrix(-.8571 0 0 -.5779 .215 .195)"
          id="c"
        >
          <stop stopColor="#FFF" stopOpacity={0.5} offset="0%" />
          <stop stopColor="rgba(83,186,255,0.5)" stopOpacity={0} offset="100%" />
        </radialGradient>
        <path
          d="M35.748 0h192.685a4 4 0 013.103 6.525l-4.613 5.67A44.229 44.229 0 00217 40.11V193c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10V40.76a45.408 45.408 0 019.923-28.332A33.048 33.048 0 0135.748 0z"
          id="b"
        />
        <filter
          x="-1.5%"
          y="-1.7%"
          width="101.1%"
          height="103.4%"
          filterUnits="objectBoundingBox"
          id="d"
        >
          <feGaussianBlur
            stdDeviation={2}
            in="SourceAlpha"
            result="shadowBlurInner1"
          />
          <feOffset dy={3} in="shadowBlurInner1" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2={-1}
            k3={1}
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.493239183 0"
            in="shadowInnerInner1"
          />
        </filter>
      </defs>
      <g
        transform="translate(-466.795 -911) translate(466.795 911)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <use fill="url(#a)" xlinkHref="#b" />
        <use fill="url(#c)" xlinkHref="#b" />
        <use fill="#000" filter="url(#d)" xlinkHref="#b" />
        <text
          fontFamily="F37Judge-Bold, F37 Judge"
          fontSize={65}
          fontWeight="bold"
          letterSpacing={2.26984024}
          fill="#FFF"
        >
          <tspan x={49.5} y={177.25}>
            {"FREE"}
          </tspan>
        </text>
        <path
          d="M52.315 97.391l32.292-31.236a8 8 0 0110.938-.174l7.813 7.09a7 7 0 009.85-.437l20.184-21.882a8 8 0 0111.497-.273l13.88 13.686h0"
          stroke="#FFF"
          strokeWidth={5}
          strokeLinecap="round"
        />
        <circle
          stroke="#FFF"
          strokeWidth={5}
          fill="#FFF"
          cx={158.769587}
          cy={62.8309493}
          r={8.20118552}
        />
      </g>
    </svg>
  )
}