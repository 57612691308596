import { Stack, Typography } from "@mui/material";
import { ImgBox } from "../../../styles/components/Layout";
import { AdvantagesList, AdvantagesListItem } from "./AdvantagesGrid.styles";
import { useBreakpoints } from "../../../utils/hooks/useBreakpoints";

const AdvantagesGrid = ({ data }) => {
  const {smallMobileScreen} = useBreakpoints();
  return (
    <AdvantagesList>
      {data.map((el, i) => (
        <AdvantagesListItem key={el.title + i}>
          <Stack>
            <Typography variant={"subtitle1"} mb={"3rem"} width={"85%"}>
              {el.title}
            </Typography>
            <Typography>{el.desc}</Typography>
          </Stack>
          <ImgBox className={"image"} sx={{maxWidth: smallMobileScreen && el.img_mob ? '32rem' : 'auto'}}>
            <img src={smallMobileScreen && el.img_mob ? el.img_mob : el.img} alt={'advantages'}/>
          </ImgBox>
        </AdvantagesListItem>
      ))}
    </AdvantagesList>
  );
};

export default AdvantagesGrid;
