import { useState } from "react";

export const useFAQ = () => {
  const [active, setActive] = useState({
    aboutUs: -1,
    benefits: -1,
  });
  const handleClick = (section, index) => {
    if (index === active[section]) {
      setActive({ ...active, [section]: -1 });
    } else {
      setActive({ ...active, [section]: index });
    }
  };
  return { active, handleClick };
};
