//Core
import { useContext } from "react";
//Styles
import {
  ArrowExpanded,
  SectionBlock,
  SectionBlockTitle,
  SectionDescription,
  SectionTitleBackground
} from "../../shared/styles/layout";
import SectionBlockWithLoader from "../../shared/components/loaders/SectionBlockWithLoader";
import { DescriptionBlockWrapper, DescriptionLogo, LogoContainer } from "./Logo.styles";
import { Stack, Typography } from "@mui/material";
//Context
import { BrandInfoContext } from "../../../../../context/BrandInfoContext";
//Images
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";
import RemoveIcon from "@mui/icons-material/RemoveOutlined";
//Utils
import { sectionOpenItems } from "../../../../shared/brandAnalysis/help/sectionLabel";

const Description = () => {
  const {
    fetchList,
    brandProfile,
    url,
    sectionOpen,
    handleSectionOpen,
    brandSectionsList,
  } = useContext(BrandInfoContext);
  const loading = fetchList.includes("brand_profile");

  if ((!brandProfile && !loading) || !brandSectionsList.includes(sectionOpenItems.brand_description)) return null;


  return (
    <SectionBlock className={loading ? "loading" : ""}>
      <SectionTitleBackground/>
      <SectionBlockTitle onClick={() => !loading && handleSectionOpen(sectionOpenItems.brand_description)}>
        {"Brand Description"}
      </SectionBlockTitle>
      <SectionDescription mb="6rem" sx={{maxWidth: '70rem'}}>
        {'Discover key information about your brand. Gain insights into how your brand is perceived by search engines and users alike.'}
      </SectionDescription>
      <SectionBlockWithLoader loading={loading}>
        {(sectionOpen === sectionOpenItems.brand_description) &&
          <DescriptionBlockWrapper>
            <DescriptionLogo>
              <LogoContainer src={brandProfile?.logo ? `${brandProfile?.logo}?size=300` : undefined}>
                {brandProfile?.name?.[0] ?? ""}
              </LogoContainer>
              <Stack ml={{ xs: 0, br480: "1.5rem" }}>
                <Typography variant="subtitle1" component="h3">
                  {brandProfile?.name ?? ""}
                </Typography>
                <Typography variant="h4" textAlign={{ xs: "center", br480: "left" }}>
                  {url}
                </Typography>
              </Stack>
            </DescriptionLogo>
            <Typography>{brandProfile?.description}</Typography>
          </DescriptionBlockWrapper>
        }
          <ArrowExpanded onClick={() => !loading && handleSectionOpen(sectionOpenItems.brand_description)}>
            {(sectionOpen === sectionOpenItems.brand_description)
              ? <RemoveIcon />
              : <ExpandMoreIcon />
            }
          </ArrowExpanded>
      </SectionBlockWithLoader>

    </SectionBlock>
  );
};

export default Description;
