import Grid2 from "@mui/material/Unstable_Grid2";
import { ImgBox } from "../../../styles/components/Layout";

const TextImageCard = ({ children, image, textWidth, sx }) => {
  return (
    <Grid2 container columnSpacing={"6rem"} rowSpacing={"5rem"}>
      <Grid2 xs={12} md={textWidth}>
        {children}
      </Grid2>
      <Grid2 xs={12} md={12 - textWidth}>
        <ImgBox sx={sx}>
          <img src={image} alt={"work meeting"} />
        </ImgBox>
      </Grid2>
    </Grid2>
  );
};

export default TextImageCard;


