export const getStringNumber = (num) => {
  if (!num) return 0;

  if (Math.round(num) >= 1000000) {
    return Math.round(num / 100000) / 10 + "M";
  } else if (Math.round(num) >= 100000) {
    return Math.round(num / 1000) + "K";
  } else if (num >= 1000) {
    return Math.round(num / 100) / 10 + "K";
  } else {
    return Math.round(num);
  }
};