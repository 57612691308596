import { createContext } from "react";

export const BrandInfoContext = createContext({
  fetchList: [],
  url: "",
  setUrl: () => {},
  setUrlHandler: () => {},
  isButtonRunWasClicked : false,
  setIsButtonRunWasClicked : () => {},
  brandProfile: null,
  generalInfo: null,
  generalTwitterInfo: null,
  keywords: null,
  competitors: null,
  socialMedia: null,
  getData: async () => {},
  brandSectionsList: [],
  otherSectionsList: [],
  addSectionsHandler: async () => {},
  sendFeedbackHandler: async () => {},
  sectionOpen: "",
  handleSectionOpen: async () => {},
  sendTimeLapFeedback: async () => {},
  runAnalysisHandler: async () => {},
});
