import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import { ImgBox, NFTSectionContainer } from "../../../../styles/components/Layout";
import theme from "../../../../styles/theme";

export const StepsNftContainer = styled(Box)`
  width: 100%;
  height: 100%;
  top: -60rem;
  position: relative;
  background-color: #f4ede4;
  padding-top: 45rem;
  margin-bottom: -90rem;
  z-index: 0;
`;

export const StepsNftContent = styled(NFTSectionContainer)`
  ${theme.breakpoints.down("xl")} {
    max-width: 80%;
  }
  ${theme.breakpoints.down("br1024")} {
    max-width: 90%;
  }
  ${theme.breakpoints.down("md")} {
    max-width: 75rem;
    padding-left: 10rem;
  }
  ${theme.breakpoints.down("sm")} {
    max-width: 90%;
    padding-left: 10vw;
  }
`;

export const GridContainer = styled(Grid)`
  margin-top: 5.5rem;
  position: relative;
  z-index: 2;
  ${theme.breakpoints.down("md")} {
    display: none;
  }
`;

export const MobileGridContainer = styled(Grid)`
  display: none;
  ${theme.breakpoints.down("md")} {
    display: flex;
    margin-top: 7rem;
    height: fit-content;
  }
  ${theme.breakpoints.down("sm")} {
    margin-right: -10vw;
  }
`;

export const MobileGridLeft = styled(Grid)`
  height: 100%;
  & > div:first-of-type {
    margin-bottom: 10rem;
  }
`;

export const MobileGridRight = styled(Grid)`
  justify-content: center;
  height: 100%;
  ${theme.breakpoints.down("sm")} {
    margin-right: -10vw;
  }
`;

export const StepGridTitle = styled(Typography)`
  font-family: "F37 Judge", sans-serif;
  font-size: 2.9rem;
  line-height: normal;
  letter-spacing: 1.01px;
  color: #010a10;
  width: 60%;
  ${theme.breakpoints.down("sm")} {
    width: 90%;
  }
`;

const descWidth = ["60%", "65%", "95%"];
export const StepGridDesc = styled(Typography)`
  font-size: 2rem;
  line-height: 1.25;
  letter-spacing: -0.21px;
  color: #000;
  margin-top: 1.5rem;
  width: ${(props) => descWidth[props.i]};
  ${theme.breakpoints.down("lg")} {
    width: ${(props) => (props.i === 2 ? "80%" : descWidth[props.i])};
  }
`;

export const StepGridImage = styled(ImgBox)`
  width: 18.6rem;
  height: 16.6rem;
  margin-bottom: 3.86rem;
  margin-left: -2.3rem;
`;

export const PeopleBox = styled(Stack)`
  width: 100%;
  position: relative;
  height: 100rem;
  ${theme.breakpoints.down("md")} {
    height: fit-content;
    background-color: #f4ede4;
  }
`;
export const PeopleImgBox = styled(Box)`
  width: 100vw;
  position: absolute;
  background-color: #f4ede4;
  bottom: -5rem;
  left: 0;
  z-index: 1;
  ${theme.breakpoints.down("md")} {
    background-color: unset;
    position: relative;
  }
  img {
    mix-blend-mode: multiply;
    width: 168rem;
    height: auto;
    position: relative;
    bottom: -5rem;
    left: 45%;
    z-index: 1;
    border: none;
    @media screen and (max-width: 2450px) {
      left: 40%;
    }
    ${theme.breakpoints.down("xl")} {
      left: 30%;
    }
    ${theme.breakpoints.down("br1024")} {
      width: 150rem;
      bottom: -2rem;
    }
    ${theme.breakpoints.down("md")} {
      left: 50%;
      transform: translateX(-42%);
      margin-top: -15rem;
    }
    ${theme.breakpoints.down("br768")} {
      width: 130rem;
      margin-top: -6rem;
      bottom: 2rem;
    }
    ${theme.breakpoints.down("sm")} {
      width: 100rem;
      margin-top: 4rem;
      bottom: 8rem;
    }
    ${theme.breakpoints.down("br480")} {
      width: 80rem;
      margin-top: 4rem;
      bottom: 13rem;
    }
  }
`;

export const PeopleBg = styled(Box)`
  position: absolute;
  bottom: -34rem;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: calc(100% + 23rem);
  background-color: #ffffff;
  border-top-right-radius: 20rem;
  border-top-left-radius: 20rem;
  display: none;
  ${theme.breakpoints.down("md")} {
    display: block;
  }
  ${theme.breakpoints.down("br768")} {
    border-top-right-radius: 10rem;
    border-top-left-radius: 10rem;
  }
`;

export const BgPeopleDark = styled(Box)`
  position: absolute;
  z-index: -1;
  bottom: -5rem;
  left: 0;
  width: 100%;
  height: 34rem;
  background: #00213a;
  border-top-left-radius: 31rem;
  border-top-right-radius: 31rem;
  @media screen and (max-width: 2450px) {
    border-top-right-radius: 0;
  }

  ${theme.breakpoints.down("md")} {
    height: 58rem;
    bottom: -30rem;
  }

  ${theme.breakpoints.down("br768")} {
    border-top-left-radius: 20rem;
  }

  ${theme.breakpoints.down("br480")} {
    border-top-left-radius: 10rem;
  }
`;

export const PeopleTextContainer = styled(NFTSectionContainer)`
  position: relative;
  width: 100%;
`;

export const StepsBigText = styled(Typography)`
  position: relative;
  z-index: 2;
  max-width: 57.5rem;
  padding-top: 25rem;
  font-family: "F37 Judge", sans-serif;
  font-size: 6.3rem;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 2.2px;
  color: #00213a;
  span {
    color: #f2a526;
  }
  ${theme.breakpoints.down("md")} {
    margin: 0 auto;
  }
  ${theme.breakpoints.down("sm")} {
    font-size: 5.5rem;
    max-width: 45rem;
  }
  ${theme.breakpoints.down("br480")} {
    padding-top: 20rem;
  }
`;
