import { Box, Stack, styled } from "@mui/material";
import { FlexBox, ImgBox } from "../../../../../styles/components/Layout";
import { mobileScreen, smallMobileScreen } from "../../../../../utils/themeBreakpoints";
import theme from "../../../../../styles/theme";

export const AboutTitleBlock = styled(Stack)`
  margin-right: 15rem;
  
  ${theme.breakpoints.down('lg')}{
    margin-right: 10vw;
  }
  ${mobileScreen} {
    margin-right: 0;
  }

  h2 {
    font-family: "F37 Judge", sans-serif;
    font-size: 4.6rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: 1.61px;
    color: #010a10;
    margin-bottom: 3rem;
    ${mobileScreen} {
      text-align: center;
    }
  }

  p {
    font-size: 2.4rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.25px;
    color: #325267;
    margin-bottom: 6rem;
    ${mobileScreen} {
      text-align: center;
    }
  }

  hr {
    border-color: #000;
    width: 80%;
    margin-bottom: 10rem;
    ${mobileScreen} {
      margin: 0 auto;
    }
  }
`;

export const AboutImage = styled(ImgBox)`
  width: 25%;
  margin: 0 auto;
  transform: scale(4);

  ${mobileScreen} {
    transform: none;
    width: 100%;
    max-width: 60rem;
  }
  
  ${smallMobileScreen}{
    max-width: 40rem;
  }
`;

export const AboutItem = styled(Stack)`

  p{
    ${mobileScreen}{
      max-width: 60rem;
      margin: 0 auto;
      
      ${smallMobileScreen}{
        max-width: 40rem;
      }
    }
  }
`;

export const AboutItemTitle = styled(FlexBox)`
  margin-bottom: 4.5rem;
  
  ${mobileScreen}{
    flex-direction: column;
  }
  
  > div:first-of-type {
    width: 9.1rem;
    height: 9.1rem;
    margin-right: 1.6rem;

    ${mobileScreen}{
      margin-bottom: 2rem;
    }
  }

  h4 {
    font-family: "F37 Judge", sans-serif;
    font-size: 3.4rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 1.19px;
    color: #010a10;
    width: 70%;
    ${mobileScreen}{
      text-align: center;
      max-width: 45rem;
      margin: 0 auto;
    }
  }
`;
