import img1 from "../../../../assets/img/cards/loudspeaker.webp";
import img2 from "./img/effective.webp";
import img3 from "./img/analysis.webp";

export const advantagesList = [
  {
    title: "Centralized Customer Data Management",
    desc: "Web3m's Marketing Cloud allows you to consolidate, store, and analyze customer data from various sources in one centralized location. This unified data view enhances your understanding of customer behavior and supports more informed business decisions.",
    img: img1,
  },
  {
    title: "Advanced Audience Segmentation",
    desc: "The platform enables precise audience segmentation based on behavioral and demographic data. This allows for the creation of targeted and effective marketing campaigns, ensuring that the right message reaches the right audience at the right time.",
    img: img2,
  },
  {
    title: "Personalized Marketing Automation",
    desc: "Web3m's Marketing Cloud offers advanced automation capabilities for personalized marketing. You can set up automated workflows to deliver real-time, personalized messages and offers to customers based on their behavior and preferences, enhancing engagement and conversion rates. ",
    img: img3,
  },
];
