import { styled, Typography } from "@mui/material";
import { FlexBox, SvgBox } from "../../../styles/components/Layout";
import theme from "../../../styles/theme";

export const FooterContainer = styled(FlexBox)`
  background-color: #010a10;
  width: 100vw;
  height: 8.14rem;
  color: rgba(255, 255, 255, 0.58);
  padding: 0 4.5rem;
  position: relative;
  margin-top: auto;

  ${theme.breakpoints.down("br1024")} {
    flex-direction: column;
    justify-content: space-around;
    height: 22rem;
    padding: 5rem 0;
  }
`;

export const SubContainer = styled(FlexBox)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${theme.breakpoints.down("br1024")} {
    justify-content: center;
    margin: 0 auto;
    width: 100vw;
  }
`;

export const LogoFooterBox = styled(SvgBox)`
  //height: 2rem;
  width: 8.6rem;
  ${theme.breakpoints.down("br1024")} {
    margin-right: 3rem;
  }
  ${theme.breakpoints.down("sm")} {
    height: 2.5rem;
    margin-right: 2rem;
  }
  svg {
    opacity: 0.35;
  }
`;

export const AllRights = styled(Typography)`
  font-size: 1.51rem;
  letter-spacing: 0.94px;
  color: rgba(255, 255, 255, 0.58);
  flex-shrink: 0;
`;
