import { Stack, styled } from "@mui/material";
import { FlexBox } from "../../../styles/components/Layout";
import { mobileScreen, smallMobileScreen } from "../../../utils/themeBreakpoints";

export const AdvantagesList = styled(Stack)`
  row-gap: 15rem;
`;

export const AdvantagesListItem = styled(FlexBox)`
  column-gap: 9rem;

  h6,
  p {
    color: #1e1e1e;
    white-space: pre-line;

    ${smallMobileScreen} {
      white-space: normal;
    }
  }

  ${mobileScreen} {
    flex-direction: column !important;

    h6,
    p {
      text-align: center;
      width: 90%;
      margin: 0 auto 5rem;
    }
  }

  ${smallMobileScreen} {
    width: 95%;
    margin: 0 auto;
  }

  &:nth-of-type(even) {
    flex-direction: row-reverse;
  }

  .image {
    flex: 0 0 53%;
  }
`;
