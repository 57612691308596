import { styled, TextField, Tooltip, tooltipClasses, Typography } from "@mui/material";
import React from "react";
import { LoadingButton } from "@mui/lab";
import theme from "../../../../../styles/theme";

export const BrandAnalysisOtherTooltip = styled(({ className, ...props }) =>
  <Tooltip {...props}
           classes={{ popper: className }}
           disableFocusListener
           disableHoverListener
           disableTouchListener
           arrow
           placement={"top-start"}
  />)(() => ({
  paddingTop: "1.7rem !important",
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#D6D6D6",
    maxWidth: 540,
    width: "fit-content",
    fontSize: "1.4rem",
    borderRadius: "1.4rem",
    padding: "4rem 3rem",
    boxShadow: "0 5px 17px 0 rgb(0 0 0 / 17%)",
    [`${theme.breakpoints.down("md")}`]: {
      maxWidth: "85%",
      margin: "0 auto",
    }
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#D6D6D6",
  },
}));

export const BrandAnalysisTooltipTitle = styled(Typography)`
  font-size: 1.5rem;
  color: #121314;
  letter-spacing: -0.16px;
  margin-bottom: 2rem;
  & span {
    font-weight: bold;
  }
  ${theme.breakpoints.down("br768")} {
    align-self: center;
    text-align: center;
  }
`

export const BrandAnalysisTooltipInput = styled(TextField)`
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.06);
  border-radius: 8px;
  
  .MuiInputBase-input {
    padding: 1.3rem 2rem;
  }
`

export const BrandAnalysisTooltipPlayButton = styled(LoadingButton)`
  background-image: linear-gradient(200deg, #53BAFF 0%, #3530B4 100%);
  border-radius: 8px;
  padding: 1rem;
  margin-left: 2rem;
  min-width: auto;
  
  #icon {
    width: 3.7rem;
    height: 3.6rem;
    fill: #FFFFFF;
  }
  
  ${theme.breakpoints.down("md")} {
    margin: 2rem 0 0 ;
    width: 100%;
    #icon {
      
    }
  }
`