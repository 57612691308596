import { Box, CircularProgress } from "@mui/material";

const FullPageLoader = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh" }}>
      <CircularProgress />
    </Box>
  );
};

export default FullPageLoader;
